import colors from "./colors";
import KievitProRegular from "./fonts/KievitPro-Regular.woff2";
import KievitProThin from "./fonts/KievitPro-Thin.woff2";
import KievitProExtraLight from "./fonts/KievitPro-ExtraLight.woff2";
import KievitProLight from "./fonts/KievitPro-Light.woff2";
import KievitProMedium from "./fonts/KievitPro-Medium.woff2";
import KievitProExtraBold from "./fonts/KievitPro-ExtraBold.woff2";
import KievitProBlack from "./fonts/KievitPro-Black.woff2";

const { info, dark } = colors;

const globals = {
  html: {
    scrollBehavior: "smooth",
  },
  body: {
    background: "rgb(247, 248, 252)",
    minHeight: "100vh",
  },
  "*, *::before, *::after": {
    margin: 0,
    padding: 0,
  },
  "a, a:link, a:visited": {
    textDecoration: "none !important",
  },
  "a.link, .link, a.link:link, .link:link, a.link:visited, .link:visited": {
    color: `${dark.main} !important`,
    transition: "color 150ms ease-in !important",
  },
  "a.link:hover, .link:hover, a.link:focus, .link:focus": {
    color: `${info.main} !important`,
  },
  "@font-face": {
    fontFamily: "KievitPro",
    src: `url(${KievitProRegular}) format("woff2")`,
    fontWeight: "400",
    fontDisplay: "block",
  },
  fallbacks: [
    {
      "@font-face": {
        fontFamily: "KievitPro",
        src: `url(${KievitProThin}) format("woff2")`,
        fontWeight: "100",
        fontDisplay: "block",
      },
    },
    {
      "@font-face": {
        fontFamily: "KievitPro",
        src: `url(${KievitProExtraLight}) format("woff2")`,
        fontWeight: "200",
        fontDisplay: "block",
      },
    },
    {
      "@font-face": {
        fontFamily: "KievitPro",
        src: `url(${KievitProLight}) format("woff2")`,
        fontWeight: "300",
        fontDisplay: "block",
      },
    },
    {
      "@font-face": {
        fontFamily: "KievitPro",
        src: `url(${KievitProRegular}) format("woff2")`,
        fontWeight: "400",
        fontDisplay: "block",
      },
    },
    {
      "@font-face": {
        fontFamily: "KievitPro",
        src: `url(${KievitProMedium}) format("woff2")`,
        fontWeight: "500",
        fontDisplay: "block",
      },
    },
    {
      "@font-face": {
        fontFamily: "KievitPro",
        src: `url(${KievitProExtraBold}) format("woff2")`,
        fontWeight: "600",
        fontDisplay: "block",
      },
    },
    {
      "@font-face": {
        fontFamily: "KievitPro",
        src: `url(${KievitProBlack}) format("woff2")`,
        fontWeight: "800",
        fontDisplay: "block",
      },
    },
  ],
};

export default globals;
