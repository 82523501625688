import typography from "@theme/base/typography";

import pxToRem from "@theme/functions/pxToRem";

const { size } = typography;

// types
type Types = any;

const dialogTitle: Types = {
  styleOverrides: {
    root: {
      padding: pxToRem(16) + " " + pxToRem(32),
      fontSize: size.xl,
    },
  },
};

export default dialogTitle;
