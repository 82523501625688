type NamingMap = {
  [index: string]: string;
};

export const genericReferenceToModuleName: (gref: string) => string = (gref) => {
  const namingMap: NamingMap = {
    Contract: "contracts",
    MaterialInTransit: "material in transit",
    Warehousing: "warehousing",
    UpcomingVessels: "upcoming vessels",
  };

  if (!gref) {
    return "deleted object";
  }
  if (!gref?.includes(":")) {
    return "unknown object";
  }
  const moduleCode = gref.split(":")[0];
  if (!gref?.includes(":")) {
    return gref;
  }
  return namingMap[moduleCode] || "unknown module";
};
