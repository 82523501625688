import React from "react";

import { SusConnectedWidgetStyles } from "./styles";

import { Skeleton } from "@mui/material";
import useCompanySettings from "@hooks/useCompanySettings";
import SusBox from "@components/SusBox";
import SusTypography from "@components/SusTypography";
import SusAvatar from "@components/SusAvatar/SusAvatar";

interface ISusConnectedCompaniesWidgetProps {
  companyId: number;
}

const SusConnectedCompaniesWidget: React.FC<ISusConnectedCompaniesWidgetProps> = ({
  companyId,
}) => {
  const { company, loading } = useCompanySettings(companyId);

  const relatedCompanies = company
    ? company.isDistributor
      ? company.customers
      : company.distributors
    : [];

  return loading ? (
    <Skeleton width={200} />
  ) : (
    <SusBox sx={SusConnectedWidgetStyles}>
      {relatedCompanies.map(({ id, name }) => (
        <SusBox key={id} className={"widgetBox"}>
          <SusAvatar
            className={"icon"}
            name={name.length > 12 ? `${name.substring(0, 14)}` : name}
          />
          <SusTypography>{name}</SusTypography>
        </SusBox>
      ))}
    </SusBox>
  );
};

export default SusConnectedCompaniesWidget;
