import {
  Switch,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  Zoom,
} from "@mui/material";
import { Theme } from "@mui/material/styles";
import React, { useRef } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faLaptop } from "@fortawesome/pro-solid-svg-icons";
import { useDashboardState } from "@src/app.state";
import {
  NotificationSettings,
  NotificationSettingsChannelPreferences,
  NotificationSettingsEvents,
  UIModule,
} from "@src/types";
import useNotifications from "@hooks/useNotifications";
import SusBox from "@components/SusBox";
import SusTypography from "@components/SusTypography";
import SusButton from "@components/SusButton";

export default function ModuleNotificationSettings() {
  const { viewSettings } = useDashboardState();
  const availableModules: {
    navigationElem: UIModule;
    title: string;
    description: string;
    subject: keyof NotificationSettings;
  }[] = [
    {
      navigationElem: "Contracts",
      title: "Contracts",
      description: "Notify about comments in this module",
      subject: "contracts",
    },
    {
      navigationElem: "CallOffs",
      title: "Call-Offs",
      description: "Notify about comments in this module",
      subject: "callOffs",
    },
    {
      navigationElem: "Warehousing",
      title: "Warehousing",
      description: "Notify about comments in this module",
      subject: "warehousing",
    },
    {
      navigationElem: "MaterialInTransit",
      title: "Material in Transit",
      description: "Notify about comments in this module",
      subject: "materialInTransit",
    },
    {
      navigationElem: "Upcoming",
      title: "Upcoming Vessels",
      description: "Notify about comments in this module",
      subject: "upcomingVessels",
    },
  ];
  const { notificationSettings, setNotificationSettings } = useNotifications();

  const tableRowRef = useRef<HTMLTableRowElement | null>(null);
  const tableRowRef1 = useRef<HTMLTableRowElement | null>(null);
  const makeChangeHandler =
    (
      subject: keyof NotificationSettings,
      eventType: keyof NotificationSettingsEvents,
      channel: keyof NotificationSettingsChannelPreferences
    ) =>
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setNotificationSettings?.({
        ...notificationSettings,
        [subject]: {
          ...notificationSettings?.[subject],
          [eventType]: {
            ...notificationSettings?.[subject]?.[eventType],
            [channel]: event.target.checked,
          },
        },
      });
    };

  const SettingsRow: React.FC<{
    title: string;
    description: string;
    subject: keyof NotificationSettings;
    eventType: keyof NotificationSettingsEvents;
  }> = ({ title, description, subject, eventType }) => {
    return (
      <>
        <TableRow ref={tableRowRef}>
          <TableCell
            sx={({ breakpoints }: Theme) => ({
              padding: [1, 1, 1, 1],
              [breakpoints.up("md")]: {
                padding: [2, 1, 1, 0.5],
              },
            })}
          >
            <SusBox lineHeight={1.4}>
              <SusTypography display="block" variant="button" fontWeight="regular">
                {title}
              </SusTypography>
              <SusTypography
                variant="caption"
                color="text"
                fontWeight="regular"
                sx={{ display: { xs: "none", md: "flex" } }}
              >
                {description}
              </SusTypography>
            </SusBox>
          </TableCell>

          <TableCell
            align="center"
            sx={({ breakpoints }: Theme) => ({
              padding: [1.5, 1, 1.5, 0.5],
              [breakpoints.up("md")]: {
                padding: [1, 1, 1, 0.5],
              },
            })}
          >
            <Switch
              onChange={makeChangeHandler(subject, eventType, "email")}
              checked={notificationSettings?.[subject]?.[eventType]?.email || false}
            />
          </TableCell>
          <TableCell
            align="center"
            sx={({ breakpoints }: Theme) => ({
              padding: [1.5, 1, 1.5, 0.5],
              [breakpoints.up("md")]: {
                padding: [1, 1, 1, 0.5],
              },
            })}
          >
            <Switch
              onChange={makeChangeHandler(subject, eventType, "web")}
              checked={notificationSettings?.[subject]?.[eventType]?.web || false}
            />
          </TableCell>
        </TableRow>
      </>
    );
  };

  return (
    <Table
      sx={({ breakpoints }: Theme) => ({
        minWidth: "100%",
        [breakpoints.up("md")]: {
          minWidth: "36rem",
        },
      })}
      stickyHeader
    >
      <TableHead
        sx={{
          background: "transparent",
          display: "table-header-group !important",
          paddingX: 0,
          "& .MuiTableCell-root": {
            background: "transparent",
          },
        }}
      >
        <TableRow ref={tableRowRef1}>
          <TableCell
            width="100%"
            sx={({ breakpoints }: Theme) => ({
              padding: [1.5, 1, 1.5, 0.5],
              [breakpoints.up("md")]: {
                padding: [1.5, 3, 1.5, 0.5],
              },
            })}
          >
            Activity
          </TableCell>
          <TableCell
            align="center"
            sx={({ breakpoints }: Theme) => ({
              padding: [1.5, 1, 1.5, 0.5],
              [breakpoints.up("md")]: {
                padding: [1.5, 3, 1.5, 0.5],
              },
            })}
          >
            <Tooltip title="E-Mail" placement="right" TransitionComponent={Zoom}>
              <SusButton variant="text" color="dark" sx={{ cursor: "help" }}>
                <FontAwesomeIcon icon={faEnvelope} />
              </SusButton>
            </Tooltip>
          </TableCell>
          <TableCell
            align="center"
            sx={({ breakpoints }: Theme) => ({
              padding: [1.5, 1, 1.5, 0.5],
              [breakpoints.up("md")]: {
                padding: [1.5, 3, 1.5, 0.5],
              },
            })}
          >
            <Tooltip title="In-App" placement="right" TransitionComponent={Zoom}>
              <SusButton variant="text" color="dark" sx={{ cursor: "help" }}>
                <FontAwesomeIcon icon={faLaptop} />
              </SusButton>
            </Tooltip>
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        <TableRow>
          <TableCell colSpan={3} sx={{ padding: "16px 8px 8px 4px" }}>
            General
          </TableCell>
        </TableRow>
        <SettingsRow
          title="Mentions"
          description="Notify when another user mentions you in a comment"
          subject="global"
          eventType="mention"
        />
        <SettingsRow
          title="Answers"
          description="Notify when another user answers to your comment"
          subject="global"
          eventType="comment"
        />
        <TableRow>
          <TableCell colSpan={3} sx={{ padding: "16px 8px 8px 4px" }}>
            Per Module
          </TableCell>
        </TableRow>
        {availableModules
          .filter((module) => viewSettings.uiModules?.includes(module.navigationElem))
          .map((module) => (
            <SettingsRow
              key={module.title}
              title={module.title}
              description={module.description}
              subject={module.subject}
              eventType="comment"
            />
          ))}
      </TableBody>
    </Table>
  );
}
