import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
  chart: {
    canvas: {
      margin: "0 auto",
      position: "relative",
      height: "100% !Important",
      width: "auto !important",
    },
  },
  barChartWrapper: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "end",
    height: "100%",
  },
  barChart: {
    display: "flex",
    alignItems: "flex-end",
    marginBottom: "10px",
  },
  barLabel: {
    marginRight: "10px",
    width: "160px",
  },
  bar: {
    width: "45px",
    borderRadius: "5px",
    backgroundColor: "#e0e0e0",
    marginRight: "10px",
  },
});

export default useStyles;
