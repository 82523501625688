import React, { useCallback, useMemo, useState } from "react";

import Grid from "@mui/material/Grid";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import useStyles from "./styles";

import { Slide, Typography } from "@mui/material";
import { WidgetProps, WidgetSpecs } from "@src/features/control-room";
import { useDashboardState } from "@src/app.state";
import { availableControlRoomConfigs } from "@src/features/control-room/widgets";
import SusBox from "@components/SusBox";
import SusButton from "@components/SusButton";
import SusDialog from "@components/SusDialog/SusDialog";
import SusTypography from "@components/SusTypography";

interface IAddControlRoomWidgetDialogProps {
  onAddWidget: (specs: WidgetSpecs, propValues?: WidgetProps) => void;
  onResetToDefault: () => void;
}

const AddControlRoomWidgetDialog: React.FC<IAddControlRoomWidgetDialogProps> = ({
  onAddWidget,
  onResetToDefault,
}) => {
  const dashboardState = useDashboardState();

  const widgetRegistry = useMemo(() => {
    const crConfig = availableControlRoomConfigs[dashboardState.viewConfigKey];
    if (!crConfig) {
      return;
    }
    return crConfig.getWidgetRegistry(dashboardState.locale);
  }, [dashboardState]);

  const handleClose = () => {
    dashboardState.setCurrentDialog(null);
  };

  const handleClick = (specs: WidgetSpecs) => {
    if (specs.modifyableProps && specs.modifyableProps.length > 0) {
      dashboardState.setCurrentDialog("AddControlRoomWidgetDetails", { specs, onAddWidget });
    } else {
      onAddWidget(specs);
      dashboardState.setCurrentDialog(null);
    }
  };

  const classes = useStyles();
  const [confirmDiscardOpen, setConfirmDiscardOpen] = useState<boolean>(false);

  const susDialogActions = useCallback(
    (handleCloseConfirm: any) => {
      return (
        <>
          {confirmDiscardOpen ? (
            <SusBox sx={{ overflow: "hidden" }}>
              <Slide direction="left" in={confirmDiscardOpen}>
                <SusBox>
                  <Typography variant="caption" sx={{ p: 1 }}>
                    Your configuration will be deleted and reset to default.
                  </Typography>
                  <SusButton
                    color="text"
                    variant="outlined"
                    size="medium"
                    sx={{
                      mt: { xs: 1, md: 0 },
                      mr: { xs: 1, sm: 1, md: 1 },
                      ml: "auto",
                      paddingLeft: "1rem",
                      paddingRight: "1rem",
                      minWidth: "auto",
                    }}
                    onClick={() => {
                      setConfirmDiscardOpen(false);
                    }}
                    ml={1}
                  >
                    No
                  </SusButton>
                  <SusButton
                    color="primary"
                    size="medium"
                    sx={{
                      mt: { xs: 1, md: 0 },
                      mr: { xs: 1, sm: 1, md: 1 },
                      ml: "auto",
                      paddingLeft: "1rem",
                      paddingRight: "1rem",
                      minWidth: "auto",
                    }}
                    onClick={() => {
                      onResetToDefault();
                      handleCloseConfirm();
                    }}
                  >
                    Yes
                  </SusButton>
                </SusBox>
              </Slide>
            </SusBox>
          ) : (
            <>
              <SusButton
                size="medium"
                color="text"
                variant="outlined"
                sx={{
                  lineHeight: "1",
                  mt: { xs: 1, md: 0 },
                  mr: { xs: 1, sm: 1, md: 1 },
                  ml: "auto",
                  paddingLeft: "1rem",
                  paddingRight: "1rem",
                  minWidth: "auto",
                }}
                onClick={() => {
                  setConfirmDiscardOpen(true);
                }}
              >
                Reset Control Room
              </SusButton>
              <SusButton
                size="medium"
                color="text"
                variant="outlined"
                sx={{
                  lineHeight: "1",
                  mt: { xs: 1, md: 0 },
                  mr: { xs: 1, sm: 1, md: 1 },
                  ml: "auto",
                  paddingLeft: "1rem",
                  paddingRight: "1rem",
                  minWidth: "auto",
                }}
                onClick={handleCloseConfirm}
              >
                Close
              </SusButton>
            </>
          )}
        </>
      );
    },
    [confirmDiscardOpen]
  );

  return (
    <SusDialog
      title="Add Widget"
      open={dashboardState.currentDialog.type === "AddControlRoomWidget"}
      onClose={handleClose}
      sx={{ minWidth: "60%" }}
      actions={susDialogActions}
    >
      <SusBox p={2}>
        <SusBox>
          <Grid
            container
            columns={{ xs: 6, md: 12, lg: 12 }}
            spacing={3}
            mb={1}
            flexDirection="row"
            justifyContent="flex-start"
          >
            {widgetRegistry?.map((specs) => (
              <Grid
                item
                onClick={() => handleClick(specs)}
                key={specs.widgetName}
                xs={6}
                sm={6}
                md={6}
                lg={4}
                sx={{ textAlign: "center", cursor: "pointer" }}
              >
                <SusBox
                  shadow="md"
                  borderRadius="md"
                  display="flex"
                  flexDirection="column"
                  p={2}
                  className={classes.WidgetButton}
                >
                  <SusBox mt={1} mb={1}>
                    <FontAwesomeIcon icon={specs.icon} size="2x" />
                  </SusBox>
                  <SusTypography variant="body2" align="center">
                    {specs.widgetName}
                  </SusTypography>
                </SusBox>
              </Grid>
            ))}
          </Grid>
        </SusBox>
      </SusBox>
    </SusDialog>
  );
};

export default AddControlRoomWidgetDialog;
