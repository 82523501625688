import React from "react";
import ReactCodeInput, { InputModeTypes } from "react-code-input";

import useStyles from "./styles";

interface ISusCodeInputProps {
  inputMode: InputModeTypes;
  onChange: (val: string) => void;
  isValid: boolean;
}

const SusCodeInput: React.FC<ISusCodeInputProps> = (props) => {
  const classes = useStyles();
  return (
    <ReactCodeInput
      name="authCode"
      type="number"
      className={classes.authCode}
      inputStyle={{
        fontFamily: "monospace",
        borderRadius: "6px",
        border: "0.0625rem solid #d2d6da",
        boxShadow: "",
        margin: "4px",
        textAlign: "center",
        width: "100%",
        minHeight: "54px",
        fontSize: "32px",
        boxSizing: "border-box",
        color: "#619D8E",
        backgroundColor: "transparent",
      }}
      inputStyleInvalid={{
        fontFamily: "monospace",
        borderRadius: "6px",
        border: "0.0625rem solid #ef4e4b",
        boxShadow: "",
        margin: "4px",
        textAlign: "center",
        width: "100%",
        minHeight: "54px",
        fontSize: "32px",
        boxSizing: "border-box",
        color: "#ef4e4b",
        backgroundColor: "rgba(239,100,97,0.25)",
      }}
      fields={6}
      {...props}
    />
  );
};

export default SusCodeInput;
