import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
  BarTabs: {
    "& header": {
      "&:focus": {
        outline: "none",
      },
    },
  },
});

export default useStyles;
