import React, { useEffect } from "react";

import {
  Button,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Skeleton,
  Theme,
} from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { SubmitHandler } from "react-hook-form/dist/types/form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFingerprint, faFloppyDisk, faTimes } from "@fortawesome/pro-regular-svg-icons";
import LoadingButton from "@mui/lab/LoadingButton";

import { Helmet } from "react-helmet-async";
import { useDashboardState } from "@src/app.state";
import useAlerts from "@hooks/useAlerts";
import useUser from "@hooks/useUser";
import SusBox from "@components/SusBox";
import TabsFormSection from "@components/FormularSection/TabsFormSection";
import colors from "@theme/base/colors";

interface IProfileTabPageProps {
  userId?: number;
  onSubmit?: () => void;
  hidePrivacySettings?: boolean;
}

interface IUserFormData {
  firstName: string;
  lastName: string;
  email: string;
  locale: string;
}

const UserProfile: React.FC<IProfileTabPageProps> = ({ userId, onSubmit, hidePrivacySettings }) => {
  const { setCurrentDialog } = useDashboardState();
  const { notifySuccess } = useAlerts();
  const sendData: SubmitHandler<IUserFormData> = (data, event) => {
    event?.preventDefault();
    updateUser?.({ user: data }).then(() => {
      setCurrentDialog(null);
      onSubmit?.();
      notifySuccess({
        title: "Success",
        content: "User saved.",
        autoHideDuration: 5000,
      });
    });
  };

  const { user, loading, updateUser, sending } = useUser(userId);
  const {
    register,
    handleSubmit,
    control,
    reset,
    formState: { isDirty },
  } = useForm<IUserFormData>();

  useEffect(() => {
    reset({ ...user });
  }, [user]);

  return loading ? (
    <Skeleton width={200} />
  ) : (
    <>
      <Helmet>
        <title>My details - Preferences | PEK Dashboard </title>
      </Helmet>
      <TabsFormSection title="General information" description="Your personal profile settings">
        <Grid container flexDirection="row">
          <Grid xs={12}>
            <FormControl
              sx={({ breakpoints }: Theme) => ({
                width: "280px",
                maxWidth: "100%",
                marginBottom: "0.5rem",
                marginRight: "0.5rem",
                [breakpoints.down("md")]: {
                  width: "100%",
                },
              })}
            >
              <InputLabel>First name</InputLabel>
              <OutlinedInput
                defaultValue=" "
                label="First name"
                data-matomo-mask
                {...register("firstName", { required: true })}
              />
            </FormControl>
            <FormControl
              sx={({ breakpoints }: Theme) => ({
                width: "280px",
                maxWidth: "100%",
                marginBottom: "0.5rem",
                [breakpoints.down("md")]: {
                  width: "100%",
                },
              })}
            >
              <InputLabel>Last name</InputLabel>
              <OutlinedInput
                defaultValue=" "
                label="Last name"
                data-matomo-mask
                {...register("lastName", { required: true })}
              />
            </FormControl>
          </Grid>
        </Grid>
        <Grid container>
          <FormControl
            sx={({ breakpoints }: Theme) => ({
              width: "280px",
              maxWidth: "100%",
              marginBottom: "0.5rem",
              [breakpoints.down("md")]: {
                width: "100%",
              },
            })}
          >
            <InputLabel>E-mail</InputLabel>
            <OutlinedInput
              defaultValue=" "
              label="E-mail"
              {...register("email", { required: true })}
            />
          </FormControl>
        </Grid>
      </TabsFormSection>
      <TabsFormSection
        title="Location Settings"
        description="Configuration of currency language and time zone"
      >
        <Grid container flexDirection="row">
          <Grid xs={12}>
            <FormControl
              variant="outlined"
              sx={({ breakpoints }: Theme) => ({
                width: "280px",
                maxWidth: "100%",
                marginBottom: "0.5rem",
                marginRight: "0.5rem",
                [breakpoints.down("md")]: {
                  width: "100%",
                  marginRight: "0",
                },
              })}
            >
              <InputLabel shrink>Location</InputLabel>
              <Controller
                control={control}
                name="locale"
                render={({ field: { onChange, value, name } }) => {
                  return value ? (
                    <Select
                      labelId="locale-select-label"
                      id="locale-select-input"
                      value={value}
                      name={name}
                      onChange={onChange}
                      label="Location"
                    >
                      <MenuItem value="en-US">United States</MenuItem>
                      <MenuItem value="de-DE">Germany</MenuItem>
                    </Select>
                  ) : (
                    <></>
                  );
                }}
              />
            </FormControl>
            <FormControl
              sx={({ breakpoints }: Theme) => ({
                width: "280px",
                maxWidth: "100%",
                marginBottom: "0.5rem",
                [breakpoints.down("md")]: {
                  width: "100%",
                },
              })}
            >
              <InputLabel>Language</InputLabel>
              <OutlinedInput label="Language" required disabled value="english" />
              <FormHelperText>Language currently not changeable</FormHelperText>
            </FormControl>
          </Grid>
        </Grid>
      </TabsFormSection>
      {!hidePrivacySettings && (
        <TabsFormSection
          title="Privacy Settings"
          description="Please click on the button to configure your privacy settings."
        >
          <Grid container flexDirection="row">
            <Grid xs={12}>
              <Button
                onClick={() => setCurrentDialog("ServiceConsentDialog")}
                size="medium"
                color="primary"
                variant="outlined"
                startIcon={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label={"open service consent dialog"}
                      edge="start"
                      sx={{
                        color: colors.primary.main,
                      }}
                    >
                      <FontAwesomeIcon icon={faFingerprint} size={"xs"} />
                    </IconButton>
                  </InputAdornment>
                }
                sx={{
                  lineHeight: "1",
                  mt: { xs: 1, md: 0 },
                  mr: { xs: 1, sm: 1, md: 1 },
                  ml: "auto",
                  paddingLeft: "1rem",
                  paddingRight: "1rem",
                  minWidth: "auto",
                }}
              >
                Configure Privacy Settings
              </Button>
            </Grid>
          </Grid>
        </TabsFormSection>
      )}
      <SusBox
        display="flex"
        justifyContent="flex-end"
        sx={{ borderTop: "1px solid #e0e0e0", padding: "1rem 1rem 0 1rem" }}
        className="TabFormActions"
      >
        {isDirty && (
          <Button
            onClick={() => reset({ ...user })}
            size="medium"
            variant="outlined"
            startIcon={
              <InputAdornment position="start">
                <IconButton
                  aria-label={"save"}
                  edge="start"
                  sx={{
                    color: colors.white.main,
                  }}
                >
                  <FontAwesomeIcon icon={faTimes} size="xs" />
                </IconButton>
              </InputAdornment>
            }
            sx={{
              lineHeight: "1",
              mt: { xs: 1, md: 0 },
              mr: { xs: 1, sm: 1, md: 1 },
              ml: "auto",
              paddingLeft: "1rem",
              paddingRight: "1rem",
              minWidth: "auto",
            }}
          >
            Discard
          </Button>
        )}
        <LoadingButton
          onClick={handleSubmit(sendData)}
          loading={sending}
          variant="contained"
          color="primary"
          size="medium"
          startIcon={
            <InputAdornment position="start">
              <IconButton
                aria-label={"save"}
                edge="start"
                sx={{
                  color: colors.white.main,
                }}
              >
                <FontAwesomeIcon icon={faFloppyDisk} size="xs" />
              </IconButton>
            </InputAdornment>
          }
        >
          Save
        </LoadingButton>
      </SusBox>
    </>
  );
};

export default UserProfile;
