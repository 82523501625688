import { makeStyles } from "@mui/styles";
import colors from "@theme/base/colors";

const { light } = colors;

const useStyles = makeStyles({
  WidgetButton: {
    height: "100%",
    "&:hover": {
      background: "#f2f4f8a1",
    },

    "&:active": {
      background: light.focus,
    },
  },
});
export default useStyles;
