// SidenavCard.tsx

import React, { useState } from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { Button, Divider, MenuItem, Popover, Tooltip, Zoom } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleInfo, faLifeRing, faPresentationScreen } from "@fortawesome/pro-solid-svg-icons";

import SusBox from "../../components/SusBox";
import SusButton from "../../components/SusButton";
import colors from "@theme/base/colors";
import borders from "@theme/base/borders";
import { useDashboardState } from "@src/app.state";

function SidenavCard() {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const { setCurrentDialog, startTour } = useDashboardState();

  return (
    <>
      <Card className="HelpMenu-Wrapper" sx={{ background: "transparent", boxShadow: "none" }}>
        <CardContent sx={{ padding: 2, background: "transparent" }}>
          <SusBox>
            <Button
              variant="text"
              onClick={handleClick}
              size="small"
              className="helpButton"
              aria-controls="help-menu"
              aria-owns={anchorEl ? "simple-popover" : undefined}
              aria-haspopup="true"
              fullWidth
              startIcon={<FontAwesomeIcon icon={faLifeRing} />}
              sx={{
                color: colors.black.main,
                borderRadius: borders.borderRadius.md,
                fontWeight: 500,
              }}
            >
              Help & Contact
            </Button>
            <Popover
              id="simple-popover"
              open={Boolean(anchorEl)}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: "top",
                horizontal: 400,
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              sx={{
                "& .MuiPopover-paper": {
                  background: "#fff",
                },
              }}
            >
              <MenuItem onClick={handleClose}>
                <SusButton
                  variant="text"
                  size="small"
                  color="dark"
                  startIcon={<FontAwesomeIcon icon={faLifeRing} />}
                  sx={{ justifyContent: "flex-start" }}
                  fullWidth
                  onClick={() => setCurrentDialog("SupportContactDialog")}
                >
                  Contact Support
                </SusButton>
              </MenuItem>
              <Tooltip
                title="Take the tour to see how the Dashboard works."
                placement="right"
                TransitionComponent={Zoom}
              >
                <MenuItem onClick={handleClose}>
                  <SusButton
                    variant="text"
                    size="small"
                    color="dark"
                    startIcon={<FontAwesomeIcon icon={faPresentationScreen} />}
                    sx={{ justifyContent: "flex-start" }}
                    fullWidth
                    onClick={() => startTour()}
                  >
                    Dashboard guided tour
                  </SusButton>
                </MenuItem>
              </Tooltip>
              <Divider sx={{ marginY: "0.2rem !important" }} />
              <MenuItem onClick={handleClose}>
                <SusButton
                  variant="text"
                  size="small"
                  color="dark"
                  startIcon={<FontAwesomeIcon icon={faCircleInfo} />}
                  sx={{ justifyContent: "flex-start" }}
                  fullWidth
                  onClick={() => setCurrentDialog("AboutDialog")}
                >
                  About PEK Dashboard
                </SusButton>
              </MenuItem>
            </Popover>
          </SusBox>
        </CardContent>
      </Card>
    </>
  );
}

export default SidenavCard;
