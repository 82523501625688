// @mui material components
import { styled } from "@mui/material/styles";
import pxToRem from "@theme/functions/pxToRem";

export default styled("div")(({ theme }) => {
  const { palette, borders, typography } = theme;

  const { borderRadius } = borders;
  const { size } = typography;
  const { text } = palette;

  return {
    "& .ql-toolbar": {
      borderRadius: `${borderRadius.md} ${borderRadius.md} 0 0`,
    },

    ".quill": {
      width: "100%",
    },

    "& .ql-container": {
      borderRadius: `0 0 ${borderRadius.md} ${borderRadius.md}`,
    },

    "& .ql-editor": {
      "& p, li, blockquote": {
        fontSize: size.md,
        color: text.main,
      },
      "& blockquote": {
        position: "relative",
        marginTop: pxToRem(4),
        marginBottom: pxToRem(4),
        paddingLeft: pxToRem(16),
        borderLeft: "none",
        "&::before": {
          content: '""',
          position: "absolute",
          top: "0",
          bottom: "0",
          left: "0",
          display: "block",
          width: pxToRem(4),
          borderRadius: pxToRem(8),
          background: "#8292ab",
        },
      },

      "& ul li": {
        color: text.main,
      },
    },
  };
});
