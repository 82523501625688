import React from "react";

import { Grid } from "@mui/material";
import SusBox from "@components/SusBox";
import SusTypography from "@components/SusTypography";

interface IFormulaSectionProps {
  title?: string;
  description?: any;
  light?: boolean;
  direction?: string;
}

const TabsFormSection: React.FC<React.PropsWithChildren<IFormulaSectionProps>> = ({
  title,
  description,
  direction,
  children,
}) => {
  return (
    <>
      <SusBox
        display="flex"
        justifyContent="flex-start"
        alignItems="center"
        className="TabBox"
        sx={{
          transition: "width 0.2s ease-in-out",
          width: "100%",
          padding: "24px 0",
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={12} md={direction === "row" ? 4 : 12}>
            <SusBox mt={0} mb={1} className="TabTitle">
              <SusTypography variant="h5" fontWeight="medium">
                {title}
              </SusTypography>
            </SusBox>
            <SusBox mt={1} mb={1} className="TabDescription">
              <SusTypography variant="body1" color="text" fontWeight="regular" fontSize="16px">
                {description}
              </SusTypography>
            </SusBox>
          </Grid>
          <Grid item xs={1}></Grid>
          {direction === "row" ? (
            <Grid
              item
              xs={12}
              md={7}
              sx={{
                justifyContent: "flex-start",
                display: "grid",
              }}
            >
              {children}
            </Grid>
          ) : (
            <Grid
              item
              xs={12}
              sx={{
                "& .MuiInputLabel-root": { background: "transparent" },
                display: "grid",
              }}
            >
              {children}
            </Grid>
          )}
        </Grid>
      </SusBox>
    </>
  );
};

TabsFormSection.defaultProps = {
  direction: "row",
};

export default TabsFormSection;
