import React, { useState } from "react";

import Grid from "@mui/material/Grid";

import {
  DialogContentText,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFloppyDisk, faTimes } from "@fortawesome/pro-regular-svg-icons";
import { useDashboardState } from "@src/app.state";
import { WidgetModifyableProp, WidgetProps, WidgetSpecs } from "@src/features/control-room";
import SusBox from "@components/SusBox";
import SusDialog from "@components/SusDialog/SusDialog";
import SusButton from "@components/SusButton";
import SusTypography from "@components/SusTypography";

interface IAddControlRoomWidgetDetailsDialogProps {
  specs: WidgetSpecs;
  onAddWidget: (specs: WidgetSpecs, modifyablePropValues: WidgetProps) => void;
}

const AddControlRoomWidgetDetailsDialog: React.FC<IAddControlRoomWidgetDetailsDialogProps> = ({
  specs,
  onAddWidget,
}) => {
  const { currentDialog, setCurrentDialog } = useDashboardState();
  const [modifyablePropValues, setModifyablePropValues] = useState<WidgetProps>(
    Object.fromEntries(
      specs.modifyableProps?.map(({ name, options }) => [name, options?.[0].value || ""]) || []
    )
  );

  const handleSave = () => {
    onAddWidget(specs, modifyablePropValues);
    setCurrentDialog(null);
  };

  const handleClose = () => {
    setCurrentDialog(null);
  };

  const handleInputChange = (propName: string, e: { target: { value: unknown } } | null) => {
    setModifyablePropValues({
      ...modifyablePropValues,
      [propName]: e?.target?.value as string,
    });
  };

  const makeDialogInput = ({ label, name, options }: WidgetModifyableProp) =>
    options ? (
      <FormControl key={name} fullWidth sx={{ m: 1.5 }} variant="outlined">
        <InputLabel id={`${name}-label`}>{label}</InputLabel>
        <Select
          labelId={`${name}-label`}
          id={`${name}-input`}
          value={modifyablePropValues[name]}
          label={label}
          onChange={(e) => handleInputChange(name, e)}
        >
          {options.map(({ label: optionLabel, value }) => (
            <MenuItem key={optionLabel} value={value}>
              <SusBox>{optionLabel}</SusBox>
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    ) : (
      <TextField
        value={modifyablePropValues[name]}
        label={label}
        onChange={(e) => handleInputChange(name, e)}
        variant="standard"
        sx={{
          "& .MuiInput-root": {
            backgroundColor: "transparent !important",
            border: "none !important",
          },
        }}
      />
    );

  return (
    <SusDialog
      title={`Add Widget "${specs.widgetName}"`}
      open={currentDialog.type === "AddControlRoomWidgetDetails"}
      onClose={handleClose}
      sx={{ minWidth: "60%" }}
      actions={(handleCloseConfirm) => (
        <>
          <SusButton
            size="medium"
            color="text"
            variant="outlined"
            sx={{
              lineHeight: "1",
              mt: { xs: 1, md: 0 },
              mr: { xs: 1, sm: 1, md: 1 },
              ml: "auto",
              paddingLeft: "1rem",
              paddingRight: "1rem",
              minWidth: "auto",
            }}
            onClick={handleCloseConfirm}
          >
            <SusBox mr="0.5rem" color="text" display="inlineFlex">
              <FontAwesomeIcon icon={faTimes} size="xs" />
            </SusBox>
            Cancel
          </SusButton>
          <SusButton
            color="primary"
            size="medium"
            sx={{
              lineHeight: "1",
              mt: { xs: 1, md: 0 },
              mr: { xs: 1, sm: 1, md: 1 },
              ml: "",
              paddingLeft: "1rem",
              paddingRight: "1rem",
              minWidth: "auto",
            }}
            onClick={handleSave}
          >
            <SusBox mr="0.5rem" color="white" display="inlineFlex">
              <FontAwesomeIcon icon={faFloppyDisk} size="xs" />
            </SusBox>
            Save
          </SusButton>
        </>
      )}
    >
      <SusBox p={2}>
        <SusBox mb={1} flexDirection="row">
          <DialogContentText>
            <SusTypography variant="body2">
              In order to add the selected widget, please choose a configuration for it <br /> by
              selecting values below.
            </SusTypography>
          </DialogContentText>
        </SusBox>
        <SusBox flexDirection="row" display="flex" gap="1rem" pt="1rem" pb="0.5rem">
          <Grid container mb={1} flexDirection="row">
            {specs.modifyableProps?.map(makeDialogInput)}
          </Grid>
        </SusBox>
      </SusBox>
    </SusDialog>
  );
};

export default AddControlRoomWidgetDetailsDialog;
