// CustomDrawer.tsx

import Drawer, { DrawerProps } from "@mui/material/Drawer";
import { styled } from "@mui/material/styles";

interface CustomDrawerProps extends DrawerProps {
  ownerState: {
    transparentSidenav: boolean;
    miniSidenav: boolean;
  };
}

const CustomDrawer = styled(Drawer)<CustomDrawerProps>(({ theme, ownerState }) => {
  const { palette, boxShadows, transitions, breakpoints, functions } = theme;
  const { transparentSidenav, miniSidenav } = ownerState;

  const sidebarWidth = 250;
  const { white, transparent } = palette;
  const { pxToRem } = functions;

  const drawerOpenStyles = () => ({
    transform: "translateX(0)",
    overflow: "hidden",
    transition: transitions.create("transform", {
      easing: transitions.easing.sharp,
      duration: transitions.duration.shorter,
    }),
    boxShadow: "none",
    margin: "0",
    height: "100vh",
    borderRadius: 0,
    borderRight: `1px solid ${palette.grey[200]}`,

    [breakpoints.up("xl")]: {
      backdropFilter: `initial`,
      backgroundColor: transparentSidenav ? transparent.main : white.main,
      left: "0",
      width: sidebarWidth,
      transform: "translateX(0)",
      transition: transitions.create(["width", "background-color"], {
        easing: transitions.easing.sharp,
        duration: transitions.duration.enteringScreen,
      }),
    },
  });

  // Styles for the sidenav when miniSidenav={true}
  const drawerCloseStyles = () => ({
    transform: `translateX(${pxToRem(-320)})`,
    transition: transitions.create("transform", {
      easing: transitions.easing.sharp,
      duration: transitions.duration.shorter,
    }),

    [breakpoints.up("xl")]: {
      backdropFilter: `initial`,
      backgroundColor: transparentSidenav ? transparent.main : white.main,
      boxShadow: boxShadows.md,
      marginBottom: transparentSidenav ? 0 : "inherit",
      left: "0",
      width: pxToRem(96),
      overflowX: "hidden",
      transform: "translateX(0)",
      transition: transitions.create(["width", "background-color"], {
        easing: transitions.easing.sharp,
        duration: transitions.duration.shorter,
      }),
    },
  });

  return {
    "& .MuiDrawer-paper": {
      border: "none",
      ...(miniSidenav ? drawerCloseStyles() : drawerOpenStyles()),
    },
  };
});

export default CustomDrawer;
