import axios, { AxiosResponse, InternalAxiosRequestConfig } from "axios";

import { camelizeKeys, decamelizeKeys } from "humps";
import { apiBaseUrl } from "./paths";
import Cookies from "js-cookie";

const dashboardAxios = axios.create({ baseURL: apiBaseUrl });

// Axios middleware to convert all api responses to camelCase
dashboardAxios.interceptors.response.use(
  (response: AxiosResponse) => {
    if (response.data && response.headers["content-type"] === "application/json") {
      response.data = camelizeKeys(response.data);
    }
    return response;
  },
  (error) => {
    if (error?.response?.data && error?.response?.headers["content-type"] === "application/json") {
      error.response.data = camelizeKeys(error.response.data);
    }
    return Promise.reject(error);
  }
);

// Axios middleware to convert all api requests to snake_case
dashboardAxios.interceptors.request.use(async (config: InternalAxiosRequestConfig) => {
  const newConfig = { ...config };
  if (newConfig?.headers?.["Content-Type"] === "multipart/form-data") return newConfig;
  if (config.params && !config.params?.hasOwnProperty("_noDecamelize")) {
    newConfig.params = decamelizeKeys(config.params);
  }
  if (config.data && !config.params?.hasOwnProperty("_noDecamelize")) {
    newConfig.data = decamelizeKeys(config.data);
  }
  if (config.params?.hasOwnProperty("_noDecamelize")) {
    delete config.params._noDecamelize;
  }
  return newConfig;
});

// Always include cookies and add CSRF token to all "unsafe" operations
dashboardAxios.interceptors.request.use(
  (request) => {
    request.withCredentials = true;
    if (
      request.headers &&
      request.method &&
      !["GET", "HEAD", "OPTIONS", "TRACE"].includes(request.method.toUpperCase())
    ) {
      const csrftoken = Cookies.get("csrftoken");
      if (csrftoken) {
        request.headers["X-CSRFToken"] = csrftoken;
      }
    }
    return request;
  },
  (error) => Promise.reject(error)
);

export interface ApiResponse<D = unknown> {
  success: boolean;
  data?: D | null;
  errors?: Map<string, string[]> | null;
}

export default dashboardAxios;
