import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
  dataGrid: {
    "& .MuiDataGrid-row": {
      position: "relative",
    },
    "& .MuiDataGrid-columnHeaderTitleContainer": {
      overflow: "visible",
    },
    "& .MuiDataGrid-columnHeader:first-of-type": {
      paddingLeft: "1rem",
    },
    "& .MuiDataGrid-cell:first-of-type": {
      paddingLeft: "2rem",
    },
    "& .MuiDataGrid-pinnedColumnHeaders": {
      backgroundColor: "#ffffff",
    },
    "& .MuiDataGrid-pinnedColumns": {
      backgroundColor: "#ffffff",
    },
    "& .MuiDataGrid-cell": {
      userSelect: "none",
      borderBottom: "1px solid #e6e9ed",

      "&:focus": {
        outline: "none",
      },
    },
    "& .MuiTable": {
      position: "relative",
    },
    "& .MuiTableRow-header": {
      overflow: "visible",
    },
    "& .MuiTableHead-root": {
      padding: "0",
      width: "100%",
      borderBottom: "0.0625rem solid #dcdcdc",
      "& .MuiTableRow-root": {
        "& .MuiTableCell-head": {
          width: "20%",
          borderBottom: "initial",
        },
      },
    },
    "& .MuiTableBody-root": {
      display: "table-row",
      borderBottom: "initial",
      "& .MuiTableCell-root": {
        borderBottom: "initial",
        width: "20%",
      },
    },
  },
});

export default useStyles;
