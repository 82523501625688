import { forwardRef } from "react";

// prop-types is a library for typechecking of props
// Custom styles for SusAvatar
import { Badge } from "@mui/material";
import SusAvatarRoot from "@components/SusAvatar/SusAvatarRoot";

interface stingToColorProps {
  color: string;
  textColor: string;
}
function stringToColor(string: string): stingToColorProps {
  const palette = [
    "#619D8E",
    "#8E619B",
    "#8E8A61",
    "#616C8E",
    "#8E6158",
    "#618E72",
    "#A98E61",
    "#8E6A61",
    "#61548E",
    "#8E7F61",
    "#6E8E61",
    "#618EA1",
    "#9B618E",
    "#8E8461",
    "#61628E",
    "#8E6E61",
    "#618E54",
    "#7C8E61",
    "#618E83",
    "#D78E61",
    "#61698E",
    "#8E5B61",
    "#618E44",
    "#52618E",
    "#8E7134",
    "#B88E61",
    "#8E5283",
    "#3C618E",
    "#61798E",
    "#8E2D61",
    "#8E6D9B",
    "#618E16",
    "#228E61",
    "#8EA12E",
    "#61618E",
    "#61638E",
    "#7F618E",
    "#618E25",
    "#8E409B",
    "#618E6B",
    "#5B8E61",
    "#618E98",
    "#C08E61",
    "#61608E",
    "#8E1A61",
    "#614C8E",
    "#8E9158",
    "#8E6131",
    "#8E3471",
    "#3E8E61",
    "#61608A",
    "#61538E",
    "#61658E",
    "#7B618E",
    "#8E8881",
    "#868E61",
    "#E18E61",
    "#61628A",
    "#8E4358",
    "#5F618E",
    "#618EB0",
    "#9E8E61",
    "#616E8E",
    "#8E3358",
    "#618E0F",
    "#158E61",
    "#8E9B34",
    "#7D618E",
    "#616B8E",
    "#8E2043",
    "#47518E",
    "#8E5E31",
    "#B08E61",
    "#8E2E83",
    "#34618E",
    "#61728E",
    "#8E1331",
    "#8E6C9B",
    "#618E07",
    "#1B8E61",
    "#8E8C2E",
    "#5A618E",
    "#61608C",
    "#677C8E",
    "#808E61",
    "#618E70",
    "#8E532E",
    "#618E93",
    "#C88E61",
    "#61628C",
    "#8E2034",
    "#594E8E",
    "#8E8158",
    "#8E6139",
    "#8E3C71",
    "#3A618E",
  ];

  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  const index = Math.abs(hash) % palette.length;
  const color = palette[index];

  /* eslint-enable no-bitwise */

  // Define a threshold for contrast ratio
  const contrastThreshold = 3;

  // Check the contrast between the color and white and black
  const contrastWithWhite = getContrastRatio(color, "#fff");
  const contrastWithBlack = getContrastRatio(color, "#000");

  // Choose the text color based on the higher contrast ratio
  const textColor =
    contrastWithWhite >= contrastWithBlack
      ? contrastWithWhite >= contrastThreshold
        ? "#fff"
        : "#67748e"
      : contrastWithBlack >= contrastThreshold
        ? "#67748e"
        : "#fff";

  return { color, textColor };
}

function getContrastRatio(color1: string, color2: string) {
  const luminance1 = getLuminance(color1);
  const luminance2 = getLuminance(color2);
  const ratio = (luminance1 + 0.05) / (luminance2 + 0.05);
  return ratio;
}

function getLuminance(color: string) {
  const r = parseInt(color.substr(1, 2), 16) / 255;
  const g = parseInt(color.substr(3, 2), 16) / 255;
  const b = parseInt(color.substr(5, 2), 16) / 255;
  const luminance = 0.2126 * r + 0.7152 * g + 0.0722 * b;
  return luminance;
}

function stringAvatar(name: string) {
  const { color, textColor } = stringToColor(name || "");
  return {
    sx: {
      backgroundColor: color,
      color: `${textColor} !important`,
    },
    children: name
      .split(" ")
      .map((part) => part[0])
      .join(""),
  };
}

interface SusAvatarProps {
  className?: string;
  name: string;
  size?: "xs" | "sm" | "md" | "lg" | "xl" | "xxl";
  status?: "active" | "inactive";
}

const SusAvatar = forwardRef<HTMLDivElement, SusAvatarProps>(
  (
    {
      className,
      size,
      name,
      status, // status kann jetzt string oder undefined sein
      ...rest
    },
    ref
  ) => {
    const validatedStatus = status || "inactive";
    const badgeStyle = {
      ".MuiBadge-badge": {
        right: "24%",
        minWidth: "10px",
        height: "10px",
        bottom: "20%",
        boxShadow: "0 0 0 2px #fff",
        borderRadius: "50%",
        backgroundColor: "#18B9DE",
      },
      ".MuiBadge-badge::after": {
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        borderRadius: "50%",
        animation: "ripple 1.2s infinite ease-in-out",
        border: "1px solid #18B9DE",
        content: '""',
      },
      "@keyframes ripple": {
        "0%": {
          transform: "scale(.8)",
          opacity: 1,
        },
        "100%": {
          transform: "scale(1.9)",
          opacity: 0,
        },
      },
    };

    return (
      <>
        {status === "active" ? (
          <>
            <Badge
              overlap="circular"
              anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
              variant="dot"
              className={`${validatedStatus} ${className}`}
              sx={badgeStyle}
            >
              <SusAvatarRoot
                ref={ref}
                className={`${validatedStatus} ${className}`}
                ownerState={{ size }}
                {...stringAvatar(name)}
                {...rest}
              />
            </Badge>
          </>
        ) : (
          <SusAvatarRoot
            ref={ref}
            className={`${validatedStatus} ${className}`}
            ownerState={{ size }}
            {...stringAvatar(name)}
            {...rest}
          />
        )}
      </>
    );
  }
);

export default SusAvatar;
