export const API_URI = process.env.REACT_APP_API_URI || "/settings/api/";
export const ADMIN_FRONTEND_URL = process.env.REACT_APP_ADMIN_FRONTEND_URL || "/settings";
export const NOTIFICATION_UPDATE_INTERVAL =
  parseInt(process.env.REACT_APP_NOTIFICATION_UPDATE_INTERVAL || "") || 60 * 1001;
export const MUI_X_LICENSE_KEY = process.env.REACT_APP_MUI_X_LICENSE_KEY || "";
export const MAX_FAVORITE_COMPANIES = 3;
export const MAX_UPLOAD_FILESIZE = 5; // in MB
export const DATA_REFETCH_INTERVAL = 2 * 60 * 1000;
export const SERVICE_WORKER_TIMEOUT =
  parseInt(process.env.SERVICE_WORKER_TIMEOUT || "") || 10 * 60 * 1000;

export const LOGOUT_EVENT_NAME = "logout";

export const SERVICE_CONSENT_COOKIE_NAME = "serviceConsent";
export const ANALYTICS_SERVICE_KEY = self.analyticsServiceKey || "";
export const TRACKING_ID = process.env.REACT_APP_TRACKING_ID || "";
export const TRACKING_SCRIPT_ID = process.env.REACT_APP_TRACKING_SCRIPT_ID || "tracking-script";
