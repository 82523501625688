import { useEffect, useRef, useState } from "react";
import { register, unregister } from "../serviceWorkerRegistration";

export const useServiceWorker = ({ timeout = 1000 }) => {
  const [updateAvailable, setUpdateAvailable] = useState<boolean>(false);
  const registrationRef = useRef<ServiceWorkerRegistration>();

  useEffect(() => {
    const interval = setInterval(() => {
      if (registrationRef.current) {
        registrationRef.current.update();
      }
    }, timeout);
    return () => clearInterval(interval);
  }, [registrationRef]);

  const onRegistered = (registration: ServiceWorkerRegistration) => {
    registrationRef.current = registration;

    // ensure the case when the updatefound event was missed is also handled
    // by re-invoking the prompt when there's a waiting Service Worker
    if (registration.waiting) {
      setUpdateAvailable(true);
      // invokeServiceWorkerUpdateFlow(registration)
    } else {
      setUpdateAvailable(false);
    }

    // detect Service Worker update available and wait for it to become installed
    registration.addEventListener("updatefound event", () => {
      if (registration.installing) {
        // wait until the new Service worker is actually installed (ready to take over)
        registration.installing.addEventListener("statechange", () => {
          if (registration.waiting) {
            // if there's an existing controller (previous Service Worker), show the prompt
            if (navigator.serviceWorker.controller) {
              setUpdateAvailable(true);
              // invokeServiceWorkerUpdateFlow(registration);
            }
          }
        });
      }
    });

    let refreshing = false;

    // detect controller change and refresh the page
    navigator.serviceWorker.addEventListener("controllerchange", () => {
      if (!refreshing) {
        if (typeof window !== "undefined") {
          const now = new Date();
          localStorage.setItem("lastPageRefresh", now.toLocaleDateString());
        }
        window.location.reload();
        refreshing = true;
      }
    });
  };

  const onUpdate = (registration: ServiceWorkerRegistration) => {
    setUpdateAvailable(!!registration?.waiting);
  };

  const update = () => {
    if (updateAvailable && registrationRef.current?.waiting) {
      registrationRef.current.waiting.postMessage({ type: "SKIP_WAITING" });
    }
  };

  useEffect(() => {
    register({ onSuccess: onRegistered, onUpdate });
    return () => unregister();
  }, []);

  return { update, updateAvailable };
};
