import React, { useEffect, useState } from "react";
import Menu from "@mui/material/Menu";
import Divider from "@mui/material/Divider";
import { Badge, Collapse, Grid, Theme } from "@mui/material";
import { faArchive, faBell, faCommentCheck } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import IconButton from "@mui/material/IconButton";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

import SimpleBar from "simplebar-react";
import "simplebar/dist/simplebar.min.css";
import { Notification } from "@src/types";
import useStyles from "./styles";
import { TransitionGroup } from "react-transition-group";

import { delay } from "lodash";

import { SyncLoader } from "react-spinners";
import useNotifications from "@hooks/useNotifications";
import NotificationItem from "@components/NotificationItem/NotificationItem";
import { genericReferenceToModuleName } from "@src/utils/genericReference";
import SusTypography from "@components/SusTypography";
import SusBox from "@components/SusBox";
import NotificationSettingsPanel from "@components/NotificationSettingsPanel/NotificationSettingsPanel";
import SusButton from "@components/SusButton";
import theme from "@theme/index";

interface INotificationsProps {
  light: boolean;
}

const Notifications: React.FC<React.PropsWithChildren<INotificationsProps>> = ({ light }) => {
  const [notificationsMenuOpen, setNotificationsMenuOpen] = useState<Element | null>(null);

  const handleOpenNotificationMenu = (event: any) => setNotificationsMenuOpen(event.currentTarget);
  const handleCloseNotificationMenu = () => setNotificationsMenuOpen(null);
  const [tabsOrientation, setTabsOrientation] = useState<"vertical" | "horizontal" | undefined>(
    "horizontal"
  );
  const [tabValue, setTabValue] = useState(0);
  const [showArchive, setShowArchive] = useState<boolean>(false);
  const { readNotifications, unreadNotifications, toggleUnreadStatus, navigateToSubject } =
    useNotifications();

  useEffect(() => {
    delay(() => {
      setTabValue(0);
      if (!notificationsMenuOpen) {
        setShowArchive(false);
      }
    }, 500);
  }, [notificationsMenuOpen]);

  function handleTabsOrientation() {
    if (typeof window === "undefined") return;

    return window.innerWidth < theme.breakpoints.values.sm
      ? setTabsOrientation("vertical")
      : setTabsOrientation("horizontal");
  }

  window.addEventListener("resize", handleTabsOrientation);

  const navbarIconButton = ({ typography: { body2 } }: Theme) => ({
    px: 0.75,

    "& .material-icons, .material-icons-round": {
      fontSize: `${body2} !important`,
    },

    "& .MuiTypography-root": {
      display: "none",
    },
  });
  const handleSetTabValue = (event: any, newValue: any) => setTabValue(newValue);

  const getItemClass = (notification: Notification) => {
    if (showArchive) {
      return "";
    }
    if (notification.prio === 1) {
      return "item-class-high";
    } else if (notification.prio === 2) {
      return "item-class-info";
    }
  };

  const makeNotificationTitle = (verb: string, target: string) => {
    const moduleName = genericReferenceToModuleName(target);
    switch (verb) {
      case "COMMENT":
        return `New comment in ${moduleName}`;
      case "MENTION":
        return `You got mentioned in ${moduleName}`;
      default:
        return `${verb} ${moduleName}`;
    }
  };

  const renderNotificationItem = (notification: Notification) => {
    return (
      <Collapse key={notification.id}>
        <NotificationItem
          className={getItemClass(notification)}
          key={notification.id}
          image={<img src={notification.actor?.avatarUrl} alt="person" />}
          title={makeNotificationTitle(notification.verb, notification.target)}
          messageActor={`${notification.actor?.firstName || "unknown"} ${
            notification.actor?.lastName || "unknown"
          }`}
          message={notification.description}
          unread={notification.unread}
          date={new Date(notification.timestamp)}
          handleMessageClick={() => {
            handleCloseNotificationMenu();
            if (notification.unread) {
              toggleUnreadStatus?.(notification);
            }
            navigateToSubject?.(notification);
          }}
          handleIconClick={() => toggleUnreadStatus?.(notification)}
        />
      </Collapse>
    );
  };

  const renderNoNotification = (
    <Collapse key="0">
      <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justifyContent="center"
        style={{ minHeight: "150px" }}
      >
        <Grid item xs={3}>
          <SusTypography variant="subtitle2" color="secondary">
            There is no new message <FontAwesomeIcon icon={faCommentCheck} />
          </SusTypography>
        </Grid>
      </Grid>
    </Collapse>
  );

  const renderLoader = (
    <Collapse key="0">
      <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justifyContent="center"
        style={{ minHeight: "150px" }}
      >
        <Grid item xs={3}>
          <SyncLoader color="#8392ab" />
        </Grid>
      </Grid>
    </Collapse>
  );

  const notifications = (showArchive ? readNotifications : unreadNotifications)?.filter(
    (notification) => tabValue === 0 || notification.prio === tabValue
  );
  const { BarTabs } = useStyles();

  const renderBellDot = () => {
    if (!unreadNotifications || unreadNotifications?.length === 0) {
      return (
        <>
          <Badge variant="dot" color="primary" invisible>
            <FontAwesomeIcon icon={faBell} size="xs" />
          </Badge>
        </>
      );
    }
    const highPrioUnread = unreadNotifications?.filter((notification) => notification.prio === 1);
    if (highPrioUnread?.length > 0) {
      return (
        <>
          <Badge color="primary" badgeContent={highPrioUnread.length} max={99}>
            <FontAwesomeIcon icon={faBell} size="xs" />
          </Badge>
        </>
      );
    } else {
      return (
        <>
          <Badge variant="dot" color="primary">
            <FontAwesomeIcon icon={faBell} size="xs" />
          </Badge>
        </>
      );
    }
  };

  return (
    <>
      <IconButton
        sx={navbarIconButton}
        aria-controls="notification-menu"
        aria-haspopup="true"
        onClick={handleOpenNotificationMenu}
      >
        {renderBellDot()}
      </IconButton>
      <Menu
        anchorEl={notificationsMenuOpen}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        open={Boolean(notificationsMenuOpen)}
        onClose={handleCloseNotificationMenu}
        sx={{ minWidth: "400" }}
        className={BarTabs}
      >
        <AppBar position="static" sx={{ paddingBottom: "0.5rem" }}>
          <Tabs
            orientation={tabsOrientation}
            value={tabValue}
            onChange={handleSetTabValue}
            sx={{ background: "transparent" }}
          >
            <Tab label="All" />
            <Tab label="Primary" />
            <Tab label="Secondary" />
          </Tabs>
        </AppBar>

        <SimpleBar style={{ maxHeight: 300, width: 470 }} autoHide={true}>
          <TransitionGroup>
            {notifications
              ? notifications.length > 0
                ? notifications.map((notification) => renderNotificationItem(notification))
                : renderNoNotification
              : renderLoader}
          </TransitionGroup>
        </SimpleBar>

        <Divider sx={{ margin: "0 0 0.5rem 0" }} color={light ? "dark" : "white"} />
        <SusBox display="flex" justifyContent="flex-end" alignItems="center">
          <NotificationSettingsPanel />
          <SusButton
            aria-haspopup="true"
            onClick={() => setShowArchive(!showArchive)}
            color={showArchive ? "primary" : "white"}
            sx={{ boxShadow: "none" }}
          >
            <FontAwesomeIcon icon={faArchive} size="xs" />
          </SusButton>
        </SusBox>
      </Menu>
    </>
  );
};

export default Notifications;
