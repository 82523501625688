import React, { useMemo, useState } from "react";

import { Grid } from "@mui/material";
import SusBox from "@components/SusBox";
import ContractPositionDetailView from "@components/ContractPositionDetailView/ContractPositionDetailView";
import { paths } from "@src/paths";
import VesselFinder from "@components/VesselFinder/VesselFinder";

const Details: React.FC = () => {
  const [imo, setImo] = useState<number | undefined>();

  const extractImo = (data: any) => {
    if (data && "imo" in data) {
      setImo(data.imo);
    }
  };

  const vesselFinder = useMemo(
    () => <VesselFinder imo={imo} showTrack title={"VesselFinder"} loading={false} />,
    [imo]
  );

  return (
    <SusBox className="wrapper-contract-position-details">
      <ContractPositionDetailView overviewUrl={paths.home} onDataChange={extractImo}>
        {imo && (
          <Grid container spacing={3} mt={0.5}>
            <Grid item xs={12} md={12} xl={12} sx={{ height: "400px" }}>
              {vesselFinder}
            </Grid>
          </Grid>
        )}
      </ContractPositionDetailView>
    </SusBox>
  );
};

export default Details;
