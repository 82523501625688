import React, { useState } from "react";

import {
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  List,
  ListItem,
  ListItemIcon,
  OutlinedInput,
  Theme,
} from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle } from "@fortawesome/pro-solid-svg-icons";
import { useForm } from "react-hook-form";
import { SubmitHandler } from "react-hook-form/dist/types/form";

import { faFloppyDisk, faTimes } from "@fortawesome/pro-regular-svg-icons";
import LoadingButton from "@mui/lab/LoadingButton";
import { Helmet } from "react-helmet-async";
import useAlerts from "@hooks/useAlerts";
import useAuth from "@hooks/useAuth";
import { IChangePasswordParams } from "@src/context/AuthProvider";
import { ApiResponse } from "@src/api";
import SusBox from "@components/SusBox";
import SusButton from "@components/SusButton";
import TabsFormSection from "@components/FormularSection/TabsFormSection";

const SecurityTabPage: React.FC = () => {
  const { notifySuccess, notifyError } = useAlerts();
  const { changePassword } = useAuth();
  const {
    register,
    handleSubmit,
    reset,
    formState: { isDirty },
  } = useForm<IChangePasswordParams>();
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState<ApiResponse["errors"] | null | undefined>();

  const onSubmit: SubmitHandler<IChangePasswordParams> = async (data, event) => {
    event?.preventDefault();
    setLoading(true);
    const response = await changePassword?.(data);
    setLoading(false);
    if (!response?.success) {
      setErrors(response?.errors);
      notifyError({
        title: "Error",
        content: "Something went wrong. Please check your input.",
        autoHideDuration: 5000,
      });
    } else {
      setErrors(null);
      notifySuccess({
        title: "Success",
        content: "Password changed.",
        autoHideDuration: 5000,
      });
    }
  };

  return (
    <>
      <Helmet>
        <title>Login & Security - Preferences | PEK Dashboard </title>
      </Helmet>
      <TabsFormSection
        title="Change Password"
        description={
          <>
            You can change your current password here
            <List sx={{ marginTop: "1rem", marginBottom: "1rem" }}>
              <ListItem>
                <ListItemIcon
                  sx={{
                    minWidth: "35px",
                    svg: { width: "10px" },
                  }}
                >
                  <FontAwesomeIcon color={"dark"} icon={faCircle} size="xs" />
                </ListItemIcon>
                At least 8 characters
              </ListItem>
              <ListItem>
                <ListItemIcon
                  sx={{
                    minWidth: "35px",
                    svg: { width: "10px", height: "10px" },
                  }}
                >
                  <FontAwesomeIcon color={"dark"} icon={faCircle} size="xs" />
                </ListItemIcon>
                One special character (@$!%_#?&-)
              </ListItem>
              <ListItem>
                <ListItemIcon
                  sx={{
                    minWidth: "35px",
                    svg: { width: "10px", height: "10px" },
                  }}
                >
                  <FontAwesomeIcon color={"dark"} icon={faCircle} size="xs" />
                </ListItemIcon>
                One number (2 are recommended)
              </ListItem>
              <ListItem>
                <ListItemIcon
                  sx={{
                    minWidth: "35px",
                    svg: { width: "10px", height: "10px" },
                  }}
                >
                  <FontAwesomeIcon color={"dark"} icon={faCircle} size="xs" />
                </ListItemIcon>
                One uppercase letter
              </ListItem>
              <ListItem>
                <ListItemIcon
                  sx={{
                    minWidth: "35px",
                    svg: { width: "10px", height: "10px" },
                  }}
                >
                  <FontAwesomeIcon color={"dark"} icon={faCircle} size="xs" />
                </ListItemIcon>
                One lowercase letter
              </ListItem>
            </List>
          </>
        }
      >
        <Grid
          container
          sx={{
            flexDirection: "column",
          }}
        >
          <Grid xs={12}>
            <FormControl
              sx={({ breakpoints }: Theme) => ({
                width: "280px",
                maxWidth: "100%",
                marginBottom: "0.5rem",
                marginRight: "0.5rem",
                [breakpoints.down("md")]: {
                  width: "100%",
                },
              })}
              variant="outlined"
            >
              <InputLabel htmlFor="standard-adornment-password">Old Password</InputLabel>
              <OutlinedInput
                label="Old Password"
                fullWidth
                {...register("oldPassword", { required: true })}
                inputProps={{ type: "password", autoComplete: "" }}
                error={errors?.has("oldPassword")}
              />
              {errors && (
                <FormHelperText id="standard-weight-helper-text">
                  {errors?.get("oldPassword")}
                </FormHelperText>
              )}
            </FormControl>
            <FormControl
              sx={({ breakpoints }: Theme) => ({
                width: "280px",
                maxWidth: "100%",
                marginBottom: "0.5rem",
                marginRight: "0.5rem",
                [breakpoints.down("md")]: {
                  width: "100%",
                },
              })}
              variant="outlined"
            >
              <InputLabel htmlFor="standard-adornment-password">New Password</InputLabel>
              <OutlinedInput
                label="New Password"
                fullWidth
                {...register("newPassword1", { required: true })}
                inputProps={{ type: "password", autoComplete: "" }}
                error={errors?.has("newPassword1")}
              />
              {errors && (
                <FormHelperText id="standard-weight-helper-text">
                  {errors?.get("newPassword1")}
                </FormHelperText>
              )}
            </FormControl>
            <FormControl
              sx={({ breakpoints }: Theme) => ({
                width: "280px",
                maxWidth: "100%",
                marginBottom: "0.5rem",
                marginRight: "0.5rem",
                [breakpoints.down("md")]: {
                  width: "100%",
                },
              })}
            >
              <InputLabel htmlFor="standard-adornment-password">Re-enter new Password</InputLabel>
              <OutlinedInput
                label="Re-enter new Password"
                fullWidth
                {...register("newPassword2", { required: true })}
                inputProps={{ type: "password", autoComplete: "" }}
                error={errors?.has("newPassword2")}
              />
              {errors && (
                <FormHelperText id="standard-weight-helper-text">
                  {errors?.get("newPassword2")}
                </FormHelperText>
              )}
            </FormControl>
          </Grid>
        </Grid>
      </TabsFormSection>
      <SusBox
        display="flex"
        justifyContent="flex-end"
        sx={{ borderTop: "1px solid #e0e0e0", padding: "1rem 1rem 0 1rem" }}
      >
        {isDirty && (
          <SusButton
            onClick={() => reset({ oldPassword: "", newPassword1: "", newPassword2: "" })}
            size="medium"
            color="text"
            variant="outlined"
            sx={{
              lineHeight: "1",
              mt: { xs: 1, md: 0 },
              mr: { xs: 1, sm: 1, md: 1 },
              ml: "auto",
              paddingLeft: "1rem",
              paddingRight: "1rem",
              minWidth: "auto",
            }}
          >
            <SusBox mr="0.5rem" color="text" display="inlineFlex">
              <FontAwesomeIcon icon={faTimes} size="xs" />
            </SusBox>
            Discard
          </SusButton>
        )}

        <LoadingButton
          onClick={handleSubmit(onSubmit)}
          loading={loading}
          variant="contained"
          color="primary"
          size="medium"
        >
          <SusBox mr="0.5rem" color="white" display="inlineFlex">
            <FontAwesomeIcon icon={faFloppyDisk} size="xs" />
          </SusBox>
          Save
        </LoadingButton>
      </SusBox>
    </>
  );
};

export default SecurityTabPage;
