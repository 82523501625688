import colors from "@theme/base/colors";

const { primary } = colors;

// types
type Types = any;

const formLabel: Types = {
  styleOverrides: {
    root: {
      color: primary.main,
    },
  },
};

export default formLabel;
