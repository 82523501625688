import { create } from "zustand";
import { Breadcrumb, CompanyMembership, ViewSettings } from "./types";
import {
  AddControlRoomWidgetDetailsDialogOptions,
  AddControlRoomWidgetDialogOptions,
  EditUserDialogOptions,
  SalesRepDialogOptions,
  ShowDocumentDialogOptions,
  SupportContactDialogOptions,
} from "@components/Dialogs/Dialogs";
import { ReOrderingDialogOptions } from "@components/ReOrderingDialog/ReOrderingDialog";
import availableViewSettings, { availableViewConfigKeys, ViewConfigKey } from "@src/viewSettings";

export type DialogType =
  | null
  | "NewContract"
  | "NewContractPosition"
  | "ShowDocument"
  | "AddControlRoomWidget"
  | "AddControlRoomWidgetDetails"
  | "SupportContactDialog"
  | "ReOrderingDialog"
  | "AboutDialog"
  | "SalesRepDialog"
  | "EditUser"
  | "SetupDialog"
  | "ServiceConsentDialog";

const defaultLocale = "en-US";
const defaultViewConfigKey: ViewConfigKey = "None";

export type DashboardState = {
  cache: Map<string, any>;
  cacheSet: (key: string, value: any) => void;
  cacheClear: () => void;

  locale: string;
  setLocale: (locale: string) => void;

  viewConfigKey: ViewConfigKey;
  setViewConfigKey: (viewConfigKey: ViewConfigKey) => void;
  viewSettings: ViewSettings;

  tourRunning: boolean;
  setTourRunning: (tourRunning: boolean) => void;
  startTour: () => void;
  stopTour: () => void;

  breadcrumbs: Breadcrumb[];
  setBreadcrumbs: (breadcrumbs: Breadcrumb[]) => void;

  currentCompany: CompanyMembership | null;
  setCurrentCompany: (company: CompanyMembership | null) => void;
  favoriteCompanies: CompanyMembership[];
  setFavoriteCompanies: (companies: CompanyMembership[]) => void;

  currentDialog:
    | { type: null }
    | { type: "NewContract" }
    | { type: "NewContractPosition" }
    | {
        type: "ShowDocument";
        options: ShowDocumentDialogOptions;
      }
    | {
        type: "AddControlRoomWidget";
        options: AddControlRoomWidgetDialogOptions;
      }
    | {
        type: "SupportContactDialog";
        options: SupportContactDialogOptions;
      }
    | {
        type: "ReOrderingDialog";
        options: ReOrderingDialogOptions;
      }
    | {
        type: "AboutDialog";
      }
    | {
        type: "SetupDialog";
      }
    | {
        type: "ServiceConsentDialog";
      }
    | {
        type: "SalesRepDialog";
        options: SalesRepDialogOptions;
      }
    | {
        type: "AddControlRoomWidgetDetails";
        options: AddControlRoomWidgetDetailsDialogOptions;
      }
    | {
        type: "EditUser";
        options: EditUserDialogOptions;
      };

  setCurrentDialog: <T extends DialogType>(
    dialog: T,
    options?: T extends "AddControlRoomWidget"
      ? AddControlRoomWidgetDialogOptions
      : T extends "AddControlRoomWidgetDetails"
        ? AddControlRoomWidgetDetailsDialogOptions
        : T extends "SupportContactDialog"
          ? SupportContactDialogOptions
          : T extends "ReOrderingDialog"
            ? ReOrderingDialogOptions
            : T extends "SalesRepDialog"
              ? SalesRepDialogOptions
              : T extends "ShowDocument"
                ? ShowDocumentDialogOptions
                : T extends "EditUser"
                  ? EditUserDialogOptions
                  : T extends null
                    ? undefined
                    : never
  ) => void;

  loading: boolean;
  setLoading: (isLoading: boolean) => void;
};

export const useDashboardState = create<DashboardState>((set) => ({
  // initial state
  cache: new Map(),
  locale: defaultLocale,
  currentDialog: { type: null },
  currentCompany: null,
  favoriteCompanies: [],
  loading: false,
  viewConfigKey: defaultViewConfigKey,
  viewSettings: availableViewSettings[defaultViewConfigKey],
  breadcrumbs: [],
  tourRunning: false,

  startTour: () => {
    set(() => ({ tourRunning: true }));
  },
  stopTour: () => {
    set(() => ({ tourRunning: false }));
  },
  setTourRunning: (tourRunning) => {
    set(() => ({ tourRunning }));
  },

  // methods for manipulating state
  cacheSet: (key, value) => {
    set(({ cache }) => {
      cache.set(key, value);
      return {
        cache,
      };
    });
  },

  cacheClear: () => {
    set(() => ({ cache: new Map() }));
  },

  setViewConfigKey: (viewConfigKey) => {
    set(() => ({ viewConfigKey, viewSettings: availableViewSettings[viewConfigKey] }));
  },

  setBreadcrumbs: (breadcrumbs) => {
    set(() => ({ breadcrumbs }));
  },

  setCurrentCompany: (company) => {
    set(() => {
      const companyViewConfigKey = company?.viewConfigKey as ViewConfigKey;
      const isValidViewConfigKey = availableViewConfigKeys.includes(companyViewConfigKey || "");
      return {
        cache: new Map<string, any>(),
        currentCompany: company,
        viewConfigKey: isValidViewConfigKey ? companyViewConfigKey : defaultViewConfigKey,
        viewSettings: isValidViewConfigKey
          ? availableViewSettings[companyViewConfigKey]
          : availableViewSettings[defaultViewConfigKey],
      };
    });
  },

  setFavoriteCompanies: (companies) => {
    set(() => {
      return { favoriteCompanies: companies };
    });
  },

  setLocale: (locale) => {
    set(() => ({ locale }));
  },

  setCurrentDialog: (dialog, options) => {
    set(() => ({ currentDialog: { type: dialog as any, options } }));
  },

  setLoading: (isLoading) => {
    set(() => ({ loading: isLoading }));
  },
}));
