import boxShadows from "@theme/base/boxShadows";
import typography from "@theme/base/typography";
import colors from "@theme/base/colors";

import pxToRem from "@theme/functions/pxToRem";

const { lg } = boxShadows;
const { size } = typography;
const { text, white } = colors;

// types
type Types = any;

const menu: Types = {
  defaultProps: {
    disableAutoFocusItem: true,
  },

  styleOverrides: {
    root: {
      transform: "translateZ(1006px) !important",
    },
    paper: {
      maxWidth: "100%",
      boxShadow: lg,
      padding: `${pxToRem(16)} ${pxToRem(8)}`,
      fontSize: size.sm,
      color: text.main,
      textAlign: "left",
      backgroundColor: `${white.main} !important`,
      borderRadius: "0",
    },
  },
};

export default menu;
