import theme from "@theme/index";

export const SusDialogStyles = {
  justifyContent: "space-between",

  "& .MuiDialog-container": {
    alignItems: "start",
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(2),
    background: "#f3f4f6",
    border: "none",
  },
  "& .MuiInputBase-root": {
    justifyContent: "space-between",
  },
};
