// react-quill components
import ReactQuill from "react-quill";
import "quill-mention";

// react-quill styles
import "quill-mention/dist/quill.mention.css";
import "react-quill/dist/quill.bubble.css";

// Custom styles for the SusEditor
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import PropTypes from "prop-types";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPaperPlaneTop } from "@fortawesome/pro-solid-svg-icons";
import { faCheck, faFloppyDisk, faTimes } from "@fortawesome/pro-regular-svg-icons";
import useAuth from "@hooks/useAuth";
import { generateApiPath } from "@src/utils/helpers";
import useApiData from "@hooks/useApiData";
import { apiPaths } from "@src/paths";
import SusEditorRoot from "@components/SusEditor/SusEditorRoot";
import SusBox from "@components/SusBox";
import SusButton from "@components/SusButton";

function SusEditor({ onSubmit, company, distributor, initialMessage, onCancel, ...restProps }) {
  const [message, setMessage] = useState(null);

  const {
    user: { id: currentUserId },
  } = useAuth();

  const apiPath = useMemo(() => {
    if (company) {
      return (
        generateApiPath(apiPaths.companyMembers, {
          id: company?.toString(),
        }) + (distributor ? `?include_distributor=${distributor}` : "")
      );
    }
  }, [company, distributor]);

  const { data: users } = useApiData(apiPath);

  useEffect(() => {
    setMessage(initialMessage);
  }, [initialMessage]);

  const quillRef = useRef();

  const handleClick = () => {
    quillRef?.current?.getEditor().enable();
    quillRef?.current?.focus();
  };

  const mentionList = useCallback(
    (searchTerm, renderItem, mentionChar) => {
      let values = [];

      function getDisplayName(user) {
        if (user.firstName && user.lastName) {
          return `${user.firstName} ${user.lastName}`;
        }
        return "Unnamed User";
      }

      if (mentionChar === "@" && users?.count > 1) {
        values = users?.results
          ?.filter((user) => user.id !== currentUserId)
          ?.map((user) => ({
            id: user.id,
            value: getDisplayName(user),
          }));
      }
      if (searchTerm.length === 0) {
        renderItem(values, searchTerm);
      } else {
        const matches = values?.filter(
          (value) => value?.value?.toLowerCase().indexOf(searchTerm.toLowerCase()) > -1
        );
        renderItem(matches, searchTerm);
      }
    },
    [users, currentUserId]
  );

  const modules = useMemo(
    () => ({
      toolbar: [
        ["bold", "italic", "blockquote"],
        [{ list: "ordered" }, { list: "bullet" }],
      ],
      mention: {
        allowedChars: /^[A-Za-z\sÅÄÖåäö]*$/,
        mentionDenotationChars: ["@"],
        source: mentionList,
        positioningStrategy: "fixed",
      },
    }),
    [mentionList]
  );

  const messageEmpty = !!message && message.replace(/(<([^>]+)>)/gi, "").trim() === "";

  return (
    <SusEditorRoot>
      <SusBox
        display="flex"
        onClick={handleClick}
        flexDirection={{ xs: "column", md: "column" }}
        alignItems="start"
        pb={1.5}
        sx={{
          backgroundColor: "#f2f4f8a1",
          border: "1px solid #e6e9ed",
          borderRadius: "1rem",
          minHeight: "70px",
        }}
      >
        <ReactQuill
          readOnly={true}
          ref={quillRef}
          display="flex"
          flexDirection="column"
          theme="bubble"
          onChange={setMessage}
          value={message || ""}
          modules={modules}
          {...restProps}
        />
        <SusBox display="flex" width="100%" justifyContent="end">
          {onCancel !== null && (
            <SusButton
              variant="contained"
              color="primary"
              size="medium"
              disabled={messageEmpty}
              sx={{
                mt: { xs: 1, md: 0 },
                mr: { xs: 1, sm: 1, md: 1 },
                ml: "auto",
                paddingLeft: "1rem",
                paddingRight: "1rem",
                minWidth: "auto",
                lineHeight: "1",
              }}
              onClick={() => {
                onCancel();
              }}
            >
              {!!onCancel ? (
                <>
                  <SusBox mr="0.5rem" color="white" display="inlineFlex">
                    <FontAwesomeIcon icon={faTimes} size="xs" />
                  </SusBox>
                  cancel
                </>
              ) : (
                <FontAwesomeIcon icon={faTimes} size="xs" />
              )}
            </SusButton>
          )}
          <SusButton
            variant="contained"
            color="primary"
            size="medium"
            disabled={messageEmpty}
            sx={{
              mt: { xs: 1, md: 0 },
              mr: { xs: 1, sm: 1, md: 1 },
              ml: !!onCancel ? "" : "auto",
              paddingLeft: "1rem",
              paddingRight: "1rem",
              minWidth: "auto",
            }}
            onClick={() => {
              onSubmit(message);
              setMessage("");
            }}
          >
            {!!onCancel ? (
              <>
                <SusBox mr="0.5rem" color="white" display="inlineFlex">
                  <FontAwesomeIcon icon={faFloppyDisk} size="xs" />
                </SusBox>
                Save
              </>
            ) : (
              <FontAwesomeIcon icon={!!onCancel ? faCheck : faPaperPlaneTop} size="xs" />
            )}
          </SusButton>
        </SusBox>
      </SusBox>
    </SusEditorRoot>
  );
}

SusEditor.defaultProps = {
  onSubmit: () => null,
  company: null,
  initialMessage: "",
};

// Typechecking props for the SusBox
SusEditor.propTypes = {
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
  company: PropTypes.number,
  initialMessage: PropTypes.string,
};

export default SusEditor;
