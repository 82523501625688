export const paletteChart = [
  "#619D8E",
  "#242F40",
  "#07A467",
  "#FBD87F",
  "#EF6461",
  "#18B9DE",
  "#E5FCC2",
  "#C5D86D",
  "#F9B5AC",
  "#69585F",
  "#BFBFBF",
  "#E8E8E8",
  "#F7DDB6",
  "#E8AEB7",
  "#634B66",
  "#F2C057",
  "#B6D7A8",
  "#F06D61",
  "#A7C5EB",
  "#9F5F80",
  "#FCE2C4",
  "#9EB9D4",
  "#EFB0B6",
  "#5B5EA6",
];

export const colorMap = {
  a: "#619D8E",
  b: "#9EB9D4",
  c: "#07A467",
  d: "#FBD87F",
  e: "#EF6461",
  f: "#18B9DE",
  g: "#E5FCC2",
  h: "#C5D86D",
  i: "#F9B5AC",
  j: "#69585F",
  k: "#BFBFBF",
  l: "#E8E8E8",
  m: "#F7DDB6",
  n: "#E8AEB7",
  o: "#634B66",
  p: "#F2C057",
  q: "#B6D7A8",
  r: "#F06D61",
  s: "#A7C5EB",
  t: "#9F5F80",
  u: "#FCE2C4",
  v: "#242F40",
  w: "#EFB0B6",
  x: "#5B5EA6",
  y: "#B2B2B2",
  z: "#FFA500",
};
