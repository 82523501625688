import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCog, faXmark } from "@fortawesome/pro-solid-svg-icons";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import { styled, useTheme } from "@mui/material/styles";
import { DialogActions, DialogTitle, IconButton, useMediaQuery } from "@mui/material";

import LoadingButton from "@mui/lab/LoadingButton";
import { faFloppyDisk } from "@fortawesome/pro-regular-svg-icons";
import ModuleNotificationSettings from "./ModuleNotificationSettings";
import SusBox from "@components/SusBox";
import SusButton from "@components/SusButton";

// @mui material components

// PEK Dashboard components

const SettingsDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
    border: "none",
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(2),
    background: "#f3f4f6",
  },
}));

const NotificationSettingsPanel: React.FC = () => {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <>
      <SusButton aria-haspopup="true" sx={{ boxShadow: "none" }} onClick={handleClickOpen}>
        <FontAwesomeIcon icon={faCog} size="xs" />
      </SusButton>
      <SettingsDialog
        onClose={handleClose}
        aria-labelledby="Notification Settings"
        open={open}
        maxWidth="md"
        fullScreen={fullScreen}
        fullWidth={true}
      >
        <DialogTitle sx={{ m: 0, p: 2 }}>
          Notification settings
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: "absolute",
              right: 18,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <FontAwesomeIcon icon={faXmark} size="xs" />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          <ModuleNotificationSettings />
        </DialogContent>
        <DialogActions>
          <LoadingButton onClick={handleClose} variant="contained" color="primary" size="medium">
            <SusBox mr="0.5rem" color="white" display="inlineFlex">
              <FontAwesomeIcon icon={faFloppyDisk} size="xs" />
            </SusBox>
            Save
          </LoadingButton>
        </DialogActions>
      </SettingsDialog>
    </>
  );
};
export default NotificationSettingsPanel;
