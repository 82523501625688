export const EditUserDialog = {
  border: "none",

  "& .MuiCard-root": {
    padding: "0",
    "& .MuiBox-root": {
      padding: "0",
    },
  },
  "& .TabDescription": {
    display: "none",
  },
  "& .TabFormActions": {
    padding: "1rem 0",
  },
  "& .SusTabPanel": {
    padding: "0",
  },
};
