import pxToRem from "@theme/functions/pxToRem";

// types
type Types = any;

const dialogActions: Types = {
  styleOverrides: {
    root: {
      padding: pxToRem(16) + " " + pxToRem(32),
    },
  },
};

export default dialogActions;
