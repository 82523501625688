import usWidgets from "./us";
import euWidgets from "./eu";
import { ViewConfigKey } from "@src/viewSettings";
import {
  WidgetConfigurationGetter,
  WidgetLayoutsGetter,
  WidgetRegistryGetter,
} from "@src/features/control-room";

export type AvailableControlRoomConfigs = Partial<
  Record<
    ViewConfigKey,
    {
      getWidgetRegistry: WidgetRegistryGetter;
      getDefaultWidgets: WidgetConfigurationGetter;
      getDefaultLayouts: WidgetLayoutsGetter;
    }
  >
>;

export const availableControlRoomConfigs: AvailableControlRoomConfigs = {
  US: usWidgets,
  EU: euWidgets,
};
