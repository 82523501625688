import React, { useEffect, useState } from "react";
import {
  Box,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Skeleton,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Theme,
  Tooltip,
  Zoom,
} from "@mui/material";
import { Controller, useForm } from "react-hook-form";

import { SubmitHandler } from "react-hook-form/dist/types/form";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import LoadingButton from "@mui/lab/LoadingButton";

import SusSettingListRoot from "./SusSettingListRoot";
import { faPaperPlaneTop, faRotateRight, faTrash } from "@fortawesome/pro-solid-svg-icons";
import useCompanySettings from "@hooks/useCompanySettings";
import { Invitation, roleNames, ROLES } from "@src/types";
import useAlerts from "@hooks/useAlerts";
import { ApiResponse } from "@src/api";
import SusBox from "@components/SusBox";
import colors from "@theme/base/colors";

interface ISusUserListProps {
  companyId: number;
  hasInvitePermission: boolean;
}

type IInvitationFormData = Pick<Invitation, "email" | "role">;

const SusCompanyUserInvite: React.FC<ISusUserListProps> = ({ companyId, hasInvitePermission }) => {
  const { company, loading, sendInvitation, resendInvitation, cancelInvitation, sending } =
    useCompanySettings(companyId);
  const [invitations, setInvitations] = useState<Pick<Invitation, "id" | "email" | "role">[]>([]);
  const { register, handleSubmit, control, reset } = useForm<IInvitationFormData>({
    defaultValues: {
      role: "member",
    },
  });
  const { notifySuccess, notifyError } = useAlerts();

  const [errors, setErrors] = useState<ApiResponse["errors"] | null | undefined>();

  useEffect(() => {
    if (!company) {
      return;
    }
    setInvitations(company.invitations);
  }, [company]);

  const onSubmit: SubmitHandler<IInvitationFormData> = async (data, event) => {
    event?.preventDefault();
    const response = await sendInvitation({ invitation: data });
    if (!response?.success) {
      setErrors(response?.errors);
      notifyError({
        title: "Error",
        content: "Something went wrong. Please check your input.",
        autoHideDuration: 5000,
      });
    } else {
      setErrors(null);
      const invitation = response.data as Invitation;
      setInvitations([...invitations, invitation]);
      reset({
        email: "",
      });
      notifySuccess({
        title: "Success",
        content: "Invitation sent.",
        autoHideDuration: 5000,
      });
    }
  };

  const onResendInvitation = async (id: number) => {
    const response = await resendInvitation({ id });
    if (!response?.success) {
      notifyError({
        title: "Error",
        content: "Something went wrong.",
        autoHideDuration: 5000,
      });
    } else {
      notifySuccess({
        title: "Success",
        content: "Invitation e-mail resent.",
        autoHideDuration: 5000,
      });
    }
  };

  const onCancelInvitation = async (id: number) => {
    const response = await cancelInvitation({ id });
    if (!response?.success) {
      notifyError({
        title: "Error",
        content: "Something went wrong.",
        autoHideDuration: 5000,
      });
    } else {
      setInvitations(invitations.filter((invitation) => invitation.id !== id));
      notifySuccess({
        title: "Success",
        content: "Invitation cancelled.",
        autoHideDuration: 5000,
      });
    }
  };
  const [focussedListRow, setfocussedListRow] = useState<number | null>(null);

  const handleMouseLeave = () => {
    setfocussedListRow(null);
  };

  return loading ? (
    <Skeleton width={200} />
  ) : (
    <Grid
      container
      sx={{
        flexDirection: "row",
      }}
    >
      <Grid item xs={12}>
        <SusSettingListRoot>
          <TableHead>
            <TableRow>
              <TableCell>Member</TableCell>
              <TableCell align="left" colSpan={3}>
                Role
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {hasInvitePermission && (
              <TableRow hover>
                <TableCell
                  sx={({ breakpoints }: Theme) => ({
                    [breakpoints.down("md")]: {
                      width: "100%",
                    },
                  })}
                >
                  <FormControl
                    sx={({ breakpoints }: Theme) => ({
                      width: "280px",
                      maxWidth: "100%",
                      marginBottom: "0.5rem",
                      marginRight: "0.5rem",
                      [breakpoints.down("md")]: {
                        width: "100%",
                      },
                    })}
                  >
                    <OutlinedInput
                      placeholder="Enter e-mail address"
                      {...register("email", { required: true })}
                      error={errors?.has("email")}
                      sx={{}}
                    />

                    {errors && (
                      <FormHelperText id="standard-weight-helper-text">
                        {errors?.get("email")}
                      </FormHelperText>
                    )}
                  </FormControl>
                </TableCell>
                <TableCell>
                  <FormControl
                    variant="outlined"
                    sx={({ breakpoints }: Theme) => ({
                      width: "280px",
                      maxWidth: "100%",
                      marginBottom: "0.5rem",
                      "& .MuiInputBase-root": {
                        margin: "0 !important",
                      },
                      [breakpoints.down("md")]: {
                        width: "100%",
                      },
                    })}
                  >
                    <InputLabel htmlFor="standard-adornment-role">Role</InputLabel>
                    <Controller
                      control={control}
                      name="role"
                      render={({ field: { onChange, value, name } }) => (
                        <Select
                          variant="outlined"
                          labelId="locale-select-label"
                          id="locale-select-input"
                          value={value}
                          name={name}
                          onChange={onChange}
                          label="Role"
                        >
                          {ROLES.map((role) => (
                            <MenuItem value={role} key={role}>
                              {roleNames[role]}
                            </MenuItem>
                          ))}
                        </Select>
                      )}
                    />
                  </FormControl>
                </TableCell>

                <TableCell align="right" sx={{ color: "rgb(102 116 142 / 39%)" }}>
                  <LoadingButton
                    onClick={handleSubmit(onSubmit)}
                    loading={sending}
                    variant="contained"
                    color="primary"
                    size="medium"
                    startIcon={
                      <InputAdornment position="start">
                        <IconButton
                          aria-label={"save"}
                          edge="start"
                          sx={{
                            color: colors.white.main,
                          }}
                        >
                          <FontAwesomeIcon icon={faPaperPlaneTop} size="xs" />
                        </IconButton>
                      </InputAdornment>
                    }
                  >
                    Send
                  </LoadingButton>
                </TableCell>
              </TableRow>
            )}

            {invitations.map(({ id, email, role }) => (
              <TableRow
                key={id}
                hover
                onMouseEnter={() => setfocussedListRow(id)}
                onMouseLeave={handleMouseLeave}
              >
                <TableCell scope="row">{email}</TableCell>
                <TableCell align="left">{roleNames[role]}</TableCell>
                <TableCell align="right" sx={{ color: "rgb(102 116 142 / 39%)" }}>
                  <SusBox
                    sx={{
                      display: "inline-flex",
                      margin: "0 5px",
                      color: "rgb(102 116 142 / 39%)",
                    }}
                  >
                    {focussedListRow === id ? (
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "flex-end",
                          gap: "1rem",
                        }}
                      >
                        <Tooltip
                          title="Remove invitation"
                          placement="top"
                          enterDelay={500}
                          TransitionComponent={Zoom}
                        >
                          <IconButton
                            onClick={() => onCancelInvitation(id)}
                            color="error"
                            size="medium"
                          >
                            <FontAwesomeIcon icon={faTrash} size="xs" />
                          </IconButton>
                        </Tooltip>
                        <Tooltip
                          enterDelay={500}
                          title="Resend invitation"
                          placement="top"
                          TransitionComponent={Zoom}
                        >
                          <IconButton onClick={() => onResendInvitation(id)} size="medium">
                            <FontAwesomeIcon icon={faRotateRight} size="xs" />
                          </IconButton>
                        </Tooltip>
                      </Box>
                    ) : (
                      <SusBox sx={{ width: "118px", height: "25px" }} />
                    )}
                  </SusBox>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </SusSettingListRoot>
      </Grid>
    </Grid>
  );
};

export default SusCompanyUserInvite;
