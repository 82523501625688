import React, { useEffect, useMemo, useState } from "react";
import {
  FormControl,
  InputAdornment,
  OutlinedInput,
  Skeleton,
  TableContainer,
  Theme,
} from "@mui/material";
import { DataGridPro, GridInitialState, LicenseInfo, useGridApiRef } from "@mui/x-data-grid-pro";
import { faSearch } from "@fortawesome/pro-solid-svg-icons/faSearch";

import useStyles from "./styles";
import { escapeRegExp } from "lodash";
import Divider from "@mui/material/Divider";
import { styled } from "@mui/material/styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSignsPost } from "@fortawesome/pro-solid-svg-icons";

import TableSkeleton from "./skeleton";
import { MUI_X_LICENSE_KEY } from "@src/constants";
import { trycatch } from "@src/utils/helpers";
import SusBox from "@components/SusBox";
import SusTypography from "@components/SusTypography";

LicenseInfo.setLicenseKey(MUI_X_LICENSE_KEY);

interface IDataGridProps {
  rows: any[];
  columns: any[];
  localStoragePrefix: string;
  title?: string;
  loading?: boolean;
  footer?: string;
}

const DataGrid_: React.FC<React.PropsWithChildren<IDataGridProps>> = ({
  rows: dataRows,
  columns,
  localStoragePrefix,
  title,
  loading,
  footer,
}) => {
  const classes = useStyles();
  const apiRef = useGridApiRef();
  const [hasFocus, setHasFocus] = useState<boolean>(false);

  const [tableState, setTableState] = useState<GridInitialState | null>(null);

  useEffect(() => {
    const localStorageStringValue = localStorage.getItem(localStoragePrefix);
    const tableStateLocalStorage = trycatch(
      () => localStorageStringValue && JSON.parse(localStorageStringValue),
      undefined
    );
    const initialTableState = tableStateLocalStorage || {
      columns: { columnVisibilityModel: {} },
    };
    setTableState(initialTableState);
  }, []);

  // Set up altered table data model
  const [rows, setRows] = useState(dataRows);

  useEffect(() => {
    setRows(dataRows);
  }, [dataRows]);

  const handleFocus = () => {
    setHasFocus(true);
  };
  const handleBlur = () => {
    setHasFocus(false);
  };
  const StyledGridOverlay = styled("div")(() => ({
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
    paddingTop: "1rem",
  }));

  function CustomNoRowsOverlay() {
    return (
      <StyledGridOverlay>
        <SusBox>
          <FontAwesomeIcon size={"3x"} icon={faSignsPost} />
        </SusBox>
        <SusBox sx={{ mt: 1 }}>There is no data available yet.</SusBox>
      </StyledGridOverlay>
    );
  }

  const persistTableState = () => {
    localStorage.setItem(localStoragePrefix, JSON.stringify(apiRef.current.exportState()));
  };

  columns = useMemo(
    () => columns.map((col) => ({ ...col, headerClassName: `col-${col.field}` })),
    [columns]
  );

  return (
    <TableContainer sx={{ boxShadow: "none", borderRadius: "0" }}>
      <SusBox
        display="flex"
        alignItems="center"
        pt={!title ? "0" : "1rem"}
        px={!title ? "0" : "1rem"}
        mx={title ? "0" : "1.25rem"}
        sx={({ breakpoints }: Theme) => ({
          marginBottom: !title ? "1.25rem" : "0",
          [breakpoints.down("sm")]: {
            flexDirection: "column",
            alignItems: "flex-start",
          },
        })}
      >
        {title && (
          <SusBox>
            <SusTypography variant="h6" fontWeight="medium" textTransform="uppercase">
              {loading ? <Skeleton width={190} /> : title}
            </SusTypography>
          </SusBox>
        )}

        <SusBox
          display="flex"
          justifyContent="flex-end"
          alignItems="center"
          sx={({ breakpoints }: Theme) => ({
            marginLeft: "auto",
            transition: "width 0.2s ease-in-out",
            width: !title ? "100%" : hasFocus ? "45%" : "250px",
            [breakpoints.down("sm")]: {
              marginLeft: "inherit",
              margin: "15px 0",
              width: !title ? "100%" : hasFocus ? "100%" : "100%",
            },
          })}
        >
          {loading ? (
            <Skeleton variant="text" height={49} width={"100%"} />
          ) : (
            <FormControl
              sx={{
                width: "100%",
                maxWidth: "100%",
                marginBottom: "0.5rem",
              }}
            >
              <OutlinedInput
                onFocus={handleFocus}
                onBlur={handleBlur}
                placeholder="Search..."
                className="search-input"
                startAdornment={
                  <InputAdornment position="start">
                    <FontAwesomeIcon icon={faSearch} size="xs" />
                  </InputAdornment>
                }
                onChange={(event: any) => {
                  const searchText = event.target.value;
                  const searchRegex = new RegExp(escapeRegExp(searchText), "i");
                  const filteredRows = dataRows.filter((row) =>
                    Object.keys(row).some((field) => searchRegex.test(row[field]?.toString()))
                  );
                  setRows(filteredRows);
                }}
              />
            </FormControl>
          )}
        </SusBox>
      </SusBox>
      {title && <Divider />}
      <SusBox display="flex" justifyContent="space-between" alignItems="center">
        {loading || !tableState ? (
          <TableContainer className={classes.dataGrid} sx={{ boxShadow: "none" }}>
            <TableSkeleton />
          </TableContainer>
        ) : (
          <DataGridPro
            apiRef={apiRef}
            rows={rows}
            columns={columns}
            autoHeight
            components={{
              NoRowsOverlay: CustomNoRowsOverlay,
            }}
            sx={{
              border: "none",
            }}
            onSortModelChange={persistTableState}
            onColumnOrderChange={persistTableState}
            onColumnWidthChange={persistTableState}
            onColumnVisibilityModelChange={persistTableState}
            initialState={tableState}
            disableRowSelectionOnClick
            hideFooter
            autoPageSize
            className={classes.dataGrid}
          />
        )}
      </SusBox>
      {!loading && (
        <SusBox
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          sx={{ padding: "2rem 2rem 0 2rem" }}
        >
          {footer ? (
            <SusTypography variant="body2" fontWeight="regular" color="text">
              {footer}
            </SusTypography>
          ) : null}
        </SusBox>
      )}
    </TableContainer>
  );
};

export default DataGrid_;
