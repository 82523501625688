import colors from "@theme/base/colors";

import pxToRem from "@theme/functions/pxToRem";
import boxShadow from "@theme/functions/boxShadow";

// types
type Types = any;

const stepIcon: Types = {
  styleOverrides: {
    root: {
      background: "#9fc9ff",
      fill: "#9fc9ff",
      stroke: "#9fc9ff",
      strokeWidth: pxToRem(10),
      width: pxToRem(13),
      height: pxToRem(13),
      borderRadius: "50%",
      zIndex: 99,
      transition: "all 200ms linear",

      "&.Mui-active": {
        background: colors.white.main,
        fill: colors.white.main,
        stroke: colors.white.main,
        borderColor: colors.white.main,
        boxShadow: boxShadow([0, 0], [0, 2], colors.white.main, 1),
      },

      "&.Mui-completed": {
        background: colors.white.main,
        fill: colors.white.main,
        stroke: colors.white.main,
        borderColor: colors.white.main,
        boxShadow: boxShadow([0, 0], [0, 2], colors.white.main, 1),
      },
    },
  },
};

export default stepIcon;
