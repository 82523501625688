import React from "react";

import { Layout, Layouts, Responsive, ResponsiveProps, WidthProvider } from "react-grid-layout";

import useStyles from "./styles";

interface ISusGridLayoutProps {
  edit: boolean;
  layouts: Layouts;
  onLayoutChange?: (currentLayout: Layout[], allLayouts: Layouts) => void;
  onBreakpointChange?: (newBreakpoint: string, newCols: number) => void;
}

const ResponsiveGridLayout = WidthProvider<ResponsiveProps>(Responsive);

const SusGridLayout: React.FC<React.PropsWithChildren<ISusGridLayoutProps>> = ({
  children,
  layouts,
  onLayoutChange,
  onBreakpointChange,
  edit,
}) => {
  const classes = useStyles();
  return (
    <>
      <ResponsiveGridLayout
        className={classes.layout}
        layouts={layouts}
        onLayoutChange={onLayoutChange}
        onBreakpointChange={onBreakpointChange}
        cols={{ lg: 12, md: 12, sm: 6, xs: 4, xxs: 2 }}
        breakpoints={{ lg: 1200, md: 992, sm: 768, xs: 576, xxs: 0 }}
        isDraggable={edit}
        isResizable={false}
        margin={[16, 16]}
      >
        {children}
      </ResponsiveGridLayout>
    </>
  );
};

export default SusGridLayout;
