import React, { useEffect, useState } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Icon from "@mui/material/Icon";
import ListItemIcon from "@mui/material/ListItemIcon";
import { Grid, Skeleton } from "@mui/material";
import { faSigma } from "@fortawesome/pro-regular-svg-icons";
import { IconDefinition } from "@fortawesome/fontawesome-svg-core";

import { decamelize } from "humps";
import { FilterSpec, ObjectClass, ObjectField } from "@src/types";
import SusWidget from "@src/templates/SusWidget";
import SusBox from "@components/SusBox";
import useApiData from "@hooks/useApiData";
import { serializeFieldName } from "@src/utils/typing";
import { apiPaths } from "@src/paths";
import { generateApiPath } from "@src/utils/helpers";
import SusTypography from "@components/SusTypography";
import theme from "@theme/index";

export interface ISimpleAggregationTileProps<T extends ObjectClass> {
  title: string;
  objectClass: T;
  icon?: IconDefinition;
  filter?: FilterSpec;
  aggregationField: ObjectField<T>;
  formatter?: (val: number) => any;
  fullHeight?: boolean;
  onRemove?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  globalLoading?: boolean;
}

export const SimpleAggregationTile = <T extends ObjectClass>({
  title,
  objectClass,
  icon,
  filter,
  aggregationField,
  formatter,
  fullHeight,
  onRemove,
  globalLoading,
}: ISimpleAggregationTileProps<T>) => {
  const [apiUrl, setApiUrl] = useState<string>("");
  useEffect(() => {
    if (!objectClass || !filter?.field || !filter?.value || !aggregationField) return;
    setApiUrl(
      generateApiPath(apiPaths.aggregate, {
        objectClass: decamelize(objectClass),
        filterField: serializeFieldName(objectClass, filter?.field),
        filterValue: filter?.value,
        sumField: serializeFieldName(objectClass, aggregationField),
      })
    );
  }, [objectClass, filter, aggregationField]);
  const { data: rawData, loading } = useApiData(apiUrl, "get", {
    trigger: "ONCE",
    companyQuery: { enabled: true, required: true },
  });

  const value = rawData?.value || 0;

  return (
    <SusWidget fullHeight={fullHeight} onRemove={onRemove}>
      <SusBox
        display="flex"
        flexDirection="row"
        sx={{
          height: "100%",
        }}
      >
        <Grid
          container
          wrap="nowrap"
          justifyContent="flex-end"
          alignItems="center"
          sx={{ justifyContent: "flex-end", marginTop: "auto" }}
        >
          <Grid item zeroMinWidth>
            <SusBox sx={{ position: "absolute", left: "18px", top: "16px" }}>
              <ListItemIcon>
                {loading || globalLoading ? (
                  <Skeleton
                    variant="rectangular"
                    width={"3rem"}
                    height={"3rem"}
                    sx={{ borderRadius: "0.5rem" }}
                  />
                ) : (
                  <Icon
                    sx={{
                      display: "grid !important",
                      minWidth: "3rem",
                      backgroundColor: theme.palette.primary.main,
                      color: "#ffffff",
                      minHeight: "3rem",
                      borderRadius: "0.5rem",
                      placeItems: "center",
                      boxShadow:
                        "0rem 0.25rem 0.375rem -0.0625rem rgb(20 20 20 / 12%), 0rem 0.125rem 0.25rem -0.0625rem rgb(20 20 20 / 7%)",
                    }}
                  >
                    <FontAwesomeIcon icon={icon || faSigma} size="xs" />
                  </Icon>
                )}
              </ListItemIcon>
            </SusBox>
            <SusBox
              sx={{
                textAlign: "right",
                height: "100%",
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "flex-end",
                flexDirection: "column",
              }}
            >
              <SusTypography
                variant="body2"
                fontWeight="medium"
                color="text"
                sx={{
                  textAlign: "right",
                  fontSize: "0.8rem",
                }}
              >
                {loading || globalLoading ? <Skeleton width={125} /> : title}
              </SusTypography>
              <SusTypography
                sx={{
                  fontSize: "clamp(1.5rem, 10vw, 2.6rem)",
                  lineHeight: 1,
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                }}
              >
                {loading || globalLoading ? (
                  <Skeleton width={236} />
                ) : formatter ? (
                  formatter(value)
                ) : (
                  value
                )}
              </SusTypography>
            </SusBox>
          </Grid>
        </Grid>
      </SusBox>
    </SusWidget>
  );
};
