import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSignsPost, faStar } from "@fortawesome/pro-solid-svg-icons";
import { teamSelectCheckbox, teamSelectDialog } from "./styles";
import {
  Alert,
  Autocomplete,
  Button,
  Checkbox,
  Collapse,
  Dialog,
  DialogActions,
  ListItem,
  Paper,
  Tab,
  Tabs,
  TextField,
  useMediaQuery,
} from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import { styled, useTheme } from "@mui/material/styles";

import { debounce } from "lodash";

import { faCircleDot } from "@fortawesome/pro-regular-svg-icons";
import { CompanyMembership } from "@src/types";
import useApiData from "@src/hooks/useApiData";
import { apiPaths } from "@src/paths";
import { MAX_FAVORITE_COMPANIES } from "@src/constants";
import SusBox from "@components/SusBox";
import { composeOneLineAddress } from "@src/utils/helpers";
import SusAvatar from "@components/SusAvatar";
import SusTypography from "@components/SusTypography";

const TeamSwitchDialogBase = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
    border: "none",
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(2),
    background: "#f3f4f6",
  },
}));

interface ITeamSwitchDialogProps {
  open: boolean;
  onClose: () => void;
  onSave: (newFavoriteCompanies: CompanyMembership[]) => void;
  favoriteCompanies: CompanyMembership[];
}

export const TeamSwitchDialog: React.FC<ITeamSwitchDialogProps> = ({
  open,
  onClose,
  onSave,
  favoriteCompanies,
}) => {
  const { breakpoints } = useTheme();
  const fullScreen = useMediaQuery(breakpoints.down("sm"));

  const [tmpFavoriteCompanies, setTmpFavoriteCompanies] = useState<CompanyMembership[]>([]);
  const [searchTerm, setSearchTerm] = React.useState<string | undefined>();
  const [error, setError] = useState<string | null>(null);
  const [info, setInfo] = useState<string | null>(null);
  const [tabValue, setTabValue] = useState<string>("all");

  // Set the data fetching to manual in order to be able to debounce it
  const { data: companies, fetch: fetchCompanies } = useApiData(
    `${apiPaths.companyMemberships}?limit=999&q=${searchTerm || ""}`,
    "get",
    { trigger: "MANUAL" }
  );

  useEffect(() => {
    setTmpFavoriteCompanies(favoriteCompanies);
  }, [favoriteCompanies]);

  useEffect(() => {
    if (open && typeof searchTerm === "undefined") {
      setSearchTerm("");
    }
  }, [open]);

  const debouncedFetchCompanies = useMemo(
    () => debounce((fetchCompanies) => fetchCompanies(), 500),
    []
  );
  useEffect(() => {
    if (typeof searchTerm !== "undefined") {
      debouncedFetchCompanies(fetchCompanies);
    }
  }, [searchTerm]);

  const handleFavoriteCompaniesChange = (event: any, value: (string | CompanyMembership)[]) => {
    if (value.length === 0 || (value.length > 0 && typeof value[0] !== "string")) {
      setTmpFavoriteCompanies(value as CompanyMembership[]);
      setInfo(`You can select ${MAX_FAVORITE_COMPANIES} favorites`);
    }
    if (value.length === 0) {
      setError("Please select at least one favorite");
    } else if (value.length > MAX_FAVORITE_COMPANIES) {
      setError(`Please select at most ${MAX_FAVORITE_COMPANIES} favorites`);
    } else {
      setError(null);
      setInfo(`You can select ${MAX_FAVORITE_COMPANIES} favorites`);
    }
  };

  const handleClose = () => {
    setSearchTerm(undefined);
    onClose();
  };

  const autocompleteOptions = companies?.results?.length
    ? companies.results.filter(
        (company: CompanyMembership) =>
          tabValue === "all" ||
          (tabValue === "active" && company.companyHasActiveMembers) ||
          (tabValue === "inactive" && !company.companyHasActiveMembers)
      )
    : [];
  const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
    setTabValue(newValue);
  };

  return (
    <TeamSwitchDialogBase
      onClose={handleClose}
      aria-labelledby="TeamSwitch-dialog"
      open={open}
      maxWidth="md"
      fullScreen={fullScreen}
      fullWidth={true}
      sx={teamSelectDialog}
    >
      {error ? (
        <Alert
          severity="error"
          sx={{
            alignItems: "center",
          }}
        >
          <span className="dz-error-message">{error}</span>
        </Alert>
      ) : (
        <Alert
          severity="info"
          sx={{
            alignItems: "center",
          }}
        >
          <span className="dz-info-message">
            {!info ? `You can select ${MAX_FAVORITE_COMPANIES} favorites` : info}
          </span>
        </Alert>
      )}

      <DialogActions>
        <Autocomplete
          multiple
          fullWidth
          id="checkboxes-tags"
          options={autocompleteOptions}
          disableCloseOnSelect
          disableClearable
          limitTags={3}
          defaultValue={[...favoriteCompanies]}
          getOptionLabel={(option) => (typeof option === "string" ? option : option.companyName)}
          isOptionEqualToValue={(option, value) => option.companyId === value.companyId}
          onChange={handleFavoriteCompaniesChange}
          filterOptions={(x) => x}
          freeSolo
          PaperComponent={({ children }) => (
            <Paper onMouseDown={(event) => event.preventDefault()}>
              <Tabs
                value={tabValue}
                onChange={(event, newValue) => handleTabChange(event, newValue)}
                variant="scrollable"
                sx={{
                  borderRadius: "0",
                  "& .MuiButtonBase-root": {
                    background: "transparent",
                    margin: "0.4rem",
                    "&.Mui-selected": {
                      background: "#ffffff",
                      boxShadow: "0rem 0.0625rem 0.3125rem 0.0625rem rgba(221, 221, 221, 1)",
                    },
                  },
                  "& .MuiTabs-indicator": {
                    background: "transparent",
                    boxShadow: "none",
                  },
                }}
              >
                <Tab label="All" value="all" />
                <Tab
                  label={
                    <SusBox
                      sx={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <SusBox sx={{ mr: 1 }}>Involved</SusBox>
                      <SusBox
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          svg: {
                            color: "#18B9DE",
                            marginTop: "0.2rem",
                          },
                        }}
                      >
                        <FontAwesomeIcon icon={faCircleDot} size={"xs"} />
                      </SusBox>
                    </SusBox>
                  }
                  value="active"
                />
                <Tab label="Uninvolved" value="inactive" />
              </Tabs>
              <Collapse in={autocompleteOptions.length === 0}>
                <SusBox
                  sx={{
                    p: 4,
                    display: "flex",
                    flexDirection: "column",
                  }}
                  justifyContent="center"
                  alignItems="center"
                >
                  <SusBox>
                    <FontAwesomeIcon size={"2x"} icon={faSignsPost} />
                  </SusBox>
                  <SusBox sx={{ mt: 1 }}>There is no team matching your search</SusBox>
                </SusBox>
              </Collapse>
              {children}
            </Paper>
          )}
          renderOption={(props, option, { selected }) => {
            const companyAddress = composeOneLineAddress(
              option.companyStreet,
              option.companyZipCode,
              option.companyCity,
              option.companyCountry
            );
            return (
              <ListItem
                sx={{
                  justifyContent: "space-between !important",
                }}
                key={option.companyId}
                {...props}
              >
                <SusBox
                  className={option.companyHasActiveMembers ? "active" : "inactive"}
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    ".MuiAvatar-root": {
                      fontSize: "8px",
                      marginRight: "0.5rem",
                      borderRadius: "50% !important",
                    },
                  }}
                >
                  <SusAvatar
                    status={option.companyHasActiveMembers ? "active" : "inactive"}
                    name={
                      option.companyName.length > 12
                        ? `${option.companyName.substring(0, 14)}`
                        : option.companyName
                    }
                    size="sm"
                  />
                  <SusTypography variant="body2"> {option.companyName}</SusTypography>
                  {companyAddress && (
                    <SusTypography
                      variant="body2"
                      sx={{
                        padding: "8px 0 3px 8px",
                        fontSize: "11px",
                        alignItems: "baseline",
                      }}
                    >
                      ({companyAddress})
                    </SusTypography>
                  )}
                </SusBox>
                <Checkbox
                  icon={<FontAwesomeIcon icon={faStar} />}
                  checkedIcon={<FontAwesomeIcon icon={faStar} />}
                  style={{
                    marginRight: 8,
                    lineHeight: 1.5,
                  }}
                  checked={selected}
                  sx={teamSelectCheckbox}
                />
              </ListItem>
            );
          }}
          sx={{
            marginRight: "1rem",
            "& .MuiAvatar-root": {
              fontSize: "9px !important",
            },
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              onChange={(e) => setSearchTerm(e.target.value)}
              label=""
              placeholder="Start typing to search..."
            />
          )}
        />
        <Button
          variant="contained"
          color="primary"
          onClick={() => onSave(tmpFavoriteCompanies)}
          disabled={!!error}
        >
          Save
        </Button>
      </DialogActions>
    </TeamSwitchDialogBase>
  );
};
