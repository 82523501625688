import pxToRem from "@theme/functions/pxToRem";
import colors from "@theme/base/colors";

// types
type Types = any;

const tabs: Types = {
  styleOverrides: {
    root: {
      position: "relative",
      minHeight: "unset",
      padding: pxToRem(4),
      borderBottom: "none",
    },

    flexContainer: {
      height: "100%",
      position: "relative",
      zIndex: 10,
    },

    fixed: {
      overflow: "unset !important",
      overflowX: "unset !important",
    },

    vertical: {
      "& .MuiTabs-indicator": {
        width: "100%",
      },
    },

    indicator: {
      transition: "all 500ms ease",
      justifyContent: "center",
      display: "flex",
      bottom: "-4px",
      height: "3px",
      backgroundColor: colors.primary.main,
      "& .MuiTabs-indicatorSpan": {
        position: "relative",
        maxWidth: "calc(50% - 30px)",
        width: "100%",
        backgroundColor: colors.primary.main,
        border: "1px solid transparent",
        height: "5px",
      },
      "&::before": {
        content: "''",
        position: "absolute",
        top: "-8px", // Position geändert auf top
        left: "50%",
        transform: "translateX(-50%)",
        width: 0,
        height: 0,
        borderLeft: "8px solid transparent",
        borderRight: "8px solid transparent",
        borderBottom: "8px solid " + colors.primary.main,
      },
    },
  },
};

export default tabs;
