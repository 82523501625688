import "chart.js/auto";
import React from "react";
import { FilterSpec } from "../../../../types";
import SimpleAggregationChart from "../SimpleAggregationChart";

interface IVolumeByProductProps {
  filter?: FilterSpec;
  amountField: "amount" | "amountLeft";
}

const ChartVolumeByProduct = ({ filter, amountField }: IVolumeByProductProps) => {
  return (
    <SimpleAggregationChart
      objectClass="ContractPosition"
      title={(amountField === "amount" ? "Total volume" : "Volume left") + " by product (MT)"}
      groupByField="productSimple"
      filter={filter}
      aggregationField={amountField}
    />
  );
};

export default ChartVolumeByProduct;
