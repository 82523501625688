import colors from "@theme/base/colors";
import typography from "@theme/base/typography";

const { text, primary } = colors;
const { size } = typography;

// types
type Types = any;

const inputLabel: Types = {
  styleOverrides: {
    root: {
      fontSize: size.sm,
      color: text.main,
      lineHeight: "1.4rem",

      "&.Mui-focused": {
        color: primary.main,
      },

      "&.MuiInputLabel-shrink": {
        lineHeight: 1.14,
        fontSize: size.md,

        "~ .MuiInputBase-root .MuiOutlinedInput-notchedOutline legend": {
          fontSize: "0.85em",
        },
      },
    },

    sizeSmall: {
      fontSize: size.xs,
      lineHeight: 1.625,

      "&.MuiInputLabel-shrink": {
        lineHeight: 1.6,
        fontSize: size.sm,

        "~ .MuiInputBase-root .MuiOutlinedInput-notchedOutline legend": {
          fontSize: "0.72em",
        },
      },
    },
  },
};

export default inputLabel;
