// @mui material components
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";

// PEK Dashboard components
import React from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleInfo, faXmark } from "@fortawesome/pro-regular-svg-icons";
import { IconButton, Menu, Skeleton, SxProps, Tooltip, Zoom } from "@mui/material";
import { faEllipsisVertical } from "@fortawesome/pro-solid-svg-icons";
import SusButton from "@components/SusButton";
import SusTypography from "@components/SusTypography";
import SusBox from "@components/SusBox";

interface IWidgetProps {
  title?: string;
  infoTooltip?: string;
  actions?: React.ReactElement;
  menuItems?: (closeMenu: () => void) => React.ReactElement;
  onRemove?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  fullHeight?: boolean;
  onMenuOpen?: () => void;
  onMenuClose?: () => void;
  loading?: boolean;
  className?: string;
  sx?: SxProps | undefined;
}

const SusWidget: React.FC<React.PropsWithChildren<IWidgetProps>> = ({
  title,
  loading,
  actions,
  menuItems,
  onRemove,
  fullHeight,
  onMenuOpen,
  onMenuClose,
  infoTooltip,
  className,
  children,
  sx,
}) => {
  const [commentMenuAnchorEl, setCommentMenuAnchorEl] = React.useState<null | HTMLElement>(null);
  const commentMenuOpen = Boolean(commentMenuAnchorEl);

  const handleOpenMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setCommentMenuAnchorEl(event.currentTarget);
    onMenuOpen?.();
  };
  const handleCloseMenu = () => {
    setCommentMenuAnchorEl(null);
    onMenuClose?.();
  };

  return (
    <Card className={className} sx={{ ...(fullHeight ? { height: "100%" } : {}), ...(sx || {}) }}>
      {title ? (
        <SusBox display="flex" justifyContent="space-between" alignItems="center" pt={2} px={2}>
          <SusTypography
            variant="h6"
            fontWeight="medium"
            textTransform="uppercase"
            sx={{ width: "100%" }}
          >
            {loading ? <Skeleton width={190} /> : title}
          </SusTypography>
          {loading ? (
            <Skeleton variant="text" width={25} />
          ) : (
            <SusBox display="flex" justifyContent="flex-end" alignItems="center">
              {infoTooltip && (
                <Tooltip title={infoTooltip} placement="bottom" TransitionComponent={Zoom}>
                  <SusButton
                    variant="text"
                    className={"info-tooltip"}
                    color="text"
                    sx={{ cursor: "auto", minWidth: "auto" }}
                  >
                    <FontAwesomeIcon icon={faCircleInfo} />
                  </SusButton>
                </Tooltip>
              )}
              {menuItems && (
                <>
                  <SusButton
                    onClick={handleOpenMenu}
                    color="text"
                    variant="text"
                    sx={{ minWidth: "auto" }}
                  >
                    <FontAwesomeIcon icon={faEllipsisVertical} size="xs" />
                  </SusButton>
                  <Menu
                    id="basic-menu"
                    open={commentMenuOpen}
                    onClose={handleCloseMenu}
                    anchorEl={commentMenuAnchorEl}
                    MenuListProps={{
                      "aria-labelledby": "basic-button",
                    }}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "center",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "center",
                    }}
                  >
                    {menuItems(handleCloseMenu)}
                  </Menu>
                </>
              )}
              {onRemove && (
                <Tooltip title="Remove Widget" placement="bottom" TransitionComponent={Zoom}>
                  <IconButton
                    className="info-tooltip"
                    onClick={onRemove}
                    sx={{
                      "&:hover": {
                        "& svg": {
                          color: "#EF6461",
                        },
                      },
                    }}
                  >
                    <FontAwesomeIcon icon={faXmark} size="xs" />
                  </IconButton>
                </Tooltip>
              )}
            </SusBox>
          )}
        </SusBox>
      ) : (
        <SusBox display="flex" justifyContent="end" alignItems="center" pt={2} px={2}>
          {onRemove && (
            <Tooltip title="Remove Widget" placement="bottom" TransitionComponent={Zoom}>
              <IconButton
                className="info-tooltip"
                onClick={onRemove}
                sx={{
                  "&:hover": {
                    "& svg": {
                      color: "#EF6461",
                    },
                  },
                }}
              >
                <FontAwesomeIcon icon={faXmark} size="xs" />
              </IconButton>
            </Tooltip>
          )}
        </SusBox>
      )}
      {(title || infoTooltip || menuItems) && <Divider />}
      <SusBox p={2} sx={fullHeight ? { height: "100%" } : {}}>
        {children}
        {actions && (
          <>
            <Divider />
            {actions}
          </>
        )}
      </SusBox>
    </Card>
  );
};

export default SusWidget;
