import React from "react";
import { Fab, Grow, Tooltip, Zoom } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faPen, faPlus, faXmark } from "@fortawesome/pro-solid-svg-icons";
import { useTheme } from "@mui/material/styles";
import { useSoftUIController } from "@src/context";
import SusBox from "@components/SusBox";
import pxToRem from "@theme/functions/pxToRem";
import borders from "@theme/base/borders";

interface IControlRoomFabProps {
  edit: boolean;
  startEdit: () => void;
  cancelEdit: () => void;
  saveLayout: () => void;
  onAddWidget?: () => void;
}

const ControlRoomFab: React.FC<IControlRoomFabProps> = ({
  edit,
  startEdit,
  cancelEdit,
  saveLayout,
  onAddWidget,
}) => {
  const theme = useTheme();

  const [controller] = useSoftUIController();
  const { miniSidenav } = controller;

  const { palette, transitions } = theme as any;

  const { white, success, error } = palette as any;

  const { borderRadius } = borders;

  return (
    <SusBox
      className="ControlRoomFab"
      sx={({}) => ({
        position: "fixed",
        bottom: 18,
        left: miniSidenav ? pxToRem(120) : pxToRem(274),
        right: 0,
        width: "210px",
        margin: "0 auto",
        textAlign: "center",
        transition: transitions.create(["left", "right"], {
          easing: transitions.easing.easeInOut,
          duration: transitions.duration.standard,
        }),
        "& > :not(style)": { m: 1.2 },
      })}
    >
      <Grow in={edit} style={{ transformOrigin: "24px 150px" }} timeout={400}>
        <Tooltip title="Discard changes" placement="top" TransitionComponent={Zoom}>
          <Fab
            color="default"
            size="medium"
            aria-controls="Discard changes"
            aria-haspopup="true"
            onClick={cancelEdit}
            sx={{ background: white.main, color: error.main }}
          >
            <FontAwesomeIcon icon={faXmark} />
          </Fab>
        </Tooltip>
      </Grow>

      {edit ? (
        <Tooltip title="Add Widget" placement="top" TransitionComponent={Zoom}>
          <Fab
            color="default"
            size="medium"
            aria-controls="Add Widget"
            aria-haspopup="true"
            onClick={onAddWidget}
            sx={{
              background: white.main,
              marginBottom: "-5px !important",
              borderRadius: borderRadius.md,
            }}
          >
            <FontAwesomeIcon icon={faPlus} />
          </Fab>
        </Tooltip>
      ) : (
        <Tooltip title="Modify Control Room" placement="top" TransitionComponent={Zoom}>
          <Fab
            color="default"
            size="medium"
            aria-controls="Modify Control Room"
            aria-haspopup="true"
            onClick={startEdit}
            className={"Control-edit-Button"}
            sx={{
              background: white.main,
              marginBottom: "-5px !important",
              borderRadius: borderRadius.md,
            }}
          >
            <FontAwesomeIcon icon={faPen} />
          </Fab>
        </Tooltip>
      )}
      <Grow in={edit} style={{ transformOrigin: "24px 150px" }} timeout={400}>
        <Tooltip title="Save Configuration" placement="top" TransitionComponent={Zoom}>
          <Fab
            color="default"
            size="medium"
            aria-controls="Save Configuration"
            aria-haspopup="true"
            onClick={saveLayout}
            sx={{ background: white.main, color: success.main }}
          >
            <FontAwesomeIcon icon={faCheck} color={"success.main"} />
          </Fab>
        </Tooltip>
      </Grow>
    </SusBox>
  );
};
export default ControlRoomFab;
