import {
  faAbacus,
  faCalendarStar,
  faChartPieSimple,
  faCheck,
  faShip,
} from "@fortawesome/pro-regular-svg-icons";

import { faNotes } from "@fortawesome/pro-light-svg-icons";
import {
  WidgetConfigurationGetter,
  WidgetLayoutsGetter,
  WidgetSpecs,
} from "@src/features/control-room";
import {
  ISimpleAggregationTileProps,
  SimpleAggregationTile,
} from "@components/widgets/SimpleAggregationTile/SimpleAggregationTile";
import { Status, statusDetails } from "@src/types";
import { ISimpleAggregationChartProps } from "@components/widgets/Charts/SimpleAggregationChart/SimpleAggregationChart";
import SimpleAggregationChart from "@components/widgets/Charts/SimpleAggregationChart";
import { formatData } from "@src/utils/typing";
import VesselFinder from "@components/widgets/VesselFinder/VesselFinder";
import Notes from "@components/widgets/Notes";

const aggWidget: (locale: string) => WidgetSpecs<ISimpleAggregationTileProps<"CallOffPosition">> = (
  locale
) => ({
  widgetName: "Call-Off aggregation",
  icon: faAbacus,
  component: SimpleAggregationTile,
  w: 3,
  h: 1,
  fixedProps: (modifyableProps) => ({
    title: `${statusDetails[modifyableProps.filter as Status]?.label} total volume`,
    objectClass: "CallOffPosition",
    initial: 0,
    icon:
      modifyableProps.filter === "UPCOMING"
        ? faCalendarStar
        : modifyableProps.filter === "IN_TRANSIT"
          ? faShip
          : modifyableProps.filter === "DELIVERED"
            ? faCheck
            : modifyableProps.filter === "DROPSHIPPING"
              ? faShip
              : undefined,
    aggregationField: "amount",
    formatter: (val: any) => formatData(val, "decimal", locale),
  }),
  modifyableProps: [
    {
      name: "filter",
      label: "Status Filter",
      valueMap: (value: string) => ({
        field: "statusByDate",
        value,
      }),
      options: [
        { label: "Upcoming", value: "UPCOMING" },
        { label: "In Transit", value: "IN_TRANSIT" },
        { label: "Dropshipping", value: "DROPSHIPPING" },
        { label: "Delivered", value: "DELIVERED" },
      ],
    },
  ],
});

const callOffChartWidget: (
  locale: string
) => WidgetSpecs<ISimpleAggregationChartProps<"CallOffPosition">> = (locale) => ({
  widgetName: "Call-Off chart",
  icon: faChartPieSimple,
  component: SimpleAggregationChart,
  w: 3,
  h: 2,
  fixedProps: (modifyableProps) => ({
    title: `${statusDetails[modifyableProps.filter as Status]?.label} total volume by ${
      modifyableProps.groupByField === "productNameEn" ? "product" : "vessel"
    }`,
    objectClass: "CallOffPosition",
    aggregationField: "amount",
    maxCount: 6,
    formatter: (val: any) => formatData(val, "decimal", locale),
    nullLabel: modifyableProps.groupByField === "productNameEn" ? "No product" : "No vessel",
  }),
  modifyableProps: [
    {
      name: "filter",
      label: "Status Filter",
      valueMap: (value: string) => ({
        field: "statusByDate",
        value,
      }),
      options: [
        { label: "Upcoming", value: "UPCOMING" },
        { label: "In Transit", value: "IN_TRANSIT" },
        { label: "Dropshipping", value: "DROPSHIPPING" },
        { label: "Delivered", value: "DELIVERED" },
      ],
    },
    {
      name: "groupByField",
      label: "Group by",
      options: [
        { label: "Product", value: "productNameEn" },
        { label: "Vessel", value: "shipName" },
      ],
    },
  ],
});

const contractChartWidget: (
  locale: string
) => WidgetSpecs<ISimpleAggregationChartProps<"ContractPosition">> = (locale) => ({
  widgetName: "Contract volume chart",
  icon: faChartPieSimple,
  component: SimpleAggregationChart,
  w: 3,
  h: 2,
  fixedProps: (modifyableProps) => ({
    title: `${
      modifyableProps.aggregationField === "amountLeftCalculated"
        ? "Amount left"
        : "Contract amount"
    } by product`,
    objectClass: "ContractPosition",
    formatter: (val: any) => formatData(val, "decimal", locale),
    filter: { field: "status", value: "OPEN" },
    maxCount: 6,
    groupByField: "productNameEn",
    nullLabel: "No product",
  }),
  modifyableProps: [
    {
      name: "aggregationField",
      label: "Value",
      options: [
        { label: "Contract amount", value: "amount" },
        { label: "Amount left", value: "amountLeftCalculated" },
      ],
    },
  ],
});

/**
 * Generate a widget registry for the control room using the current locale.
 * @param locale
 */
const getWidgetRegistry = (locale: string) => {
  return [
    aggWidget(locale),
    /*{
      widgetName: "All Vessels Tracker",
      icon: faShip,
      component: VesselFinder,
      w: 4,
      h: 2,
      fixedProps: () => ({
        showTrack: false,
        title: "All Vessels",
      }),
    },*/
    {
      widgetName: "Single Vessel Tracker",
      icon: faShip,
      component: VesselFinder,
      w: 4,
      h: 2,
      fixedProps: () => ({
        showTrack: true,
        title: "Single Vessel",
      }),
      modifyableProps: [
        {
          name: "imo",
          label: "Vessel IMO",
        },
      ],
    },
    /*{
      widgetName: "Vessels in Transit Tracker",
      icon: faShip,
      component: VesselFinder,
      w: 4,
      h: 2,
      fixedProps: () => ({
        showTrack: true,
        showAllInTransit: true,
        title: "Vessels in Transit",
      }),
    },*/
    callOffChartWidget(locale),
    contractChartWidget(locale),
    {
      widgetName: "Notes",
      title: "Notes",
      icon: faNotes,
      component: Notes,
      fixedSize: true,
      w: 3,
      h: 2,
    },
    /*{
      widgetName: "Positions activities",
      image: faTimelineArrow,
      component: VesselFinder,
      w: 4,
      h: 2,
      fixedProps: () => ({
        imo: 9648714,
        showTrack: true,
      }),
    },
    */
    /*{
      widgetName: "Currency Exchange",
      title: "Currency Exchange",
      icon: faArrowRightArrowLeft,
      component: CurrencyExchange,
      w: 4,
      h: 2,
    },*/
    /*{
      widgetName: "PEK Dashboard Info",
      image: faCircleInfo,
      component: VesselFinder,
      w: 4,
      h: 2,
      fixedProps: () => ({
        imo: 9648714,
        showTrack: true,
      }),
    },
    */
  ];
};

const getDefaultWidgets: WidgetConfigurationGetter = (locale) => {
  return [
    {
      widget: aggWidget(locale),
      modifyablePropsValues: {
        filter: "DROPSHIPPING",
      },
      x: 2,
      y: 7,
    },
    {
      widget: aggWidget(locale),
      modifyablePropsValues: {
        filter: "IN_TRANSIT",
      },
      x: 2,
      y: 6,
    },
    {
      widget: aggWidget(locale),
      modifyablePropsValues: {
        filter: "UPCOMING",
      },
      x: 2,
      y: 8,
    },
    {
      widget: callOffChartWidget(locale),
      modifyablePropsValues: {
        filter: "DROPSHIPPING",
        groupByField: "productNameEn",
      },
      x: 1,
      y: 4,
    },
    {
      widget: callOffChartWidget(locale),
      modifyablePropsValues: {
        filter: "IN_TRANSIT",
        groupByField: "productNameEn",
      },
      x: 3,
      y: 2,
    },
    {
      widget: callOffChartWidget(locale),
      modifyablePropsValues: {
        filter: "UPCOMING",
        groupByField: "productNameEn",
      },
      x: 2,
      y: 8,
    },
    {
      widget: contractChartWidget(locale),
      modifyablePropsValues: {
        aggregationField: "amountLeftCalculated",
      },
      x: 2,
      y: 8,
    },
  ];
};

const getDefaultLayouts: WidgetLayoutsGetter = () => {
  return {
    lg: [
      {
        h: 1,
        i: "0",
        w: 3,
        x: 6,
        y: 2,
        min_h: 1,
        min_w: 2,
        moved: false,
        static: false,
      },
      {
        h: 1,
        i: "1",
        w: 3,
        x: 0,
        y: 2,
        min_h: 1,
        min_w: 2,
        moved: false,
        static: false,
      },
      {
        h: 1,
        i: "2",
        w: 3,
        x: 3,
        y: 2,
        min_h: 1,
        min_w: 2,
        moved: false,
        static: false,
      },
      {
        h: 2,
        i: "3",
        w: 3,
        x: 6,
        y: 0,
        min_h: 2,
        min_w: 2,
        moved: false,
        static: false,
      },
      {
        h: 2,
        i: "4",
        w: 3,
        x: 0,
        y: 0,
        min_h: 2,
        min_w: 2,
        moved: false,
        static: false,
      },
      {
        h: 2,
        i: "5",
        w: 3,
        x: 3,
        y: 0,
        min_h: 2,
        min_w: 2,
        moved: false,
        static: false,
      },
      {
        h: 2,
        i: "6",
        w: 3,
        x: 0,
        y: 3,
        moved: false,
        static: false,
      },
    ],
    md: [
      {
        h: 1,
        i: "0",
        w: 3,
        x: 6,
        y: 2,
        min_h: 1,
        min_w: 2,
        moved: false,
        static: false,
      },
      {
        h: 1,
        i: "1",
        w: 3,
        x: 0,
        y: 2,
        min_h: 1,
        min_w: 2,
        moved: false,
        static: false,
      },
      {
        h: 1,
        i: "2",
        w: 3,
        x: 3,
        y: 2,
        min_h: 1,
        min_w: 2,
        moved: false,
        static: false,
      },
      {
        h: 2,
        i: "3",
        w: 3,
        x: 6,
        y: 0,
        min_h: 2,
        min_w: 2,
        moved: false,
        static: false,
      },
      {
        h: 2,
        i: "4",
        w: 3,
        x: 0,
        y: 0,
        min_h: 2,
        min_w: 2,
        moved: false,
        static: false,
      },
      {
        h: 2,
        i: "5",
        w: 3,
        x: 3,
        y: 0,
        min_h: 2,
        min_w: 2,
        moved: false,
        static: false,
      },
      {
        h: 2,
        i: "6",
        w: 3,
        x: 0,
        y: 3,
        moved: false,
        static: false,
      },
    ],
    sm: [
      {
        h: 1,
        i: "0",
        w: 2,
        x: 4,
        y: 2,
        min_h: 1,
        min_w: 2,
        moved: false,
        static: false,
      },
      {
        h: 1,
        i: "1",
        w: 2,
        x: 0,
        y: 2,
        min_h: 1,
        min_w: 2,
        moved: false,
        static: false,
      },
      {
        h: 1,
        i: "2",
        w: 2,
        x: 2,
        y: 2,
        min_h: 1,
        min_w: 2,
        moved: false,
        static: false,
      },
      {
        h: 2,
        i: "3",
        w: 2,
        x: 4,
        y: 0,
        min_h: 2,
        min_w: 2,
        moved: false,
        static: false,
      },
      {
        h: 2,
        i: "4",
        w: 2,
        x: 0,
        y: 0,
        min_h: 2,
        min_w: 2,
        moved: false,
        static: false,
      },
      {
        h: 2,
        i: "5",
        w: 2,
        x: 2,
        y: 0,
        min_h: 2,
        min_w: 2,
        moved: false,
        static: false,
      },
      {
        h: 2,
        i: "6",
        w: 3,
        x: 0,
        y: 4,
        moved: false,
        static: false,
      },
    ],
    xs: [
      {
        h: 1,
        i: "0",
        w: 2,
        x: 2,
        y: 3,
        min_h: 1,
        min_w: 2,
        moved: false,
        static: false,
      },
      {
        h: 1,
        i: "1",
        w: 2,
        x: 2,
        y: 2,
        min_h: 1,
        min_w: 2,
        moved: false,
        static: false,
      },
      {
        h: 1,
        i: "2",
        w: 2,
        x: 2,
        y: 4,
        min_h: 1,
        min_w: 2,
        moved: false,
        static: false,
      },
      {
        h: 2,
        i: "3",
        w: 2,
        x: 0,
        y: 2,
        min_h: 2,
        min_w: 2,
        moved: false,
        static: false,
      },
      {
        h: 2,
        i: "4",
        w: 2,
        x: 0,
        y: 0,
        min_h: 2,
        min_w: 2,
        moved: false,
        static: false,
      },
      {
        h: 2,
        i: "5",
        w: 2,
        x: 2,
        y: 0,
        min_h: 2,
        min_w: 2,
        moved: false,
        static: false,
      },
      {
        h: 2,
        i: "6",
        w: 3,
        x: 0,
        y: 9,
        moved: false,
        static: false,
      },
    ],
    xxs: [
      {
        h: 1,
        i: "0",
        w: 2,
        x: 0,
        y: 8,
        min_h: 1,
        min_w: 2,
        moved: false,
        static: false,
      },
      {
        h: 1,
        i: "1",
        w: 2,
        x: 0,
        y: 6,
        min_h: 1,
        min_w: 2,
        moved: false,
        static: false,
      },
      {
        h: 1,
        i: "2",
        w: 2,
        x: 0,
        y: 7,
        min_h: 1,
        min_w: 2,
        moved: false,
        static: false,
      },
      {
        h: 2,
        i: "3",
        w: 2,
        x: 0,
        y: 4,
        min_h: 2,
        min_w: 2,
        moved: false,
        static: false,
      },
      {
        h: 2,
        i: "4",
        w: 2,
        x: 0,
        y: 0,
        min_h: 2,
        min_w: 2,
        moved: false,
        static: false,
      },
      {
        h: 2,
        i: "5",
        w: 2,
        x: 0,
        y: 2,
        min_h: 2,
        min_w: 2,
        moved: false,
        static: false,
      },
      {
        h: 2,
        i: "6",
        w: 2,
        x: 0,
        y: 9,
        moved: false,
        static: false,
      },
    ],
  };
};

export default {
  getWidgetRegistry,
  getDefaultWidgets,
  getDefaultLayouts,
};
