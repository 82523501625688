// SidenavItem.tsx

import React, { ReactNode } from "react";
import Collapse from "@mui/material/Collapse";
import ListItemText from "@mui/material/ListItemText";
import Icon from "@mui/material/Icon";
import { useSoftUIController } from "../../context";
import { itemArrow, itemContent } from "./styles/sidenavItem";
import { Button } from "@mui/material";

interface SidenavItemProps {
  name: string;
  active?: boolean;
  nested?: boolean;
  children?: ReactNode;
  open?: boolean;
  [key: string]: any; // To accommodate the rest of the props passed
}

const SidenavItem: React.FC<SidenavItemProps> = ({
  name,
  active = false,
  nested = false,
  children = null,
  open = false,
  ...rest
}) => {
  const [controller] = useSoftUIController();
  const { miniSidenav } = controller;

  return (
    <>
      <Button {...rest} sx={(theme) => itemContent(theme, { active, miniSidenav, name, nested })}>
        <ListItemText primary={name} />
        {children && (
          <Icon component="i" sx={(theme) => itemArrow(theme, { open, miniSidenav })}>
            expand_less
          </Icon>
        )}
      </Button>
      {children && (
        <Collapse in={open} timeout="auto" unmountOnExit>
          {children}
        </Collapse>
      )}
    </>
  );
};

export default SidenavItem;
