export const SusConnectedWidgetStyles = {
  display: "grid",
  gridTemplateColumns: "repeat(auto-fit, minmax(290px, 290px))",
  justifyContent: "flex-start",
  " .widgetBox": {
    boxShadow: "0 20px 27px 0 rgba(0,0,0,0.05)",
    borderRadius: "12px",
    width: "235px",
    margin: "1rem",
    padding: "1rem",
    display: "flex",
    flexDirection: "column",
    textAlign: "center",
    " .icon": {
      display: "flex",
      borderRadius: "50%",
      width: "85px",
      height: "85px",
      justifyContent: "center",
      alignItems: "center",
      margin: "0 auto 10px auto",
      fontSize: "22px",
      textTransform: "uppercase",
    },
    " .MuiTypography-root": {
      fontSize: "16px",
    },
  },
};
