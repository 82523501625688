import colors from "@theme/base/colors";
import boxShadows from "@theme/base/boxShadows";
import pxToRem from "@theme/functions/pxToRem";

const { transparent } = colors;
const { md } = boxShadows;

// types
type Types = any;

const switchButton: Types = {
  defaultProps: {
    disableRipple: false,
  },

  styleOverrides: {
    root: {
      width: 42,
      height: 26,
      padding: 0,
      margin: pxToRem(8),
    },
    backgroundColor: transparent,

    switchBase: {
      padding: 0,
      margin: 2,
      transitionDuration: "300ms",

      "&:hover": {
        backgroundColor: transparent,
      },

      "&.Mui-checked": {
        transform: "translateX(16px)",
        color: "#fff",
        "& + .MuiSwitch-track": {
          backgroundColor: "#65C466",
          opacity: 1,
          border: 0,
        },
        "&.Mui-disabled + .MuiSwitch-track": {
          opacity: 0.5,
        },
        "&.Mui-disabled": {
          color: "#ffffff",
        },
      },

      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: "0.3 !important",
      },

      "&.Mui-focusVisible .MuiSwitch-thumb": {
        color: "#33cf4d",
        border: "6px solid #fff",
      },
    },

    thumb: {
      boxSizing: "border-box",
      boxShadow: md,
      width: 22,
      height: 22,
    },

    track: {
      borderRadius: 26 / 2,
      backgroundColor: "#E9E9EA",
      opacity: 1,
      transition: "opacity 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
    },

    checked: {},
  },
};

export default switchButton;
