import React, { useMemo } from "react";
import { Card, IconButton, Skeleton, Tooltip, Zoom } from "@mui/material";

import useStyles from "./styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/pro-regular-svg-icons";
import SusBox from "@components/SusBox";
import SusTypography from "@components/SusTypography";

interface IVesselFinderProps {
  title: string;
  onRemove?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  width?: string;
  height?: string;
  zoom?: string;
  showShipNames?: boolean;
  showTrack?: boolean;
  clickToActivate?: boolean;
  fleet?: string;
  fleetName?: string;
  fleetTimespan?: number;
  fleetHideOldPositions?: boolean;
  mmsi?: number;
  imo?: number | string;
  latitude?: number;
  longitude?: number;
  storePosition?: boolean;
  fil?: string;
  defaultMaptype?: string;
  loading?: boolean;
}

const VesselFinder: React.FC<React.PropsWithChildren<IVesselFinderProps>> = (props) => {
  const {
    title,
    onRemove,
    width,
    height,
    showShipNames,
    showTrack,
    clickToActivate,
    zoom,
    fleet,
    fleetName,
    fleetTimespan,
    fleetHideOldPositions,
    mmsi,
    imo,
    latitude,
    longitude,
    storePosition,
    fil,
    defaultMaptype,
    loading,
  } = {
    width: "100%",
    height: 500,
    showShipNames: false,
    showTrack: false,
    clickToActivate: false,
    ...props,
  };
  const classes = useStyles();
  const correctedWidth = parseInt(width) < 480 && !width.endsWith("%") ? 480 : width;
  const correctedHeight =
    (typeof height === "string" ? parseInt(height) : height) < 400 ? 400 : height;

  const vesselFinder = useMemo(() => {
    const embedUri = encodeURI(
      `https://www.vesselfinder.com/aismap?
    zoom=${zoom || "undefined"}
    &lat=${latitude || "undefined"}
    &lon=${longitude || "undefined"}
    &width=${correctedWidth}
    &height=${correctedHeight}
    &names=${showShipNames}
    ${mmsi ? "&mmsi=" + mmsi : ""}
    ${imo ? "&imo=" + imo : ""}
    &track=${showTrack}
    &fleet=${fleet || false}
    &fleet_name=${(fleetName && encodeURIComponent(fleetName)) || false}
    ${fleetTimespan ? "&fleet_timespan=" + fleetTimespan : ""}
    &fleet_hide_old_positions=${fleetHideOldPositions || false}
    &clicktoact=${clickToActivate}
    &store_pos=${storePosition || false}
    ${fil ? "&fil=" + fil : ""}
    ${defaultMaptype ? "&default_maptype=" + defaultMaptype : ""}
  `
        .replaceAll("\n", "")
        .replaceAll(" ", "")
    );

    return (
      <>
        <SusBox component="span" className={classes.FrameOverlay}></SusBox>
        <iframe
          name="vesselfinder"
          id="vesselfinder"
          // sandbox=""
          width={correctedWidth}
          height={correctedHeight}
          src={embedUri}
          className={classes.Frame}
        >
          Browser does not support embedded objects.
          <br />
          Visit directly{" "}
          <a href="https://www.vesselfinder.com" target="_blank" rel="noreferrer">
            www.vesselfinder.com
          </a>
        </iframe>
      </>
    );
  }, [props]);

  return (
    <Card sx={{ height: "100%" }} className="vesselfinder-card">
      <SusBox
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        flexDirection={{ xs: "row", sm: "row" }}
        pt={2}
        px={2}
        pb={2}
        borderRadius="lg"
        sx={{
          position: "absolute",
          zIndex: "3",
          width: "100%",
          backdropFilter: "saturate(200%) blur(1.875rem)",
          backgroundColor: "rgba(255, 255, 255, 0.8)",
          borderBottomRightRadius: 0,
          borderBottomLeftRadius: 0,
        }}
      >
        <SusBox
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          mx={0}
          borderRadius="md"
          lineHeight={1}
          width={"100%"}
        >
          <SusTypography variant="h6" fontWeight="medium" textTransform="uppercase">
            {loading ? <Skeleton width={190} /> : title}
          </SusTypography>
          {!loading && (
            <>
              {onRemove && (
                <SusBox display="flex" justifyContent="flex-end" alignItems="center">
                  <Tooltip title="Remove Widget" placement="bottom" TransitionComponent={Zoom}>
                    <IconButton
                      onClick={onRemove}
                      sx={{
                        "&:hover": {
                          "& svg": {
                            color: "#EF6461",
                          },
                        },
                      }}
                    >
                      <FontAwesomeIcon icon={faXmark} size="xs" />
                    </IconButton>
                  </Tooltip>
                </SusBox>
              )}
            </>
          )}
        </SusBox>
      </SusBox>
      <SusBox className={classes.BoxContent} sx={{ height: "100%" }}>
        {loading ? (
          <Skeleton variant="rectangular" width={"100%"} height={"100%"} />
        ) : (
          <>{vesselFinder}</>
        )}
      </SusBox>
    </Card>
  );
};

export default VesselFinder;
