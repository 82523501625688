import React from "react";
import { BrowserRouter } from "react-router-dom";

// Soft UI Context Provider
import { createRoot } from "react-dom/client";
import { SoftUIControllerProvider } from "./context";
import App from "./App";

const container = document.getElementById("root");
if (!container) throw new Error("Failed to find the root element");
const root = createRoot(container);

declare global {
  interface Window {
    _mtm: any;
    analyticsServiceKey: string;
  }
}

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <SoftUIControllerProvider>
        <App />
      </SoftUIControllerProvider>
    </BrowserRouter>
  </React.StrictMode>
);
