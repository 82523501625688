import React, { useEffect } from "react";

import Cookies from "js-cookie";
import { useDashboardState } from "@src/app.state";
import useAuth from "@src/hooks/useAuth";
import { useLocation } from "react-router-dom";
import useAlerts from "@hooks/useAlerts";
import { LOGOUT_EVENT_NAME, SERVICE_CONSENT_COOKIE_NAME } from "@src/constants";
import { CompanyMembership } from "@src/types";
import dashboardAxios from "@src/api";
import { generateApiPath } from "@src/utils/helpers";
import { apiPaths } from "@src/paths";

const DashboardStateManager: React.FC<React.PropsWithChildren> = ({ children }) => {
  const state = useDashboardState();
  const { user } = useAuth();
  const { search } = useLocation();
  const { notifyWarning, notifySuccess, notifyInfo } = useAlerts();

  useEffect(() => {
    // Set app-global locale
    const userLocale = user?.locale || "en-US";
    state.setLocale(userLocale);

    if (user && !user?.firstLoginAt && state.currentDialog.type !== "ServiceConsentDialog") {
      state.setCurrentDialog("SetupDialog");
    }
  }, [user?.id]);

  useEffect(() => {
    if (!Cookies.get(SERVICE_CONSENT_COOKIE_NAME)) {
      state.setCurrentDialog("ServiceConsentDialog");
    }
  }, []);

  async function getCompanyMembership(companyId: number): Promise<CompanyMembership | null> {
    const url = generateApiPath(apiPaths.companyMembership, { companyId: companyId.toString() });
    return url
      ? dashboardAxios
          .get(url)
          .then((response) => response.data)
          .catch(() => null)
      : null;
  }

  async function initCurrentCompany() {
    const newCurrentCompanyId = user?.config?.currentCompanyId || user?.companies?.[0]?.companyId;
    if (!newCurrentCompanyId) {
      state.setCurrentCompany(null);
      return;
    }
    const newCurrentCompany = await getCompanyMembership(newCurrentCompanyId);
    state.setCurrentCompany(newCurrentCompany);
  }

  async function initFavoriteCompanies() {
    state.setLoading(true);

    async function setFavCompaniesFromMemberships() {
      const firstMemberships = await dashboardAxios.get(apiPaths.companyMemberships, {
        params: { limit: 3 },
      });
      state.setFavoriteCompanies(firstMemberships?.data?.results as CompanyMembership[]);
    }

    if (!user?.config?.favoriteCompanyIds) {
      await setFavCompaniesFromMemberships();
      state.setLoading(false);
      return;
    }
    const validFavCompanies = await Promise.all(
      user.config.favoriteCompanyIds.map(getCompanyMembership)
    );
    const newFavCompanies = validFavCompanies?.filter(
      (membership) => membership !== null
    ) as CompanyMembership[];

    if (newFavCompanies.length === 0) {
      await setFavCompaniesFromMemberships();
    } else {
      state.setFavoriteCompanies(newFavCompanies);
    }
    state.setLoading(false);
  }

  useEffect(() => {
    initCurrentCompany();
    initFavoriteCompanies();
  }, [user?.id]);

  // Set alerts via query params
  useEffect(() => {
    const query = new URLSearchParams(location.search);
    if (query.has("already-member")) {
      const company = query.get("already-member");
      notifyInfo({
        title: "Already member",
        content: `It looks like you're already a member of ${company}. You have been redirected.`,
        autoHideDuration: 5000,
      });
    } else if (query.has("inv-login")) {
      notifyWarning({
        title: "Unauthenticated",
        content: "Please log in again to accept your invitation.",
        autoHideDuration: 5000,
      });
    } else if (query.has("inv-exp")) {
      notifyWarning({
        title: "Invitation expired",
        content: "Sorry, it looks like that invitation link has expired.",
        autoHideDuration: 5000,
      });
    } else if (query.has("join-success")) {
      const company = query.get("join-success");
      notifySuccess({
        title: "Success",
        content: `You successfully joined ${company}.`,
        autoHideDuration: 5000,
      });
    }
  }, [search]);

  // Close dialogs on global LOGOUT event
  useEffect(() => {
    const logoutEventListener = () => state.setCurrentDialog(null);
    document.body.addEventListener(LOGOUT_EVENT_NAME, logoutEventListener);

    return () => {
      document.body.removeEventListener(LOGOUT_EVENT_NAME, logoutEventListener);
    };
  }, []);

  return <>{children}</>;
};

export default DashboardStateManager;
