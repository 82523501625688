import React, { useEffect, useMemo, useState } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/pro-light-svg-icons";

import { v4 as uuidv4 } from "uuid";
import { Helmet } from "react-helmet-async";
import { useDashboardState } from "@src/app.state";
import useAuth from "@hooks/useAuth";
import useAlerts from "@hooks/useAlerts";
import { Layout, Layouts } from "react-grid-layout";
import { paths } from "@src/paths";
import { availableControlRoomConfigs } from "@src/features/control-room/widgets";
import { cloneDeep, delay, reject } from "lodash";
import SusBox from "@components/SusBox";
import SusGridLayout from "@components/SusGridLayout/SusGridLayout";
import { Grow } from "@mui/material";
import SusTypography from "@components/SusTypography";
import ControlRoomFab from "@components/ControlRoomFab/ControlRoomFab";
import { LayoutItem, WidgetProps, WidgetSpecs } from "../types";

export const ControlRoom: React.FC = () => {
  const dashboardState = useDashboardState();
  const { user, updateUser } = useAuth();
  const { notifyError } = useAlerts();

  const [edit, setEdit] = useState<boolean>(false);
  const [breakpoint, setBreakpoint] = useState<string>("lg");
  const [cols, setCols] = useState<number | undefined>();
  const [layouts, setLayouts] = useState<Layouts>({});
  const [tmpLayouts, setTmpLayouts] = useState<Layouts>({});
  const [items, setItems] = useState<LayoutItem[]>([]);
  const [tmpItems, setTmpItems] = useState<LayoutItem[]>([]);

  useEffect(
    () => dashboardState.setBreadcrumbs([{ label: "Control room", url: paths.controlRoom }]),
    []
  );

  function widgetSpecsToLayoutItem(
    layouts: Layouts,
    fallbackX: number,
    fallbackY: number,
    i: string,
    widgetSpecs: WidgetSpecs,
    serializableProps?: WidgetProps
  ) {
    const itemInCurrentLayout = layouts[breakpoint]?.find((layout: Layout) => layout.i === i);
    const x = itemInCurrentLayout?.x || fallbackX;
    const y = itemInCurrentLayout?.y || fallbackY;
    return compileWidget(widgetSpecs, serializableProps, i, x, y);
  }

  function resetToDefault() {
    const crConfig = availableControlRoomConfigs[dashboardState.viewConfigKey];
    if (!crConfig) {
      return;
    }
    const defaultLayouts = crConfig.getDefaultLayouts();
    const widgets = crConfig
      .getDefaultWidgets(dashboardState.locale)
      .map(({ widget, modifyablePropsValues, x, y }, i) =>
        widgetSpecsToLayoutItem(defaultLayouts, x, y, i.toString(), widget, modifyablePropsValues)
      );
    setLayouts(defaultLayouts);
    setItems(widgets);
  }

  useEffect(() => {
    const savedControlRoomLayouts: Layouts | undefined = user?.config?.controlRoomLayouts;
    const savedControlRoomItems: LayoutItem[] | undefined = user?.config
      ?.controlRoomItems as LayoutItem[];

    const widgetRegistry = availableControlRoomConfigs[
      dashboardState.viewConfigKey
    ]?.getWidgetRegistry(dashboardState.locale);

    if (savedControlRoomLayouts && savedControlRoomItems && widgetRegistry) {
      const newItems: LayoutItem[] = [];

      savedControlRoomItems.forEach((item: LayoutItem) => {
        const widgetSpecs = widgetRegistry.find((widget) => widget.widgetName === item.widgetName);
        if (!widgetSpecs) {
          return;
        }
        const layoutItem = widgetSpecsToLayoutItem(
          savedControlRoomLayouts,
          item.x,
          item.y,
          item.i,
          widgetSpecs,
          item.serializableProps
        );
        newItems.push(layoutItem);
      });

      setLayouts(savedControlRoomLayouts);
      setItems(newItems);
    } else if (items.length === 0) {
      resetToDefault();
    }
  }, [user?.id, breakpoint, dashboardState.locale, dashboardState.viewConfigKey]);

  const startEdit = () => {
    setTmpLayouts(cloneDeep(layouts));
    setTmpItems(cloneDeep(items));
    setEdit(true);
  };
  const cancelEdit = () => {
    setLayouts(cloneDeep(tmpLayouts));
    setItems(cloneDeep(tmpItems));
    setEdit(false);
  };

  const saveLayout = async () => {
    setEdit(false);
    const notifyCouldNotSave = () =>
      notifyError({
        title: "Error",
        content: "Could not save layout. Please reload the page.",
        autoHideDuration: 5000,
      });
    if (updateUser && user?.config) {
      const serializableItems = items.map((item) => {
        const { i, widgetName, x, y, serializableProps } = item;
        return { i, widgetName, x, y, serializableProps };
      });
      const success = await updateUser({
        config: {
          ...user?.config,
          controlRoomLayouts: layouts,
          controlRoomItems: serializableItems,
        },
      });
      if (!success) {
        notifyCouldNotSave();
      }
    } else {
      notifyCouldNotSave();
    }
  };

  const handleAddWidget = () => {
    dashboardState.setCurrentDialog("AddControlRoomWidget", {
      onAddWidget: compileAndAddWidget,
      onResetToDefault: resetToDefault,
    });
  };

  const compileWidget = (
    widgetSpecs: WidgetSpecs,
    modifyablePropsValues?: WidgetProps,
    i?: string,
    x?: number,
    y?: number
  ) => {
    const appliedFixedProps = widgetSpecs.fixedProps?.(modifyablePropsValues || {});
    const appliedModifyableProps = Object.fromEntries(
      Object.entries(modifyablePropsValues || {}).map(([propName, value]) => [
        propName,
        widgetSpecs.modifyableProps?.find(({ name }) => name === propName)?.valueMap?.(value) ||
          value,
      ])
    );
    const props = { ...appliedFixedProps, ...appliedModifyableProps };

    return {
      ...widgetSpecs,
      i: i || uuidv4(),
      x: x || (items.length * 4) % (cols || 12),
      y: y || Infinity, // puts it at the bottom
      props,
      visible: true,
      resizeHandles: [],
      serializableProps: modifyablePropsValues,
    };
  };

  const compileAndAddWidget = (widgetSpecs: WidgetSpecs, modifyablePropsValues?: WidgetProps) => {
    const layoutItem = compileWidget(widgetSpecs, modifyablePropsValues);
    setItems([
      ...items,
      {
        ...layoutItem,
      },
    ]);
  };

  const handleRemoveWidget = (i: string) => {
    setItems(items.map((item) => (item.i === i ? { ...item, visible: false } : item)));
    delay(() => setItems(reject(items, { i: i })), 250);
  };
  const handleLayoutChange = (currentLayout: Layout[], allLayouts: Layouts) => {
    setLayouts(allLayouts);
  };

  // We're using the cols coming back from this to calculate where to add new items.
  const handleBreakpointChange = (newBreakpoint: string, newCols: number) => {
    setBreakpoint(newBreakpoint);
    setCols(newCols);
  };

  // Memoizing the children of ReactGridLayout results in increased performance,
  // because RGL has an optimized shouldComponentUpdate implementation.
  const gridLayoutChildren = useMemo(
    () =>
      items.map((item) => {
        return (
          <SusBox id={`dropItem-${item.i}"`} key={item.i} data-grid={item}>
            <Grow appear in={item?.visible}>
              <SusBox sx={{ height: "100%" }}>
                <item.component
                  {...item.props}
                  uuid={item.i}
                  fullHeight
                  onRemove={edit ? () => handleRemoveWidget(item.i) : null}
                  globalLoading={dashboardState.loading}
                />
              </SusBox>
            </Grow>
          </SusBox>
        );
      }),
    [items, edit, dashboardState.loading]
  );

  return (
    <>
      <Helmet>
        <title>Control Room | PEK Dashboard</title>
      </Helmet>
      <SusBox
        className="ControlRoom-wrapper"
        sx={{
          padding: 0,
          margin: "0 -16px",
        }}
      >
        {items?.length ? (
          <SusGridLayout
            edit={edit}
            layouts={layouts}
            onLayoutChange={handleLayoutChange}
            onBreakpointChange={handleBreakpointChange}
          >
            {gridLayoutChildren}
          </SusGridLayout>
        ) : (
          <Grow appear in>
            <SusBox
              onClick={() => {
                setEdit(true);
              }}
              display="flex"
              sx={{
                height: "280px",
                width: "100%",
                maxWidth: "280px",
                color: "text.secondary",
                border: "0.2rem dashed #d2d6da!important",
                padding: 1.5,
                borderRadius: "1rem",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                cursor: "pointer",
                "&:hover": {
                  color: "#67748e",
                  borderColor: "#67748e !Important",
                },
              }}
            >
              <SusBox display="flex" sx={{ color: "#9EA3B0" }}>
                <FontAwesomeIcon size={"3x"} icon={faPlus} />
              </SusBox>
              <SusBox display="flex" sx={{ mt: 1, textAlign: "center", color: "#9EA3B0" }}>
                <SusTypography variant="body2" sx={{ color: "#9EA3B0" }}>
                  Add Widget
                </SusTypography>
              </SusBox>
            </SusBox>
          </Grow>
        )}
        <ControlRoomFab
          edit={edit}
          startEdit={startEdit}
          cancelEdit={cancelEdit}
          saveLayout={saveLayout}
          onAddWidget={handleAddWidget}
        />
      </SusBox>
    </>
  );
};
