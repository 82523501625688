import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
  SusTabNavigation: {
    marginRight: "auto",
    transition: "width 0.2s ease-in-out",
    width: "100%",
    padding: "1rem",

    "& .SusTabNavigation-Wrapper": {
      borderBottom: "0.5px solid #e0e0e0",
      display: "flex",
      width: "100%",
      maxWidth: "1276px",
    },

    "& .MuiTabs-root": {
      backgroundColor: "transparent !important",
      borderRadius: "0",
      padding: "0",
      margin: "0",

      "& .Mui-selected": {
        fontWeight: "500",
      },
      "& .MuiButtonBase-root": {
        padding: "0.5rem 0.5rem",
        minWidth: "120px !important",
        justifyContent: "center",
        maxWidth: "460px !important",
        display: "inline-flex",
        "&.MuiTabScrollButton-root": {
          maxWidth: "25px !important",
          minWidth: "25px !important",
        },
      },
    },
    "& .MuiTabs-indicator": {
      position: "absolute",
      height: "2px",
      bottom: "0",
      width: "100%",
      transition: "all 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
      boxShadow: "none",
      zIndex: "11",
      borderRadius: "0",
    },
  },

  SusTabActionArea: {
    padding: "1rem",
    borderTop: "1px solid #e0e0e0",

    "& .MuiLoadingButton-root": {
      lineHeight: "1",
      mt: { xs: 1, md: 0 },
      mr: { xs: 1, sm: 1, md: 1 },
      ml: "",
      paddingLeft: "1rem",
      paddingRight: "1rem",
      minWidth: "auto",
    },
  },
});

export default useStyles;
