import pxToRem from "@theme/functions/pxToRem";

interface Theme {
  palette: any;
  boxShadows: any;
  functions: any;
  transitions: any;
  breakpoints: any;
  borders: any;
}

interface OwnerState {
  transparentNavbar: boolean;
  absolute: boolean;
  light: boolean;
}

export function navbar(theme: Theme, ownerState: OwnerState) {
  const { palette, boxShadows, functions, transitions, breakpoints, borders } = theme;
  const { transparentNavbar, absolute, light } = ownerState;

  const { dark, white, text, transparent } = palette;
  const { navbarBoxShadow } = boxShadows;
  const { rgba } = functions;
  const { borderRadius } = borders;

  return {
    boxShadow: navbarBoxShadow,
    backdropFilter: transparentNavbar || absolute ? "none" : `saturate(200%) blur(${pxToRem(30)})`,
    backgroundColor:
      transparentNavbar || absolute ? `${transparent.main} !important` : rgba(white.main, 0.8),

    color: () => {
      let color;

      if (light) {
        color = white.main;
      } else if (transparentNavbar) {
        color = text.main;
      } else {
        color = dark.main;
      }

      return color;
    },
    top: absolute ? 0 : pxToRem(12),
    minHeight: pxToRem(75),
    display: "grid",
    alignItems: "center",
    borderRadius: borderRadius.xl,
    paddingTop: pxToRem(8),
    paddingBottom: pxToRem(8),
    paddingRight: absolute ? pxToRem(8) : 0,
    paddingLeft: absolute ? pxToRem(16) : 0,

    "& > *": {
      transition: transitions.create("all", {
        easing: transitions.easing.easeInOut,
        duration: transitions.duration.standard,
      }),
    },

    "& .MuiToolbar-root": {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",

      [breakpoints.up("sm")]: {
        minHeight: "auto",
        padding: `${pxToRem(4)} ${pxToRem(16)}`,
      },
      [breakpoints.up("xl")]: {
        display: "grid",
        gridTemplateAreas: "'breadcrumbs teamSwitch .'",
      },
      "& .teamSwitch": {
        display: "none",
        gridArea: "teamSwitch",

        [breakpoints.up("xl")]: {
          display: "flex",
        },
      },
    },
  };
}

export const navbarRow = ({ breakpoints } = {}, { isMini } = {}) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  width: "100%",

  [breakpoints?.up("md")]: {
    justifyContent: isMini ? "space-between" : "stretch",
    width: isMini ? "100%" : "max-content",
  },

  [breakpoints?.up("xl")]: {
    justifyContent: "stretch !important",
    width: "max-content !important",
  },
});

export const navbarIconButton = ({ typography: { size }, breakpoints }) => ({
  px: 0.75,
  justifyContent: "flex-start",

  "& .material-icons, .material-icons-round": {
    fontSize: `${size.md} !important`,
  },

  "& .MuiTypography-root": {
    display: "none",

    [breakpoints.up("sm")]: {
      display: "inline-block",
      lineHeight: 1.2,
      ml: 0.5,
    },
  },
});

export const teamSwitch = ({ breakpoints }) => ({
  display: "none",
  cursor: "pointer",

  [breakpoints.up("xl")]: {
    display: "flex",
  },
});

export const navbarDesktopMenu = ({ breakpoints } = {}) => ({
  display: "none !important",
  cursor: "pointer",

  [breakpoints?.up("xl")]: {
    display: "inline-block !important",
  },
});

export const navbarMobileMenu = ({ breakpoints }) => ({
  display: "flex",
  cursor: "pointer",
  marginRight: pxToRem(16),

  [breakpoints.up("xl")]: {
    display: "none",
  },
});
