import React, { useEffect, useMemo, useState } from "react";
import { generatePath, useNavigate, useParams } from "react-router-dom";
import { Card, Grid, MenuItem, Select, TableContainer } from "@mui/material";

import { groupBy, isEmpty } from "lodash";
import useAxios from "axios-hooks";

import { Helmet } from "react-helmet-async";
import useAlerts from "@hooks/useAlerts";
import { useDashboardState } from "@src/app.state";
import {
  ContractPosition,
  ContractPositionStatus,
  FileAttachments,
  statusDetails,
} from "@src/types";
import useApiData from "@hooks/useApiData";
import { generateApiPath, useCurrentCompanyDistributorOrPurchaser } from "@src/utils/helpers";
import { apiBaseUrl, apiPaths, getCallOffHref, paths } from "@src/paths";
import {
  deserializeContractPosition,
  formatData,
  serializeContractPosition,
} from "@src/utils/typing";
import dashboardAxios from "@src/api";
import DropzoneArea from "@components/widgets/DropzoneArea";
import SusBox from "@components/SusBox";
import ProductInfoCard from "@components/widgets/ProductInfoCard";
import SusTypography from "@components/SusTypography";
import ProductWidget from "@components/ProductDetails/ProductDetails";
import ModuleOverviewTable from "@components/ModuleOverviewTable/ModuleOverviewTable";
import CommentBox from "@components/CommentBox/CommentBox";

interface IDetailViewProps {
  overviewUrl: string;
  onDataChange?: (data: ContractPosition) => void;
}

const ContractPositionDetailView: React.FC<React.PropsWithChildren<IDetailViewProps>> = ({
  overviewUrl,
  onDataChange,
  children,
}) => {
  const fileNames = ["Contract", "Safety Data", "Product Sheet"];
  const {
    locale,
    currentCompany,
    loading,
    setBreadcrumbs,
    viewSettings: { contractPosition: contractPositionViewSettings, callOff: callOffViewSettings },
  } = useDashboardState();

  const { id } = useParams();
  const navigate = useNavigate();
  const alerts = useAlerts();
  const [newStatus, setNewStatus] = useState<ContractPositionStatus | undefined>();
  const {
    data: apiData,
    loading: dataLoading,
    error: dataError,
  } = useApiData(generateApiPath(apiPaths.contractPositionDetail, { id }));

  const [, patchData] = useAxios(
    {
      url: generateApiPath(apiPaths.contractPositionDetail, { id }),
      method: "patch",
      withCredentials: true,
    },
    {
      manual: true,
    }
  );
  const [errors, setErrors] = useState<{ [field: string]: string[] }>({});
  const [contractPosition, setContractPosition] = useState<ContractPosition>();
  const statusForAttrs = newStatus || contractPosition?.status;
  const attrs =
    (statusForAttrs &&
      contractPositionViewSettings.fields.byStatus?.[statusForAttrs]?.detailView) ||
    contractPositionViewSettings.fields.default.detailView;

  // Set breadcrumbs
  useEffect(
    () =>
      setBreadcrumbs([
        { label: "Contracts", url: paths.contractOverview },
        {
          label: contractPosition?.contractDistributorReference || "...",
          url: generatePath(paths.contractDetail, {
            id: contractPosition?.contract?.toString() || "",
          }),
        },
        {
          label: contractPosition?.contractPosition
            ? `Pos. ${contractPosition?.contractPosition}`
            : "...",
        },
      ]),
    [contractPosition]
  );

  const companyId = useCurrentCompanyDistributorOrPurchaser(contractPosition)?.id;

  const [edit, setEdit] = useState(false);

  const fileAttachments: FileAttachments = useMemo(
    () => ({
      ...fileNames.reduce((a, v) => ({ ...a, [v]: [] }), {}),
      ...groupBy(apiData?.files, (file: File) => file?.name),
    }),
    [apiData]
  );

  useEffect(() => {
    if (apiData) {
      setContractPosition(
        deserializeContractPosition({ ...apiData, contractId: apiData?.contract })
      );
      onDataChange?.(apiData);
    }
  }, [apiData]);

  const filteredCallOffs = useMemo(
    () =>
      contractPosition?.callOffs &&
      contractPosition.callOffs.filter((co) => co.status !== "CANCELED"),
    [contractPosition?.callOffs]
  );

  useEffect(() => {
    if (!dataError) {
      return;
    }
    alerts.notifyError({
      title: "Not found",
      content:
        dataError?.response?.status === 404
          ? `Could not find contract position with id ${id}.`
          : "Sorry, something went wrong. Please try again later.",
      autoHideDuration: 5000,
    });
    navigate(overviewUrl);
  }, [dataError]);

  const handleStatusChange = (newStatus: any) => {
    if (newStatus?.target?.value) {
      setNewStatus(newStatus?.target?.value);
      setEdit(true);
    }
  };

  const handleEdit = () => setEdit(true);
  const handleCancelEdit = () => {
    setNewStatus(undefined);
    setEdit(false);
  };

  const handleSave = async (newData: ContractPosition) => {
    const serializedContractPosition = serializeContractPosition({
      ...newData,
      status: newStatus,
    });
    return patchData({ data: serializedContractPosition })
      .then((response) => {
        if (response?.data) {
          const newData = {
            ...response.data,
            contractId: response.data?.contract,
          };
          setContractPosition(deserializeContractPosition(newData));
          setEdit(false);
          onDataChange?.(newData);
          return true;
        }
        return false;
      })
      .catch((error) => {
        setErrors(error?.response?.data || {});
        if (error?.response?.data?.detail || isEmpty(error?.response?.data)) {
          alerts.notifyError({
            title: "Error",
            content:
              error?.response?.data?.detail ||
              "Sorry, something went wrong. Please try again later.",
            autoHideDuration: 5000,
          });
        }
        return false;
      });
  };

  const handleDelete = async (id: number) =>
    dashboardAxios
      .delete(generateApiPath(apiPaths.contractPositionDetail, { id: id.toString() }))
      .then((response) => {
        if (response.status === 204) {
          navigate(overviewUrl);
          alerts.notifySuccess({
            title: "Success",
            content: `Successfully deleted contract position with id ${id}.`,
            autoHideDuration: 5000,
          });
        }
      })
      .catch((error) => {
        if (error.response.status === 403) {
          alerts.notifyError({
            title: "Error",
            content: "You don't have permission to delete this contract position.",
            autoHideDuration: 5000,
          });
        } else {
          alerts.notifyError({
            title: "Error",
            content: "Sorry, something went wrong. Please try again later.",
            autoHideDuration: 5000,
          });
        }
      });

  const dropzoneArea = (
    <DropzoneArea
      fileAttachments={fileAttachments}
      loading={dataLoading}
      comingSoon={contractPositionViewSettings.documents?.comingSoon}
      url={generateApiPath(`${apiBaseUrl}${apiPaths.contractPositionFileUpload}`, {
        id,
      })}
    />
  );

  return (
    <>
      <Helmet>
        {contractPosition?.contractPosition && (
          <title>{`Pos. ${contractPosition?.contractPosition} - ${contractPosition?.contractDistributorReference} - Contracts | PEK Dashboard`}</title>
        )}
      </Helmet>
      <SusBox mb={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6} xl={7}>
            <SusBox>
              <>
                <ProductInfoCard
                  title="Position Details"
                  data={contractPosition}
                  className={"contract-position-card"}
                  loading={dataLoading}
                  idGetter={(data) => data?.id}
                  errors={errors}
                  attrs={attrs}
                  editable={
                    contractPositionViewSettings.dataModifiable &&
                    ["admin", "data_editor"].includes(currentCompany?.role || "")
                  }
                  edit={edit}
                  lastModifiedAt={contractPosition?.modifiedTs}
                  onSave={handleSave}
                  onEdit={handleEdit}
                  onCancelEdit={handleCancelEdit}
                  onDelete={handleDelete}
                >
                  <>
                    {!dataLoading && contractPositionViewSettings.statusField && (
                      <Grid item display="flex" xs={12} sm={6} md={4} p={0.5}>
                        <Card
                          sx={{
                            width: "100%",
                            boxShadow: "none",
                            border: "1px solid rgba(34, 34, 34, 0.125)",
                            borderRadius: "13px",
                          }}
                        >
                          <SusBox
                            display="flex"
                            xs={6}
                            p={1}
                            flexDirection="column"
                            sx={{ width: "100%" }}
                          >
                            <SusTypography
                              variant="caption"
                              fontWeight="regular"
                              display="flex"
                              textTransform="uppercase"
                              fontSize="sm"
                            >
                              Status
                            </SusTypography>
                            {edit ? (
                              <Select
                                labelId="status-select-label"
                                id="status-select"
                                value={
                                  (newStatus !== undefined
                                    ? newStatus
                                    : contractPosition?.status) || ""
                                }
                                disabled={!edit}
                                onChange={handleStatusChange}
                                variant="standard"
                                size="small"
                                sx={{
                                  background: "transparent !important",
                                  border: "none !important",
                                  "& .MuiInput-root": {
                                    backgroundColor: "transparent !important",
                                    border: "none !important",
                                  },
                                  "& .MuiSelect-select": {
                                    fontSize: "16px",
                                  },
                                }}
                              >
                                {contractPositionViewSettings.availableStatus.map((status) => (
                                  <MenuItem key={status} value={status} sx={{ fontSize: "16px" }}>
                                    {statusDetails[status].label}
                                  </MenuItem>
                                ))}
                              </Select>
                            ) : (
                              <>
                                <SusTypography variant="button" fontWeight="bold" p={0.5} px={0}>
                                  {contractPosition?.status &&
                                    formatData(contractPosition.status, "positionStatus", locale)}
                                </SusTypography>
                              </>
                            )}
                          </SusBox>
                        </Card>
                      </Grid>
                    )}
                  </>
                </ProductInfoCard>
              </>
            </SusBox>
          </Grid>
          {contractPosition && attrs && (
            <Grid item xs={12} md={6} xl={5}>
              <SusBox>
                {contractPosition && attrs && !contractPosition.product ? (
                  !contractPositionViewSettings.documents?.hide ? (
                    dropzoneArea
                  ) : null
                ) : (
                  <ProductWidget data={contractPosition.product} />
                )}
              </SusBox>
            </Grid>
          )}
        </Grid>
        {contractPosition &&
          contractPosition.product &&
          !contractPositionViewSettings.documents?.hide && (
            <Grid container spacing={3} mt={0.5}>
              <Grid item xs={12} md={12} xl={12}>
                {dropzoneArea}
              </Grid>
            </Grid>
          )}

        {filteredCallOffs?.length ? (
          <Grid container spacing={3} mt={0.5}>
            <Grid item xs={12} md={12} xl={12}>
              <TableContainer>
                <ModuleOverviewTable
                  rows={filteredCallOffs || []}
                  loading={loading}
                  columns={callOffViewSettings.fields.default.childList}
                  getHref={getCallOffHref}
                  localStoragePrefix={"ContractPositionDetailCallOffsGeneralTable"}
                  title={"Call-Offs"}
                />
              </TableContainer>
            </Grid>
          </Grid>
        ) : null}
        {children}

        <Grid container spacing={3} mt={0.5}>
          <Grid item xs={12} md={12} xl={12}>
            <CommentBox
              title="Comments"
              loading={dataLoading}
              comments={contractPosition?.comments || []}
              commentedObjectRef={contractPosition?.genericRef}
              companyId={companyId}
              distributorId={contractPosition?.distributor?.id}
            />
          </Grid>
        </Grid>
      </SusBox>
    </>
  );
};

export default ContractPositionDetailView;
