export const SusListOfContentStyle = {
  boxShadow: "none",
  width: "100%",
  borderRadius: "0px",
  ".MuiTable-root": {
    minWidth: "100%",
  },
  thead: {
    display: "table-header-group",
    borderRadius: "0",
    ".MuiTableCell-root": { borderBottom: "0.16rem solid #dcdcdc" },
  },
  tbody: {
    transitionDuration: "0.3s",
    transitionProperty: "background-color",
    transform: "perspective(1px) translateZ(0)",

    "& .MuiTableRow-root": {
      background: "transparent",
    },
    "& :last-child td, &:last-child th": { border: 0 },
  },
};
