import colors from "@theme/base/colors";

const root = {
  borderRadius: "0!important",
  marginBottom: "1rem",
  backgroundColor: "none",
  boxShadow: "none",
  "&:before": {
    display: "none",
  },
  "&.Mui-expanded": {
    boxShadow: "0",
    backgroundColor: colors.white.main,
    borderRadius: "0",
    marginBottom: "2.6rem",
  },
};

export default root;
