import React, { useState } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  LinearProgress,
  Step,
  Stepper,
  Tooltip,
  useMediaQuery,
  useTheme,
  Zoom,
} from "@mui/material";

import "simplebar/dist/simplebar.min.css";

import AgbContent from "./AgbContent";
import Confetti from "react-confetti";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft, faAngleRight } from "@fortawesome/pro-regular-svg-icons";
import { DateTime } from "luxon";
import useAuth from "@hooks/useAuth";
import { useDashboardState } from "@src/app.state";
import ModuleNotificationSettings from "@components/NotificationSettingsPanel/ModuleNotificationSettings";
import SusBox from "@components/SusBox";
import { default as GreetingsImage } from "@src/assets/images/GreetingsImage.png";
import { SusDialogStyles } from "@components/SusDialog/styles";
import SusTypography from "@components/SusTypography";
import SusButton from "@components/SusButton";

const STEP_AGB = 0;

interface Step {
  headline: string;
  description: string;
  content?: React.ReactNode;
}

interface StepperProgressBarProps {
  activeStep: number;
  steps: Step[];
}

const StepperProgressBar = React.memo(({ activeStep, steps }: StepperProgressBarProps) => {
  const maxSteps = steps.length;
  const progress = ((activeStep + 1) / maxSteps) * 100;

  return <LinearProgress variant="determinate" value={progress} sx={{ borderRadius: "0" }} />;
});
const SetupDialog: React.FC = () => {
  const { user, updateUser } = useAuth();
  const [activeStep, setActiveStep] = React.useState(0);
  const theme = useTheme();
  const fullScreenQuery = useMediaQuery(theme.breakpoints.down("lg"));
  const { currentDialog, setCurrentDialog, startTour } = useDashboardState();
  const [agbScrolledDown, setAgbScrolledDown] = useState(false);

  const handleScrollToBottom = () => {
    setAgbScrolledDown(true);
  };

  const steps: Step[] = [
    {
      headline: "Welcome to PEK Dashboard - Terms of use",
      description: `Please read the Terms of use carefully. And accept them.`,
      content: <AgbContent onScrollToBottom={handleScrollToBottom} />,
    },
    {
      headline: "Notifications",
      description: `Please select the modules you want to be notified about.`,
      content: <ModuleNotificationSettings />,
    },
    {
      headline: "Get started",
      description: `You can now start the screen tour or jump directly to the dashboard.`,
      content: (
        <SusBox>
          <Confetti width={600} height={400} numberOfPieces={200} gravity={0.5} recycle={false} />
          <SusBox
            component="img"
            alt="..."
            src={GreetingsImage}
            width="345"
            sx={{ width: "360px" }}
          />
        </SusBox>
      ),
    },
  ];

  const handleAcceptTerms = () => {
    updateUser?.({ termsAcceptedAt: DateTime.now().toISO() || undefined });
    handleNext();
  };

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };
  const isLastStep = activeStep === steps.length - 1;

  const setFirstLogin = () => {
    if (!user?.firstLoginAt && updateUser) {
      updateUser({ firstLoginAt: DateTime.now().toISO() || undefined });
    }
  };

  const handleCloseDialog = () => {
    setFirstLogin();
    setCurrentDialog(null);
  };

  const handleClickJourney = () => {
    setFirstLogin();
    setCurrentDialog(null);
    startTour();
  };

  return (
    <Dialog
      fullScreen={fullScreenQuery}
      open={currentDialog.type === "SetupDialog"}
      scroll="body"
      disableEscapeKeyDown
      sx={
        SusDialogStyles && {
          "& .MuiDialog-container": {
            alignItems: "center",
          },
          "& .MuiDialog-paper": {
            maxWidth: "100%",
            width: "782px",
          },
        }
      }
    >
      <Stepper
        connector={null}
        activeStep={activeStep}
        sx={{ maxWidth: 870, flexGrow: 1, padding: 0, margin: 0 }}
      >
        {steps.map(({ headline, description, content }, index) => (
          <React.Fragment key={index}>
            {activeStep === index && (
              <Step sx={{ padding: 0, width: "100%" }}>
                <DialogTitle id="responsive-dialog-title" sx={{ m: 0, p: 2 }}>
                  {headline}
                </DialogTitle>
                <StepperProgressBar activeStep={activeStep} steps={steps} />
                <DialogContent
                  sx={{
                    padding: "1rem",
                    borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
                    textAlign: "center",
                  }}
                >
                  <SusTypography variant="body2" mt="10px" mb="25px">
                    {description}
                  </SusTypography>
                  {content}
                </DialogContent>
              </Step>
            )}
          </React.Fragment>
        ))}
      </Stepper>
      <DialogActions sx={{ justifyContent: "space-between" }}>
        <SusBox display="flex" justifyContent="flex-start">
          {activeStep !== 0 && (
            <SusButton
              size="medium"
              color="text"
              variant="outlined"
              onClick={handleBack}
              disabled={activeStep === 0}
              sx={{
                lineHeight: "1",
                mt: { xs: 1, md: 0 },
                mr: { xs: 1, sm: 1, md: 1 },
                ml: "auto",
                paddingLeft: "1rem",
                paddingRight: "1rem",
                minWidth: "auto",
              }}
            >
              <SusBox mr="0.5rem" color="text" display="flex" sx={{ alignItems: "center" }}>
                <FontAwesomeIcon icon={faAngleLeft} size="xs" />
              </SusBox>
              Back
            </SusButton>
          )}
        </SusBox>
        <SusBox display="flex" justifyContent="flex-end" sx={{ padding: "0 0 0 1rem" }}>
          {isLastStep ? (
            <SusBox display="flex" justifyContent="flex-end" sx={{ padding: "0 0 0 1rem" }}>
              <SusButton
                size="medium"
                color="text"
                variant="outlined"
                onClick={handleCloseDialog}
                sx={{
                  lineHeight: "1",
                  mt: { xs: 1, md: 0 },
                  mr: { xs: 1, sm: 1, md: 1 },
                  ml: "auto",
                  paddingLeft: "1rem",
                  paddingRight: "1rem",
                  minWidth: "auto",
                }}
              >
                Go to Dashboard
              </SusButton>
              <SusButton
                size="medium"
                color="primary"
                variant="contained"
                onClick={handleClickJourney}
                sx={{
                  lineHeight: "1",
                  mt: { xs: 1, md: 0 },
                  mr: { xs: 1, sm: 1, md: 1 },
                  ml: "auto",
                  paddingLeft: "1rem",
                  paddingRight: "1rem",
                  minWidth: "auto",
                }}
              >
                Start Journey
              </SusButton>
            </SusBox>
          ) : (
            <>
              {activeStep === STEP_AGB ? (
                <Tooltip
                  title="Please read the terms and conditions to the end"
                  placement="top"
                  disableInteractive={!agbScrolledDown}
                  TransitionComponent={Zoom}
                  open={activeStep === STEP_AGB && !agbScrolledDown}
                >
                  <span>
                    <SusButton
                      size="medium"
                      variant="contained"
                      color="primary"
                      disabled={!agbScrolledDown}
                      onClick={handleAcceptTerms}
                      sx={{
                        lineHeight: "1",
                        mt: { xs: 1, md: 0 },
                        mr: { xs: 1, sm: 1, md: 1 },
                        ml: "auto",
                        paddingLeft: "1rem",
                        paddingRight: "1rem",
                        minWidth: "auto",
                      }}
                    >
                      Accept & Next
                      <SusBox
                        ml="0.5rem"
                        color="white"
                        display="flex"
                        sx={{ alignItems: "center" }}
                      >
                        <FontAwesomeIcon icon={faAngleRight} size="xs" />
                      </SusBox>
                    </SusButton>
                  </span>
                </Tooltip>
              ) : (
                <SusButton
                  size="medium"
                  variant="contained"
                  color="primary"
                  onClick={handleNext}
                  sx={{
                    lineHeight: "1",
                    mt: { xs: 1, md: 0 },
                    mr: { xs: 1, sm: 1, md: 1 },
                    ml: "auto",
                    paddingLeft: "1rem",
                    paddingRight: "1rem",
                    minWidth: "auto",
                  }}
                >
                  Next
                  <SusBox ml="0.5rem" color="white" display="flex" sx={{ alignItems: "center" }}>
                    <FontAwesomeIcon icon={faAngleRight} size="xs" />
                  </SusBox>
                </SusButton>
              )}
            </>
          )}
        </SusBox>
      </DialogActions>
    </Dialog>
  );
};
export default SetupDialog;
