import React, { useState } from "react";

import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPaperPlaneTop } from "@fortawesome/pro-solid-svg-icons";
import { useForm } from "react-hook-form";

import { SubmitHandler } from "react-hook-form/dist/types/form";
import { useDashboardState } from "@src/app.state";
import useAuth from "@hooks/useAuth";
import useAlerts from "@hooks/useAlerts";
import dashboardAxios from "@src/api";
import { apiPaths } from "@src/paths";
import SusDialog from "@components/SusDialog/SusDialog";
import SusBox from "@components/SusBox";

interface FormProps {
  onSubmit?: (data: FormData) => void;
}

interface FormData {
  subject: string;
  messageType: string;
  message: string;
}

const SupportContactDialog: React.FC<FormProps> = () => {
  const { currentDialog, setCurrentDialog } = useDashboardState();
  const { user } = useAuth();
  const {
    register,
    handleSubmit,
    formState: { isDirty },
  } = useForm<FormData>();
  const { notifySuccess, notifyError } = useAlerts();

  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState<FormData | undefined>();

  const onSubmit: SubmitHandler<FormData> = async (data, event) => {
    event?.preventDefault();
    setLoading(true);
    dashboardAxios
      .post(apiPaths.supportRequest, {
        ...data,
        email: user?.email,
      })
      .then(() => {
        setErrors(undefined);
        notifySuccess({
          title: "Success",
          content: "Support request sent.",
          autoHideDuration: 5000,
        });
        setCurrentDialog(null);
      })
      .catch((error) => {
        setErrors(error?.response?.data?.errors);
        notifyError({
          title: "Error",
          content: "Something went wrong. Please check your input.",
          autoHideDuration: 5000,
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  console.log(errors);

  const handleClose = (event: any, reason: string) => {
    if (reason === "backdropClick") {
      return;
    }
    setCurrentDialog(null);
  };

  return (
    <SusDialog
      title={`New Support Request`}
      open={currentDialog.type === "SupportContactDialog"}
      onClose={handleClose}
      sx={{ "& .MuiPaper-root": { width: "700px" } }}
      actions={() => (
        <>
          <LoadingButton
            variant="contained"
            color="primary"
            loading={loading}
            size="medium"
            sx={{
              lineHeight: "1",
              mt: { xs: 1, md: 0 },
              mr: { xs: 1, sm: 1, md: 1 },
              ml: "",
              paddingLeft: "1rem",
              paddingRight: "1rem",
              minWidth: "auto",
            }}
            onClick={handleSubmit(onSubmit)}
            disabled={loading || !isDirty}
          >
            <SusBox mr="0.5rem" color="white" display="inlineFlex">
              <FontAwesomeIcon icon={faPaperPlaneTop} size="xs" />
            </SusBox>
            Send
          </LoadingButton>
        </>
      )}
    >
      <SusBox p={2}>
        <FormControl
          fullWidth
          variant="outlined"
          sx={{
            my: 1.5,
            "& .MuiInput-root": {
              fontSize: "16px",
            },
          }}
        >
          <InputLabel>Subject</InputLabel>
          <OutlinedInput
            label="Subject"
            type="text"
            {...register("subject")}
            error={!!errors && "subject" in errors}
            required
            fullWidth
          />
          {errors && "subject" in errors && (
            <FormHelperText id="standard-weight-helper-text">{errors.subject}</FormHelperText>
          )}
        </FormControl>
        <FormControl
          fullWidth
          variant="outlined"
          sx={{
            my: 1.5,
            "& .MuiInput-root": {
              fontSize: "16px",
            },
          }}
        >
          <InputLabel>Request type</InputLabel>
          <Select
            label="Request type"
            {...register("messageType")}
            error={!!errors && "messageType" in errors}
            required
            fullWidth
          >
            <MenuItem value="question">Question</MenuItem>
            <MenuItem value="problem">Issue</MenuItem>
            <MenuItem value="feature">Feature request</MenuItem>
          </Select>
          {errors && "messageType" in errors && (
            <FormHelperText id="standard-weight-helper-text">{errors.messageType}</FormHelperText>
          )}
        </FormControl>
        <FormControl
          fullWidth
          sx={{
            my: 1.5,
            "& .MuiInput-root": {
              fontSize: "16px",
            },
          }}
        >
          <InputLabel>Message</InputLabel>
          <OutlinedInput
            label="Message"
            {...register("message")}
            error={!!errors && "message" in errors}
            required
            fullWidth
            multiline
            rows={4}
          />
          {errors && "message" in errors && (
            <FormHelperText id="standard-weight-helper-text">{errors.message}</FormHelperText>
          )}
        </FormControl>
      </SusBox>
    </SusDialog>
  );
};

export default SupportContactDialog;
