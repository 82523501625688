import "chart.js/auto";
import React from "react";
import { FilterSpec } from "../../../../types";
import SimpleAggregationChart from "../SimpleAggregationChart";

interface IChartMtPerVesselProps {
  filter?: FilterSpec;
  onRemove?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

const ChartVolumeByVessel: React.FC<IChartMtPerVesselProps> = ({ filter, onRemove }) => {
  return (
    <SimpleAggregationChart
      objectClass="ContractPosition"
      title="Total volume by vessel (MT)"
      groupByField="vesselName"
      filter={filter}
      onRemove={onRemove}
      aggregationField="amount"
    />
  );
};

export default ChartVolumeByVessel;
