import React, { useEffect } from "react";

import Box from "@mui/material/Box";

import { Helmet } from "react-helmet-async";
import { useDashboardState } from "@src/app.state";
import { paths } from "@src/paths";
import UserProfile from "./tabPages/UserProfile";
import SecurityTabPage from "./tabPages/SecurityTabPage";
import CompanyTabPage from "./tabPages/CompanyTabPage";
import SusTabBox from "@components/SusTabBox/SusTabBox";

const SettingsPage: React.FC = () => {
  const { setBreadcrumbs } = useDashboardState();
  useEffect(() => setBreadcrumbs([{ label: "Preferences", url: paths.settings }]), []);

  const settingsPageData = [
    {
      tabTitle: "My details",
      tabContent: <UserProfile />,
    },
    {
      tabTitle: "Login & Security",
      tabContent: <SecurityTabPage />,
    },
    {
      tabTitle: "Company",
      tabContent: <CompanyTabPage />,
    },
    // {
    //   tabTitle: "Global Admin",
    //   tabContent: <GlobalAdminTabPage />,
    // },
  ];

  return (
    <>
      <Helmet>
        <title>Preferences | PEK Dashboard</title>
      </Helmet>
      <Box
        sx={{
          ".SusTabPanel": {
            px: "2rem",
          },
        }}
      >
        <SusTabBox data={settingsPageData} />
      </Box>
    </>
  );
};

export default SettingsPage;
