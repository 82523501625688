import React, { useMemo, useState } from "react";
import { generatePath, useNavigate } from "react-router-dom";
import useAxios from "axios-hooks";
import Grid from "@mui/material/Grid";
import { Autocomplete, FormControl, TextField } from "@mui/material";
import { DateTime } from "luxon";
import LoadingButton from "@mui/lab/LoadingButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFloppyDisk, faTimes } from "@fortawesome/pro-regular-svg-icons";
import { DatePicker } from "@mui/x-date-pickers-pro";
import useAlerts from "@hooks/useAlerts";
import { AttrType, Contract } from "@src/types";
import { useDashboardState } from "@src/app.state";
import useCompanySettings from "@hooks/useCompanySettings";
import { apiPaths, paths } from "@src/paths";
import { NumberParser, serializeContract } from "@src/utils/typing";
import SusDialog from "@components/SusDialog/SusDialog";
import SusButton from "@components/SusButton";
import SusBox from "@components/SusBox";

const NewContractDialog: React.FC = () => {
  const navigate = useNavigate();
  const alerts = useAlerts();
  const [data, setData] = useState<Contract>({});
  const {
    currentDialog,
    setCurrentDialog,
    currentCompany,
    locale,
    viewSettings: { contract: contractViewSettings },
  } = useDashboardState();
  const { company } = useCompanySettings(currentCompany?.companyId || -1);
  const relatedCompanies = company
    ? company.isDistributor
      ? company.customers
      : company.distributors
    : [];

  const [{ loading }, postData, cancelPost] = useAxios(
    {
      url: apiPaths.contracts,
      method: "post",
    },
    {
      manual: true,
    }
  );

  const numberParser = useMemo(() => new NumberParser(locale), [locale]);

  const [submitting, setSubmitting] = useState<boolean>(false);
  const [errors, setErrors] = useState<{ [field: string]: string[] }>({});

  const handleSave = () => {
    setSubmitting(true);
    if (!currentCompany?.companyId) {
      alerts.notifyError({
        title: "Error",
        content: "No current company selected!",
        autoHideDuration: 5000,
      });
    }
    const contract = serializeContract({
      ...data,
      [currentCompany?.companyIsDistributor ? "distributor" : "purchaser"]:
        currentCompany?.companyId,
    });
    postData({ data: contract })
      .then((response) => {
        if (response?.data?.id) {
          navigate(generatePath(paths.contractDetail, { id: response.data.id }));
          setCurrentDialog(null);
        }
      })
      .catch((error) => {
        setErrors(error?.response?.data || {});
        alerts.notifyError({
          title: "Error",
          content:
            error?.response?.data?.detail || "Sorry, something went wrong. Please try again later.",
          autoHideDuration: 5000,
        });
      })
      .finally(() => setSubmitting(false));
  };

  const handleClose = (event: any, reason: string) => {
    if (reason === "backdropClick") {
      return;
    }
    if (submitting) {
      cancelPost();
    }
    setCurrentDialog(null);
  };

  const handleInputChange = (
    key: keyof Contract,
    e: React.ChangeEvent<{ value: unknown }> | null
  ) => {
    setData({
      ...data,
      [key]: e?.target?.value as string,
    });
  };

  const handleNumberInputChange = (
    key: keyof Contract,
    e: React.ChangeEvent<{ value: unknown }>
  ) => {
    const value = numberParser.parse((e?.target?.value as string) || "");
    setData({
      ...data,
      [key]: value,
    });
  };

  const handleDateInputChange = (key: keyof Contract, e: DateTime | null) => {
    setData({
      ...data,
      [key]: e,
    });
  };

  const handleCompanyChange = (newValue: { label: string; id: number } | null) => {
    if (!currentCompany || !newValue) {
      return;
    }
    setData({
      ...data,
      [currentCompany.companyIsDistributor ? "purchaser" : "distributor"]: newValue.id,
    });
  };

  const makeDialogInput = (label: string, key: keyof Contract, type?: AttrType) => (
    <FormControl
      key={key}
      fullWidth
      variant="outlined"
      sx={{
        m: 1.5,
        "& .MuiInput-root": {
          fontSize: "16px",
        },
      }}
    >
      {type && type === "date" ? (
        <DatePicker
          label={label}
          onChange={(e: DateTime | null) => handleDateInputChange(key, e)}
          slotProps={{ textField: { variant: "outlined" } }}
        />
      ) : type === "number" ? (
        <TextField
          label={label}
          defaultValue={((data[key] as any) || "").toString()}
          onChange={(e: React.ChangeEvent<{ value: unknown }>) => handleNumberInputChange(key, e)}
          size="small"
          inputProps={{ inputMode: "numeric" }}
          error={errors?.[key] && errors[key].length > 0}
          helperText={errors?.[key] && errors[key][0]}
          sx={{
            "& .MuiInput-root": {
              fontSize: "16px",
            },
          }}
        />
      ) : (
        <TextField
          defaultValue={data[key]}
          label={label}
          onChange={(e: React.ChangeEvent<{ value: unknown }>) => handleInputChange(key, e)}
          size="medium"
          error={errors?.[key] && errors[key].length > 0}
          helperText={errors?.[key] && errors[key][0]}
          fullWidth
        />
      )}
    </FormControl>
  );

  return (
    <SusDialog
      title={`New Contract (${currentCompany?.companyName})`}
      open={currentDialog.type === "NewContract"}
      onClose={handleClose}
      confirmClose
      disableEscapeKeyDown
      actions={(handleCloseConfirm) => (
        <>
          <SusButton
            size="medium"
            color="text"
            variant="outlined"
            sx={{
              lineHeight: "1",
              mt: { xs: 1, md: 0 },
              mr: { xs: 1, sm: 1, md: 1 },
              ml: "auto",
              paddingLeft: "1rem",
              paddingRight: "1rem",
              minWidth: "auto",
            }}
            onClick={handleCloseConfirm}
            disabled={submitting}
          >
            <SusBox mr="0.5rem" color="text" display="inlineFlex">
              <FontAwesomeIcon icon={faTimes} size="xs" />
            </SusBox>
            cancel
          </SusButton>
          <LoadingButton
            variant="contained"
            color="primary"
            loading={loading}
            size="medium"
            sx={{
              lineHeight: "1",
              mt: { xs: 1, md: 0 },
              mr: { xs: 1, sm: 1, md: 1 },
              ml: "",
              paddingLeft: "1rem",
              paddingRight: "1rem",
              minWidth: "auto",
            }}
            onClick={handleSave}
            disabled={submitting}
          >
            <SusBox mr="0.5rem" color="white" display="inlineFlex">
              <FontAwesomeIcon icon={faFloppyDisk} size="xs" />
            </SusBox>
            Save
          </LoadingButton>
        </>
      )}
    >
      <SusBox p={2}>
        <SusBox>
          <Grid container mb={1} flexDirection="row">
            <FormControl sx={{ m: 1.5 }} fullWidth variant="outlined">
              <Autocomplete
                id="company"
                options={relatedCompanies.map((company) => ({
                  label: company.name,
                  id: company.id,
                }))}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={[currentCompany?.companyIsDistributor ? "Purchaser" : "Distributor"]}
                    sx={{
                      width: "100%",
                      "& .MuiInput-root": {
                        fontSize: "16px",
                      },
                    }}
                  />
                )}
                getOptionLabel={(option) => option.label}
                onChange={(e, newValue) => handleCompanyChange(newValue)}
                sx={{ width: "100%" }}
              />
            </FormControl>
            {contractViewSettings.fields.default.detailView
              .filter(({ readonly }) => !readonly)
              .map(({ field, label, type }) => makeDialogInput(label, field, type))}
          </Grid>
        </SusBox>
      </SusBox>
    </SusDialog>
  );
};

export default NewContractDialog;
