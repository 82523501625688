// @mui material components
import Avatar from "@mui/material/Avatar";
import { styled } from "@mui/material/styles";
import pxToRem from "@src/assets/theme/functions/pxToRem";
import typography from "@theme/base/typography";

interface OwnerState {
  size?: string;
}

export default styled(Avatar)<{ ownerState: OwnerState }>(({ ownerState }) => {
  const { size } = ownerState;

  let sizeValue;

  switch (size) {
    case "xs":
      sizeValue = {
        width: pxToRem(24),
        height: pxToRem(24),
        fontSize: typography.size.xs,
      };
      break;
    case "sm":
      sizeValue = {
        width: pxToRem(36),
        height: pxToRem(36),
        fontSize: typography.size.sm,
        borderRadius: pxToRem(5),
        boxShadow: "0rem 1.25rem 1.6875rem 0rem rgb(34 34 34 / 5%)",
      };
      break;
    case "lg":
      sizeValue = {
        width: pxToRem(58),
        height: pxToRem(58),
        fontSize: typography.size.sm,
      };
      break;
    case "xl":
      sizeValue = {
        width: pxToRem(74),
        height: pxToRem(74),
        fontSize: typography.size.md,
      };
      break;
    case "xxl":
      sizeValue = {
        width: pxToRem(110),
        height: pxToRem(110),
        fontSize: typography.size.md,
      };
      break;
    default: {
      sizeValue = {
        width: pxToRem(48),
        height: pxToRem(48),
        fontSize: typography.size.md,
      };
    }
  }

  return {
    fontWeight: typography.fontWeightBold,
    ...sizeValue,
  };
});
