import { makeStyles } from "@mui/styles";
import pxToRem from "@theme/functions/pxToRem";

const useStyles = makeStyles({
  Comments: {
    "&:hover": {
      background: "#f2f4f8a1",
    },
  },
  CommentMessage: {
    "& blockquote": {
      position: "relative",
      marginTop: pxToRem(4),
      marginBottom: pxToRem(4),
      paddingLeft: pxToRem(16),
      "&::before": {
        content: '""',
        position: "absolute",
        top: "0",
        bottom: "0",
        left: "0",
        display: "block",
        width: pxToRem(4),
        borderRadius: pxToRem(8),
        background: "#8292ab",
      },
    },

    "& ol, & ul": {
      "& li": {
        marginLeft: pxToRem(20),
        marginBottom: "0",
        "&::before": {
          content: '""',
          width: pxToRem(15),
          display: "inline-block",
        },
      },
    },
  },
});

export default useStyles;
