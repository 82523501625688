import React, { memo, useEffect, useState } from "react";

import { Card, Grid } from "@mui/material";
import { Product } from "@src/types";
import { useDashboardState } from "@src/app.state";
import { formatData } from "@src/utils/typing";
import SusTypography from "@components/SusTypography";
import SusWidget from "@src/templates/SusWidget";
import SusBox from "@components/SusBox";

const ProductWidget: React.FC<{ data?: Product }> = memo(({ data }) => {
  const { locale } = useDashboardState();
  const [size, setSize] = useState("");
  const [purity, setPurity] = useState("");

  useEffect(() => {
    if (!data) {
      return;
    }
    const sizeMin = formatData(data.sizeMin, "decimal", locale);
    const sizeMax = formatData(data.sizeMax, "decimal", locale);
    setPurity(`${data.purityMin || ""}`);
    setSize(
      sizeMin && sizeMax
        ? `${sizeMin} - ${sizeMax} ${data.sizeUnit || ""}`.trim()
        : `${sizeMin || sizeMax} ${data.sizeUnit || ""}`.trim()
    );
  }, [data, locale]);

  if (!data) {
    return <SusTypography>No product info given</SusTypography>;
  }

  const cardProps = {
    sx: {
      width: "100%",
      boxShadow: "none",
      border: "1px solid rgba(34, 34, 34, 0.125)",
      borderRadius: "13px",
    },
  };

  const productDetails = [
    {
      name: "Name",
      value: data.nameEn,
    },
    {
      name: "Size",
      value: size,
    },
    {
      name: "Purity",
      value: purity,
    },
    {
      name: "Origin",
      value: data.countryOfOrigin,
    },
  ];

  return (
    <SusWidget title="Product Details" className={"product-details-card"}>
      <Grid container mb={1}>
        {productDetails.map((productDetails, index) => (
          <Grid item display="flex" xs={12} sm={6} md={4} p={0.5} key={index}>
            <Grid container>
              <Card {...cardProps}>
                <SusBox display="flex" xs={6} p={1} flexDirection="column" sx={{ width: "100%" }}>
                  <SusTypography
                    variant="caption"
                    fontWeight="regular"
                    display="flex"
                    textTransform="uppercase"
                    fontSize="sm"
                  >
                    {productDetails.name}
                  </SusTypography>
                  <SusTypography
                    variant="button"
                    fontWeight="bold"
                    p={0.5}
                    px={0}
                    sx={{ fontSize: "16px" }}
                  >
                    {productDetails.value}
                  </SusTypography>
                </SusBox>
              </Card>
            </Grid>
          </Grid>
        ))}
      </Grid>
    </SusWidget>
  );
});

export default ProductWidget;
