// types
import colors from "@theme/base/colors";
import borders from "@theme/base/borders";

type Types = any;

const dialog: Types = {
  styleOverrides: {
    root: {
      transform: "translateZ(1005px)",
    },
    paper: {
      borderRadius: borders.borderRadius.lg,
      boxShadow: "3px 2px 7px 0px rgb(0 0 0 / 5%)",
      background: colors.background.paper,
    },

    paperFullScreen: {
      borderRadius: 0,
    },
  },
};

export default dialog;
