import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
  dropzone: {
    boxSizing: "border-box",
    minHeight: "150px",
    border: "1px solid #67748e",
    borderRadius: "5px",
    padding: "20px 20px",
    position: "relative",

    "&.dz-clickable": {
      cursor: "pointer",
    },
    "&.dz-started": {
      border: "1px solid #67748e !important",
      boxShadow: "0rem 1.25rem 1.6875rem 0rem rgb(34 34 34 / 5%)",

      "&.dz-error": {
        border: "0.0625rem dashed #67748e!important",
        boxShadow: "none",
      },

      "& .dz-message": {
        display: "none",
      },
      "& .icon": {
        display: "none",
      },
    },
    "& .dz-preview": {
      "& .dz-error-mark": {
        display: "none",
      },
    },
    "& .dz-file-preview": {
      width: "100% !important",
      "&.dz-error": {
        width: "100% !important",
      },
    },
    "&.dz-error": {
      "& .dz-filename": {
        display: "none",
      },
      "& .dz-default.dz-message": {
        display: "block",
      },
      "& .dz-preview ": {
        display: "none",
      },

      "& .dz-message": {
        display: "none",
      },
      "& .dz-success-mark": {
        display: "none",
      },
    },
    "& .dz-processing": {
      position: "relative",
      "& .dz-progress": {
        left: 0,
        top: 0,
        background: "#fff",
        width: "100%",
        height: "100%",
        display: "flex",
        justifyContent: "left",
        position: "absolute",
        alignItems: "center",
        transition: "all 300ms ease-in-out",
        "& .dz-upload": {
          height: "10px",
          display: "block",
          background: "#619c8e",
          borderRadius: "3px;",
        },
      },
      "&.dz-success": {
        "& .dz-success-mark": {
          left: 0,
          display: "flex",
          top: 0,
          background: "#fff",
          width: "100%",
          height: "100%",
          justifyContent: "center",
          position: "absolute",
          alignItems: "center",
          transition: "all 300ms ease-in-out",
          "& svg": {
            background: "#619c8e",
            borderRadius: "33px;",
          },
        },
        "& .dz-image": {
          display: "none",
        },
        "& .dz-error-mark": {
          display: "none",
        },
      },
      "&.dz-error": {
        "& .dz-error-mark": {
          left: 0,
          display: "flex",
          top: 0,
          background: "#fff",
          width: "100%",
          height: "100%",
          justifyContent: "center",
          position: "absolute",
          alignItems: "center",
          transition: "all 300ms ease-in-out",
          "& svg": {
            background: "#a93e3e",
            borderRadius: "33px;",
          },
        },
        "& .dz-image": {
          display: "none",
        },
      },
    },

    "& .dz-processing.dz-success": {
      "& .dz-progress": {
        display: "none !important",
      },
    },
    "&.dz-drag-hover": {
      border: "0.0625rem dashed #67748e!important",
      "& .icon": {
        color: "#67748e !important",
      },
      "& .dz-default": {
        color: "#67748e !important",
      },
    },
    "& .dz-size": {
      display: "none",
    },
    "& .dz-filename": {
      margin: 0,
      overflow: "hidden",
      whiteSpace: "nowrap",
      display: "block",
      opacity: "1",
      color: "#344767",
      fontWeight: "400",
      maxWidth: "100%",
      fontSize: "0.875rem!important",
    },
    "& .dz-default": {
      "&.dz-message": {
        "& .dz-button": {
          background: "none",
          color: "inherit",
          border: "none",
          padding: "0",
          font: "inherit",
          cursor: "pointer",
          outline: "inherit",
        },
      },
    },
  },
});
export default useStyles;
