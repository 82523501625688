import React, { createContext, useState } from "react";
import { Alert, AlertSettings, NotificationColors } from "../types";
import { IconDefinition } from "@fortawesome/fontawesome-svg-core";
import {
  faCircleCheck,
  faCircleInfo,
  faHexagonExclamation,
  faTriangleExclamation,
} from "@fortawesome/pro-solid-svg-icons";

const emptySettings: AlertSettings = {
  title: "",
  content: "",
};

export const alertsContext = createContext<Alert>({
  settings: emptySettings,
  color: "light",
  icon: faCircleInfo,
  dateTime: "",
  isOpen: false,
  notifyInfo: () => {},
  notifySuccess: () => {},
  notifyWarning: () => {},
  notifyError: () => {},
  close: () => {},
});

export const AlertsProvider: (props: React.PropsWithChildren<any>) => React.ReactElement = ({
  children,
}) => {
  const [settings, setSettings] = useState<AlertSettings>(emptySettings);
  const [color, setColor] = useState<NotificationColors>("light");
  const [dateTime, setDateTime] = useState<string>("");
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [icon, setIcon] = useState<IconDefinition>(faCircleInfo);

  const notifyInfo = (newSettings: AlertSettings) => {
    setSettings(newSettings);
    setColor("info");
    setDateTime("");
    setIcon(faCircleInfo);
    setIsOpen(true);
  };
  const notifySuccess = (newSettings: AlertSettings) => {
    setSettings(newSettings);
    setColor("success");
    setDateTime("");
    setIcon(faCircleCheck);
    setIsOpen(true);
  };
  const notifyWarning = (newSettings: AlertSettings) => {
    setSettings(newSettings);
    setColor("warning");
    setDateTime("");
    setIcon(faTriangleExclamation);
    setIsOpen(true);
  };
  const notifyError = (newSettings: AlertSettings) => {
    setSettings(newSettings);
    setColor("error");
    setDateTime("");
    setIcon(faHexagonExclamation);
    setIsOpen(true);
  };
  const close = () => setIsOpen(false);

  const alert: Alert = {
    settings,
    color,
    icon,
    dateTime,
    isOpen,
    notifyInfo,
    notifySuccess,
    notifyWarning,
    notifyError,
    close,
  };

  return <alertsContext.Provider value={alert}>{children}</alertsContext.Provider>;
};
