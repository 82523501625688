import React from "react";
import { Skeleton } from "@mui/material";
import SusUserList from "./SusUserList";
import useCompanySettings from "@hooks/useCompanySettings";

interface ISusUserListProps {
  companyId: number;
  hasEditUserPermission: boolean;
}

const SusCompanyUserList: React.FC<ISusUserListProps> = ({ companyId, hasEditUserPermission }) => {
  const { company, loading, refetch, refetching } = useCompanySettings(companyId);

  const members = company?.members || [];

  return loading && !refetching ? (
    <Skeleton width={200} />
  ) : (
    <SusUserList
      members={members}
      refetchCompany={refetch}
      hasEditUserPermission={hasEditUserPermission}
    />
  );
};

export default SusCompanyUserList;
