import React, { memo, useMemo } from "react";
import { Card, Grid, Tab, Tabs } from "@mui/material";
import useStyles from "./styles";
import { createSearchParams, useSearchParams } from "react-router-dom";
import SusBox from "@components/SusBox";

interface SusTabsBoxProps {
  data: SusTabSpec[];
  actionButtons?: boolean;
}

interface SusTabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

interface SusTabSpec {
  tabTitle: string;
  tabContent: React.ReactElement;
}

interface SusTabProps {
  data: SusTabSpec[];
  currentTab: number;
  handleTabChange?: (event: React.SyntheticEvent, newValue: number, data?: SusTabSpec[]) => void;
  className?: string;
}

const SusTabPanel: React.FC<SusTabPanelProps> = ({ children, value, index, ...other }) => (
  <div
    role="tabpanel"
    hidden={value !== index}
    id={`${index}`}
    aria-labelledby={`${index}`}
    {...other}
  >
    {value === index && (
      <SusBox sx={{ py: 3 }} className={`SusTabPanel`}>
        {children}
      </SusBox>
    )}
  </div>
);

const SusTabNavigation: React.FC<SusTabProps> = ({ currentTab, handleTabChange, data }) => {
  const styles = useStyles();

  return (
    <SusBox className={`${styles.SusTabNavigation}`}>
      <SusBox className={"SusTabNavigation-Wrapper"}>
        <Tabs
          value={currentTab}
          onChange={handleTabChange}
          aria-label={"Tabs Navigation"}
          className="Tabs-root-wrapper"
          variant="scrollable"
          scrollButtons={false}
          allowScrollButtonsMobile
        >
          {data.map((tabInfo: any, key: any) => (
            <Tab
              label={tabInfo.tabTitle}
              className={`tabItem-${tabInfo.tabTitle.replace(/\s/g, "-").toLowerCase()}`}
              key={key}
            />
          ))}
        </Tabs>
      </SusBox>
    </SusBox>
  );
};

const SusTabContent: React.FC<SusTabProps> = memo(({ data, currentTab }) => (
  <SusBox sx={{ width: "100%" }} className="SusTabContent">
    <Grid container spacing={3}>
      <Grid item xs={12}>
        {data.map((tabInfo: any, index: any) => (
          <SusTabPanel value={currentTab} index={index} key={tabInfo.tabTitle}>
            {tabInfo.tabContent}
          </SusTabPanel>
        ))}
      </Grid>
    </Grid>
  </SusBox>
));

const SusTabBox: React.FC<React.PropsWithChildren<SusTabsBoxProps>> = ({ data }) => {
  const [currentTab, setCurrentTab] = React.useState(0);
  const [searchParams, setSearchParams] = useSearchParams();
  React.useEffect(() => {
    if (searchParams.has("tab")) {
      const tabLabels = data.map(({ tabTitle }) => tabTitle);
      const index = tabLabels.indexOf(searchParams.get("tab") || "");
      setCurrentTab(index > 0 ? index : 0);
    }
  }, []);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setCurrentTab(newValue);
    setSearchParams(createSearchParams({ tab: [data[newValue].tabTitle] }));
  };

  const tabNavigation = useMemo(
    () => (
      <>
        {data.length > 1 && (
          <SusTabNavigation data={data} handleTabChange={handleTabChange} currentTab={currentTab} />
        )}
      </>
    ),
    [data, currentTab]
  );
  return (
    <>
      <Card>
        {tabNavigation}
        <SusTabContent data={data} currentTab={currentTab} />
      </Card>
    </>
  );
};

export default SusTabBox;
