import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
  FrameOverlay: {
    display: "flex",
    borderTopLeftRadius: "12px",
    borderTopRightRadius: "12px",
    width: "100%",
    height: "20px",
    position: "absolute",
    background: "#fff !important",
    zIndex: 2,
    top: 0,
  },
  Frame: {
    display: "flex",
    border: "none",
    height: "100%",
    borderRadius: "12px",
  },
  BoxContent: {
    "&::before": {
      //content: "",
      position: "absolute",
      width: "100%",
      height: "100%",
      zIndex: 1,
      background: "rgb(255 255 255 / 62%)",
      left: 0,
      top: 0,
      borderRadius: "15px",
    },
  },
});

export default useStyles;
