// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Snackbar from "@mui/material/Snackbar";
import Fade from "@mui/material/Fade";

// PEK Dashboard components
// Custom styles for the SusSnackbar
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useMediaQuery } from "@mui/material";
import { faXmark } from "@fortawesome/pro-solid-svg-icons";
import { useTheme } from "@mui/material/styles";

import SusBox from "@components/SusBox";
import SusTypography from "@components/SusTypography";
import SusButton from "@components/SusButton";
import typography from "@theme/base/typography";

function SusSnackbar({
  color,
  icon,
  title,
  action,
  content,
  close,
  notDismissable,
  autoHideDuration,
  bgWhite,
  ...rest
}) {
  const { size } = typography;
  let titleColor;

  if (bgWhite) {
    titleColor = color;
  } else if (color === "light") {
    titleColor = "dark";
  } else {
    titleColor = "white";
  }
  const theme = useTheme();
  const fullScreenQuery = useMediaQuery(theme.breakpoints.up("sm"));
  const anchorOrigin = {
    vertical: "bottom",
    horizontal: fullScreenQuery ? "right" : "center",
  };

  return (
    <Snackbar
      TransitionComponent={Fade}
      autoHideDuration={autoHideDuration}
      anchorOrigin={anchorOrigin}
      sx={({ breakpoints }) => ({
        [breakpoints.up("sm")]: {
          width: "auto",
        },
      })}
      {...rest}
    >
      <SusBox
        variant={bgWhite ? "contained" : "gradient"}
        bgColor={bgWhite ? "white" : color}
        maxWidth="100%"
        shadow="md"
        borderRadius="md"
        p={1}
        sx={({ breakpoints }) => ({
          width: "100%",
          [breakpoints.up("sm")]: {
            width: "auto",
          },
        })}
      >
        <SusBox
          display="flex"
          justifyContent="space-between"
          alignItems="stretch"
          color="dark"
          p={1}
          sx={({ breakpoints }) => ({
            flexDirection: "column",
            minWidth: "auto",
            [breakpoints.up("sm")]: {
              minWidth: "320px",
              maxWidth: "500px",
              flexDirection: "row",
            },
          })}
        >
          <SusBox>
            <SusBox display="flex" sx={{ flexDirection: "row" }}>
              <SusTypography variant="subtitle2" fontWeight="medium" color={titleColor} pb={1}>
                <FontAwesomeIcon icon={icon} size="lg" mx="2" /> {title}
              </SusTypography>
              {useMediaQuery(theme.breakpoints.down("sm")) && !notDismissable && (
                <SusButton
                  aria-label="close"
                  variant="text"
                  color="dark"
                  onClick={close}
                  sx={{
                    color: ({ palette: { dark, white } }) =>
                      bgWhite || color === "light" ? dark.main : white.main,
                    textAlign: "right",
                    display: "block",
                    marginLeft: "auto",
                  }}
                >
                  <FontAwesomeIcon icon={faXmark} size="lg" mx="2" />
                </SusButton>
              )}
            </SusBox>
            <SusBox color={bgWhite || color === "light" ? "text" : "white"} fontSize={size.sm}>
              {content}
            </SusBox>
          </SusBox>
          <SusBox display="flex">
            {useMediaQuery(theme.breakpoints.up("sm")) && !notDismissable && (
              <SusButton
                aria-label="close"
                variant="text"
                color="dark"
                onClick={close}
                sx={{
                  color: ({ palette: { dark, white } }) =>
                    bgWhite || color === "light" ? dark.main : white.main,
                  textAlign: "right",
                  display: "block",
                  marginLeft: "auto",
                }}
              >
                <FontAwesomeIcon icon={faXmark} size="lg" mx="2" />
              </SusButton>
            )}

            {action && (
              <SusBox
                alignItems="flex-End"
                display="flex"
                p={1}
                justifyContent="flex-End"
                sx={({ breakpoints }) => ({
                  flexDirection: "row",
                  [breakpoints.up("sm")]: {
                    flexDirection: "column",
                  },
                })}
              >
                {action}
              </SusBox>
            )}
          </SusBox>
        </SusBox>
      </SusBox>
    </Snackbar>
  );
}

// Setting default values for the props of SusSnackbar
SusSnackbar.defaultProps = {
  bgWhite: false,
  color: "info",
};

// Typechecking props for SusSnackbar
SusSnackbar.propTypes = {
  color: PropTypes.oneOf([
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
    "dark",
    "light",
  ]),
  action: PropTypes.object,
  icon: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  content: PropTypes.node.isRequired,
  autoHideDuration: PropTypes.number,
  close: PropTypes.func.isRequired,
  bgWhite: PropTypes.bool,
};

export default SusSnackbar;
