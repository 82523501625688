// react-router-dom components
import { Link } from "react-router-dom";

// prop-types is a library for typechecking of props.
// @mui material components
import { Breadcrumbs as MuiBreadcrumbs } from "@mui/material";

// PEK Dashboard components
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight } from "@fortawesome/pro-solid-svg-icons";
import React from "react";
import { useDashboardState } from "@src/app.state";
import SusBox from "@components/SusBox";
import SusTypography from "@components/SusTypography";

interface IBreadcrumbsProps {
  light?: boolean;
}

const Breadcrumbs: React.FC<React.PropsWithChildren<IBreadcrumbsProps>> = ({ light }) => {
  const { breadcrumbs } = useDashboardState();

  return breadcrumbs?.length ? (
    <SusBox mr={{ xs: 0, xl: 8 }}>
      <MuiBreadcrumbs maxItems={3} separator={<FontAwesomeIcon icon={faAngleRight} />}>
        {breadcrumbs.slice(0, breadcrumbs.length - 1).map((el) => (
          <Link to={el.url || "#"} key={el.url}>
            <SusTypography
              component="span"
              variant="button"
              fontWeight="regular"
              textTransform="capitalize"
              color={light ? "white" : "dark"}
              opacity={light ? 0.8 : 0.5}
            >
              {el.label}
            </SusTypography>
          </Link>
        ))}
        <SusTypography
          variant="button"
          fontWeight="regular"
          textTransform="capitalize"
          color={light ? "white" : "dark"}
          className="active"
        >
          {breadcrumbs[breadcrumbs.length - 1].label}
        </SusTypography>
      </MuiBreadcrumbs>
    </SusBox>
  ) : null;
};

export default Breadcrumbs;
