import React, { useState } from "react";
import { Grid, TableBody, TableCell, TableHead, TableRow, Tooltip, Zoom } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen } from "@fortawesome/pro-solid-svg-icons";
import SusSettingListRoot from "./SusSettingListRoot";
import { CompanyMembership, roleNames } from "@src/types";
import { useDashboardState } from "@src/app.state";
import SusButton from "@components/SusButton";
import SusBox from "@components/SusBox";

interface ISusUserListProps {
  members: Pick<CompanyMembership, "id" | "userId" | "firstName" | "lastName" | "role">[];
  refetchCompany: () => void;
  hasEditUserPermission: boolean;
}

const SusUserList: React.FC<ISusUserListProps> = ({
  members,
  refetchCompany,
  hasEditUserPermission,
}) => {
  const { setCurrentDialog } = useDashboardState();

  const handleClose = () => {
    setCurrentDialog(null);
    refetchCompany();
  };

  const [focussedListRow, setfocussedListRow] = useState<number | null>(null);

  const handleMouseLeave = () => {
    setfocussedListRow(null);
  };

  return (
    <Grid
      container
      sx={{
        flexDirection: "row",
      }}
    >
      <Grid item xs={12}>
        <SusSettingListRoot>
          <TableHead>
            <TableRow>
              <TableCell>Member</TableCell>
              <TableCell align="left" colSpan={3}>
                Role
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {members.map(({ id, userId, firstName, lastName, role }) => (
              <TableRow
                key={id}
                hover
                onMouseEnter={() => setfocussedListRow(id)}
                onMouseLeave={handleMouseLeave}
              >
                <TableCell scope="row">
                  {firstName} {lastName}
                </TableCell>
                <TableCell align="left">{roleNames[role]}</TableCell>
                <TableCell align="right" sx={{ color: "rgb(102 116 142 / 39%)" }}>
                  <SusBox
                    sx={{
                      display: "inline-flex",
                      margin: "0 5px",
                      color: "rgb(102 116 142 / 39%)",
                    }}
                  >
                    {focussedListRow === id && hasEditUserPermission ? (
                      <Tooltip
                        title="edit"
                        placement="top"
                        enterDelay={500}
                        TransitionComponent={Zoom}
                      >
                        <SusButton
                          variant="text"
                          color="dark"
                          onClick={() =>
                            setCurrentDialog("EditUser", {
                              userId,
                              firstName,
                              lastName,
                              onClose: handleClose,
                            })
                          }
                        >
                          <FontAwesomeIcon icon={faPen} />
                        </SusButton>
                      </Tooltip>
                    ) : (
                      <SusBox sx={{ width: "64px", height: "22px" }} />
                    )}
                  </SusBox>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </SusSettingListRoot>
      </Grid>
    </Grid>
  );
};

export default SusUserList;
