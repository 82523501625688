import React from "react";
import { Navigate, useParams } from "react-router-dom";
import useAuth from "@hooks/useAuth";

interface IRequireAuthProps {
  redirect: string;
}

const RequireAuth: React.FC<React.PropsWithChildren<IRequireAuthProps>> = ({
  redirect,
  children,
}) => {
  const { user } = useAuth();
  const params = useParams();
  return user ? <>{children}</> : <Navigate to={redirect} state={params?.location} />;
};

export default RequireAuth;
