import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
  documentContext: {
    height: "50px",
    width: "100%",
    bottom: "-50px",
    transition: "bottom .2s ease-in-out",
    backgroundColor: "#fff",
    position: "absolute",
    visibility: "hidden",

    "&.focussed": {
      bottom: "0",
      visibility: "visible",
    },
  },
  documentItem: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    justifyContent: "center",
    position: "relative",
    color: "#fff",
    border: "1px solid #e5e5e5 !important",
    borderRadius: "0.5rem",
    boxShadow: "0rem 1.25rem 1.6875rem 0rem rgb(34 34 34 / 5%)",
    height: "150px",
    minHeight: "auto",
    width: "100%",
    fontSize: "0.875rem!important",
    padding: "20px 20px",

    "& .icon": {
      height: "1.3rem",
    },
  },
  confirmDeleteId: {
    "& .MuiPopover-paper": {
      background: "#ffffff",
    },
  },
});
export default useStyles;
