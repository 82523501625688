import { Slide } from "@mui/material";
import React from "react";
import useAlerts from "@hooks/useAlerts";
import SusSnackbar from "@components/SusSnackbar/SusSnackbar";

const Alerts: React.FC = () => {
  const { settings, color, icon, dateTime, isOpen, close } = useAlerts();

  const onClose = (event: any, reason: string) => {
    if (reason !== "clickaway") {
      settings?.onClose?.();
      close();
    }
  };

  return (
    <SusSnackbar
      color={color}
      icon={icon}
      title={settings.title}
      content={settings.content}
      action={settings?.action?.(close)}
      dateTime={dateTime}
      autoHideDuration={settings?.autoHideDuration}
      notDismissable={settings?.notDismissable}
      open={isOpen}
      onClose={onClose}
      close={close}
      bgWhite
      TransitionComponent={Slide}
      TransitionProps={{ direction: "left" }}
    />
  );
};

export default Alerts;
