import React, { ChangeEvent, useEffect, useState } from "react";

// react-router-dom components
import { Link as RouterLink, Navigate } from "react-router-dom";

import { Helmet } from "react-helmet-async";
import SignInHero from "@src/assets/images/steel_Hero.jpeg";
import Logo from "@src/assets/images/cremer_steel_logo.svg";
import useAuth from "../hooks/useAuth";
import { paths } from "../paths";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFingerprint } from "@fortawesome/pro-regular-svg-icons";
import {
  Box,
  FormControl,
  FormHelperText,
  IconButton,
  InputAdornment,
  InputLabel,
  Link,
  OutlinedInput,
  Slide,
  Typography,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import useAlerts from "../hooks/useAlerts";
import { DateTime } from "luxon";
import { delay } from "lodash";
import SusBox from "@components/SusBox";
import SusTypography from "@components/SusTypography";
import SusCodeInput from "@components/SusCodeInput/SusCodeInput";
import SusButton from "@components/SusButton";
import Grid from "@mui/material/Unstable_Grid2";
import { faEye, faEyeSlash } from "@fortawesome/pro-solid-svg-icons";
import colors from "@theme/base/colors";

const SignIn: React.FC = () => {
  const { user, login, requestMfaCode } = useAuth();
  const { notifySuccess, notifyWarning } = useAlerts();
  const mfaCodeResendIntervalSecs = 60;

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [mfaCode, setMfaCode] = useState<string | undefined>(undefined);
  const [showMfaInput, setShowMfaInput] = useState(false);

  const [lastResend, setLastResend] = useState<DateTime | undefined>();
  const [mfaCodeResendableInSecs, setMfaCodeResendableInSecs] = useState<number>(0);

  const [submitting, setSubmitting] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const interval = setInterval(() => {
      setMfaCodeResendableInSecs(
        lastResend
          ? Math.ceil(
              mfaCodeResendIntervalSecs - DateTime.now().diff(lastResend, "seconds").seconds
            )
          : 0
      );
    }, 1000);
    return () => clearInterval(interval);
  });

  const handleSignIn = async (e: SubmitEvent) => {
    e.preventDefault();

    if (showMfaInput && (!mfaCode || mfaCode.length < 6)) {
      setError("Please enter the code that we sent to you.");
      return;
    }

    setSubmitting(true);
    const result = await login?.(email, password, mfaCode);
    if (result === "mfa_required") {
      delay(() => {
        if (requestMfaCode?.(email)) {
          setLastResend(DateTime.now());
          setShowMfaInput(true);
        }
        setSubmitting(false);
      }, 2000);
    } else if (result === "mfa_invalid") {
      delay(() => {
        setError("The code you entered is either expired or invalid.");
        setSubmitting(false);
      }, 2000);
    } else if (result === false) {
      delay(() => {
        notifyWarning({
          title: "Log in unsuccessful",
          content: "Please check your account credentials.",
          autoHideDuration: 5000,
        });
        setSubmitting(false);
      }, 2000);
    } else if (result === true) {
      notifySuccess({
        title: "Success",
        content: "You successfully signed in.",
        autoHideDuration: 5000,
      });
      setSubmitting(false);
    }
  };
  const handleChangeUsername = (e: ChangeEvent<{ value: string }>) => {
    setEmail(e.target.value || "");
  };
  const handleChangePassword = (e: ChangeEvent<{ value: string }>) => {
    setPassword(e.target.value);
  };
  const handleChangeMfaCode = (value: string) => {
    setError(null);
    setMfaCode(value);
  };

  if (user) {
    return <Navigate to={paths.home} replace />;
  }

  const resendCode = async () => {
    setSubmitting(true);
    requestMfaCode?.(email).then(() =>
      notifySuccess({
        title: "Verification code sent",
        content: "A code to verify your login has been sent to you via email.",
        autoHideDuration: 5000,
      })
    );
    setLastResend(DateTime.now());
    setSubmitting(false);
  };

  const [showPassword, setShowPassword] = useState(false);

  const handleTogglePasswordVisibility = () => {
    setShowPassword((prev) => !prev);
  };

  const { background } = colors;

  return (
    <>
      <Helmet>
        <title>Sign in | PEK Dashboard</title>
      </Helmet>
      <Grid container spacing={2} py={2} disableEqualOverflow>
        <Grid xs={12} md={4}>
          <Box
            display="flex"
            flexDirection="column"
            height="calc(100vh - 144px)"
            ml={2}
            sx={{
              background: background.sidebar,
              borderRadius: "2rem",
              padding: "1rem",
            }}
          >
            <Box
              sx={{
                marginTop: "0",
              }}
            >
              <SusBox display="flex" flexDirection="row" p={2}>
                <img src={Logo} alt="PEK Dashboard" height="32" style={{ marginRight: "0.5rem" }} />
              </SusBox>
            </Box>
            <Box
              sx={{
                margin: "auto auto auto",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                maxWidth: "400px",
              }}
            >
              <Box
                sx={{
                  marginBottom: "2rem",
                }}
              >
                <SusTypography variant="h4" fontWeight="bold">
                  {!showMfaInput ? (
                    "Customer Dashboard"
                  ) : (
                    <Slide direction="left" in={showMfaInput}>
                      <div>Please check your email</div>
                    </Slide>
                  )}
                </SusTypography>
                <SusTypography variant="body2" fontWeight="regular" color="text">
                  {!showMfaInput ? (
                    <>Welcome Back! Please login to access the dashboard.</>
                  ) : (
                    <Slide direction="left" in={showMfaInput}>
                      <div>
                        We've sent a code to <b>{email}</b>
                      </div>
                    </Slide>
                  )}
                </SusTypography>
              </Box>
              <SusBox
                component="form"
                role="form"
                onSubmit={handleSignIn}
                sx={{
                  width: "100%",
                }}
              >
                <SusBox mb={2}>
                  {!showMfaInput ? (
                    <FormControl fullWidth>
                      <InputLabel>Email</InputLabel>
                      <OutlinedInput
                        type="email"
                        label="Email"
                        autoComplete={"username"}
                        data-matomo-mask
                        onChange={handleChangeUsername}
                      />
                    </FormControl>
                  ) : (
                    <Slide direction="left" in={showMfaInput}>
                      <SusBox>
                        {error && (
                          <SusTypography variant="body2" fontWeight="regular" color="error">
                            {error}
                          </SusTypography>
                        )}
                        <SusBox
                          p={{ xs: 1, md: 2 }}
                          display="flex"
                          flexDirection="row"
                          justifyContent="center"
                        >
                          <SusCodeInput
                            inputMode="numeric"
                            onChange={handleChangeMfaCode}
                            isValid={!error}
                          />
                        </SusBox>
                      </SusBox>
                    </Slide>
                  )}
                </SusBox>
                <Box
                  mb={2}
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "100%",
                  }}
                >
                  {!showMfaInput ? (
                    <>
                      <FormHelperText
                        id="password-helper-text"
                        sx={{
                          margin: "0 0 0.5rem auto",
                        }}
                      >
                        <Link component={RouterLink} to="/reset-password" color="inherit">
                          Forgot your password?
                        </Link>
                      </FormHelperText>
                      <FormControl fullWidth>
                        <InputLabel>Password</InputLabel>
                        <OutlinedInput
                          type={showPassword ? "text" : "password"}
                          aria-describedby="password"
                          label="Password"
                          autoComplete={"current-password"}
                          data-matomo-mask
                          onChange={handleChangePassword}
                          endAdornment={
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleTogglePasswordVisibility}
                                edge="end"
                              >
                                {showPassword ? (
                                  <FontAwesomeIcon icon={faEye} fixedWidth size={"xs"} />
                                ) : (
                                  <FontAwesomeIcon icon={faEyeSlash} fixedWidth size={"xs"} />
                                )}
                              </IconButton>
                            </InputAdornment>
                          }
                        />
                      </FormControl>
                    </>
                  ) : (
                    <Slide direction="left" in={showMfaInput}>
                      <div>
                        {mfaCodeResendableInSecs <= 0 ? (
                          <SusTypography fontWeight="regular" variant="button">
                            Didn't get a code?{" "}
                            <SusTypography
                              onClick={resendCode}
                              variant="button"
                              fontWeight="regular"
                              sx={{ textDecoration: "underline", cursor: "pointer" }}
                            >
                              Click to resend.
                            </SusTypography>
                          </SusTypography>
                        ) : (
                          <SusTypography fontWeight="regular" variant="button">
                            Didn't receive a code? <br />
                            You can request a new one in {mfaCodeResendableInSecs} seconds.
                          </SusTypography>
                        )}
                      </div>
                    </Slide>
                  )}
                </Box>
                <SusBox mt={4} mb={1}>
                  {!showMfaInput ? (
                    <>
                      <LoadingButton
                        color="primary"
                        size="large"
                        variant="contained"
                        fullWidth
                        type="submit"
                        loading={submitting}
                        disabled={submitting}
                      >
                        Sign in
                      </LoadingButton>
                    </>
                  ) : (
                    <Slide direction="left" in={showMfaInput}>
                      <div>
                        <LoadingButton
                          color="primary"
                          size="large"
                          variant="contained"
                          fullWidth
                          type="submit"
                          disabled={submitting}
                          loading={submitting}
                        >
                          <SusBox mr="0.5rem" color="white" display="inlineFlex">
                            <FontAwesomeIcon icon={faFingerprint} size="xs" />
                          </SusBox>
                          Verify & Log in
                        </LoadingButton>
                        <SusButton
                          color="dark"
                          size="large"
                          variant="outlined"
                          fullWidth
                          onClick={() => {
                            setMfaCode(undefined);
                            setShowMfaInput(false);
                          }}
                          sx={{ marginTop: 1 }}
                        >
                          back
                        </SusButton>
                      </div>
                    </Slide>
                  )}
                </SusBox>
              </SusBox>
            </Box>
          </Box>
          <Box
            display="flex"
            flexDirection="column"
            ml={2}
            sx={{
              borderRadius: "2rem",
              padding: "1rem",
            }}
          >
            <Box
              sx={{
                margin: "auto auto auto",
                maxWidth: "400px",
              }}
            >
              <SusTypography variant="button" color="text" fontWeight="regular">
                <SusButton
                  href="https://pektogram.com/imprint/"
                  target="_blank"
                  variant="text"
                  fontWeight="medium"
                  color="text"
                >
                  Legal notice
                </SusButton>
                <SusButton
                  href="https://pektogram.com/privacy-policy/"
                  target="_blank"
                  variant="text"
                  fontWeight="medium"
                  color="text"
                >
                  Privacy policy
                </SusButton>
              </SusTypography>
            </Box>
            <Box
              sx={{
                margin: "auto auto auto",
                maxWidth: "400px",
              }}
            >
              <SusTypography variant="button" color="text" fontWeight="regular">
                <SusButton
                  href="https://pektogram.com/"
                  target="_blank"
                  variant="text"
                  fontWeight="medium"
                  color="text"
                >
                  © powered by pektogram
                </SusButton>
              </SusTypography>
            </Box>
          </Box>
        </Grid>
        <Grid xs={12} md={8}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              justifyContent: "end",
              width: "100%",
              height: "calc(100vh - 32px)",
              minHeight: "150px",
              borderRadius: "2rem 0 0 2rem",
              background: `URL(${SignInHero}) no-repeat center center / cover`,
              position: "relative",
              zIndex: 99,
            }}
          >
            <Slide direction="right" in={true} style={{ transitionDelay: "1400ms" }}>
              <Box
                sx={{
                  margin: "1rem",
                  borderRadius: "1.5rem",
                  background:
                    "linear-gradient(to right, rgb(242 247 255 / 60%) 0%, rgb(255 255 255 / 80%) 60%)",
                  border: "1px solid #fff",
                  backdropFilter: "blur(11px)",
                  color: "#2C3C4C",
                  padding: "1.5rem",
                  maxWidth: "600px",
                }}
              >
                <Typography>Dashboard News</Typography>
                <Typography>
                  The dashboard has received a small design update.
                  <br /> Please send us your feedback
                </Typography>
              </Box>
            </Slide>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default SignIn;
