import * as React from "react";
import { Box, styled, Tooltip, Zoom } from "@mui/material";
import { round } from "lodash";

const Root = styled(Box)(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  position: "relative",
  overflow: "hidden",
  width: "100%",
  height: 26,
  borderRadius: "0.5rem",
}));

const Value = styled(Box)(({ theme }) => ({
  position: "absolute",
  lineHeight: "24px",
  fontSize: "1rem",
  width: "100%",
  display: "flex",
  justifyContent: "center",
  color: theme.palette.text.primary,
}));

const Bar = styled(Box)(() => ({
  height: "100%",
  "&.low": {
    backgroundColor: "rgba(239,100,97,0.7)",
  },
  "&.medium": {
    backgroundColor: "rgba(242,192,87,0.7)",
  },
  "&.high": {
    backgroundColor: "rgba(7,164,103,0.7)",
  },
}));

interface ProgressBarProps {
  title?: string;
  value: number;
}

const ProgressBar: React.FC<ProgressBarProps> = React.memo(({ title, value }) => {
  const precision = value >= 0.1 ? 0 : 1;
  const valueInPercent = value < 0.0 ? 0.0 : round(value * 100.0, precision);

  const barClass = React.useMemo(() => {
    if (valueInPercent < 30) return "low";
    if (valueInPercent <= 70) return "medium";
    return "high";
  }, [valueInPercent]);

  return (
    <Tooltip title={title} TransitionComponent={Zoom}>
      <Root>
        <Value>{`${valueInPercent.toLocaleString()} %`}</Value>
        <Bar className={barClass} sx={{ maxWidth: `${valueInPercent}%` }} />
      </Root>
    </Tooltip>
  );
});

interface LinearProgressbarProps {
  title?: string;
  // value is a string or number, but we want to pass it as a percentage in decimal to ProgressBar
  value: string | number;
}

export const LinearProgressbar: React.FC<LinearProgressbarProps> = ({ value, title }) => {
  return <ProgressBar value={Number(value)} title={title} />;
};
