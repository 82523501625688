import "chart.js/auto";
import { Card, IconButton, Skeleton, Tooltip, Zoom } from "@mui/material";
import Grid from "@mui/material/Grid";

import { LayoutPosition } from "chart.js";
import React, { useEffect, useMemo, useRef, useState } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/pro-regular-svg-icons";

import useStyles from "../styles";
import { Bar } from "react-chartjs-2";

import { decamelize } from "humps";
import { FilterSpec, ObjectClass, ObjectField } from "@src/types";
import { generateApiPath } from "@src/utils/helpers";
import { apiPaths } from "@src/paths";
import { serializeFieldName } from "@src/utils/typing";
import useApiData from "@hooks/useApiData";
import { generateColorsByLetter } from "@components/widgets/Charts/colorhelpers";
import { colorMap, paletteChart } from "@components/widgets/Charts/defaults";
import SusBox from "@components/SusBox";
import SusTypography from "@components/SusTypography";

export interface ISimpleAggregationChartProps<T extends ObjectClass> {
  title: string;
  objectClass: T;
  onRemove?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  filter?: FilterSpec;
  groupByField: ObjectField<T>;
  aggregationField: ObjectField<T>;
  maxCount?: number;
  globalLoading?: boolean;
  nullLabel?: string;
}

const SimpleAggregationChart = <T extends ObjectClass>({
  title,
  objectClass,
  onRemove,
  filter,
  groupByField,
  aggregationField,
  maxCount,
  globalLoading,
  nullLabel,
}: ISimpleAggregationChartProps<T>) => {
  const [apiUrl, setApiUrl] = useState<string>("");
  useEffect(() => {
    if (!objectClass || !filter?.field || !filter?.value || !aggregationField || !groupByField)
      return;
    setApiUrl(
      generateApiPath(apiPaths.aggregate, {
        objectClass: decamelize(objectClass),
        filterField: serializeFieldName(objectClass, filter?.field),
        filterValue: filter?.value,
        sumField: serializeFieldName(objectClass, aggregationField),
        groupByField: serializeFieldName(objectClass, groupByField),
      })
    );
  }, [objectClass, filter, aggregationField, groupByField]);
  const { data: rawData, loading } = useApiData(apiUrl, "get", {
    trigger: "ONCE",
    companyQuery: { enabled: true, required: true },
  });

  const aggregatedData = useMemo(() => {
    const data = new Map<string, number>(
      (typeof rawData?.map === "function" &&
        rawData.map((entry: { key: string; value: number }) => [
          entry.key || nullLabel || "unknown",
          entry.value,
        ])) ||
        []
    );

    // Summarize entries that exceed the <maxCount> largest entries under the label "Others"
    if (maxCount && data.size > maxCount) {
      const otherValue = Array.from(data.entries())
        .slice(maxCount - 1)
        .map(([key, value]) => {
          data.delete(key);
          return value;
        })
        .reduce((prev, curr) => prev + curr, 0);
      data.set("Others", otherValue);
    }

    const colors = generateColorsByLetter([...data.keys()], colorMap, paletteChart);

    return {
      labels: [...data.keys()],
      datasets: [
        {
          data: [...data.values()],
          backgroundColor: colors.map((color) => `${color}100`),
          borderColor: colors,
          borderWidth: 1,
          borderRadius: { topLeft: 8, topRight: 8 },
          hoverBackgroundColor: colors.map((color) => `${color}95`),
        },
      ],
    };
  }, [rawData]);

  const options = {
    plugins: {
      legend: {
        display: false,
        position: "bottom" as LayoutPosition,
      },
      dataLabels: {
        font: {
          weight: "bold" as any,
        },
        maintainAspectRatio: false,
        responsive: false,
      },
    },
  };

  const boxRef = useRef<HTMLDivElement | null>(null);
  const [chartSize, setChartSize] = useState({ width: 246, height: 246 });

  useEffect(() => {
    const updateChartSize = () => {
      if (boxRef.current) {
        setChartSize({
          width: boxRef.current.clientWidth,
          height: boxRef.current.clientHeight,
        });
      }
    };

    updateChartSize();

    window.addEventListener("resize", updateChartSize);

    return () => {
      window.removeEventListener("resize", updateChartSize);
    };
  }, []);

  const classes = useStyles();
  return (
    <Card sx={{ width: "100%", background: "#ffffff", height: "100%" }}>
      <SusBox
        display="flex"
        justifyContent="space-between"
        flexDirection="row"
        alignItems="center"
        pt={2}
        px={2}
      >
        <SusTypography variant="h6" fontWeight="medium" textTransform="uppercase">
          {loading || globalLoading ? <Skeleton width={190} /> : title}
        </SusTypography>
        {!(loading || globalLoading) && onRemove && (
          <Tooltip title="Remove Widget" placement="bottom" TransitionComponent={Zoom}>
            <IconButton
              onClick={onRemove}
              sx={{
                "&:hover": {
                  "& svg": {
                    color: "#EF6461",
                  },
                },
              }}
            >
              <FontAwesomeIcon icon={faXmark} size="xs" />
            </IconButton>
          </Tooltip>
        )}
      </SusBox>
      <Grid item p={2} sx={{ height: "calc(100% - 62px)" }}>
        {loading || globalLoading ? (
          <div className={classes.barChartWrapper}>
            <div className={classes.barChart}>
              <div className={classes.bar}>
                <Skeleton variant="rectangular" height={50} />
              </div>
              <div className={classes.bar}>
                <Skeleton variant="rectangular" height={140} />
              </div>
              <div className={classes.bar}>
                <Skeleton variant="rectangular" height={110} />
              </div>
              <div className={classes.bar}>
                <Skeleton variant="rectangular" height={150} />
              </div>
            </div>
            <div className={classes.barChart}>
              <div className={classes.barLabel}>
                <Skeleton variant="text" />
              </div>
            </div>
          </div>
        ) : aggregatedData.labels.length > 0 ? (
          <SusBox
            ref={boxRef}
            sx={{
              width: "100%",
              height: "100%",
              minWidth: 150,
              minHeight: 150,
            }}
          >
            <Bar
              datasetIdKey={title.replaceAll(" ", "-")}
              data={aggregatedData}
              options={options}
              width={chartSize.width}
              height={chartSize.height}
              className={classes.chart}
            />
          </SusBox>
        ) : (
          "No data available"
        )}
      </Grid>
    </Card>
  );
};

export default SimpleAggregationChart;
