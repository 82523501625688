import React, { ChangeEvent, useState } from "react";

// react-router-dom components
import { Link, Navigate, useLocation, useNavigate } from "react-router-dom";

import { Box, FormControl, InputLabel, OutlinedInput } from "@mui/material";
import { Helmet } from "react-helmet-async";
import SignInHero from "@src/assets/images/steel_Hero.jpeg";

import Grid from "@mui/material/Unstable_Grid2";
import useAuth from "@hooks/useAuth";
import useAlerts from "@hooks/useAlerts";
import { paths } from "@src/paths";
import SusBox from "@components/SusBox";
import SusButton from "@components/SusButton";
import SusTypography from "@components/SusTypography";
import Logo from "@src/assets/images/cremer_steel_logo.svg";

type ResetPasswordProps = {
  image?: string;
  title?: string;
  description?: string;
};

const ResetPassword: React.FC<ResetPasswordProps> = () => {
  const { user, resetPassword } = useAuth();
  const [username, setUsername] = useState("");
  const navigate = useNavigate();
  const { state } = useLocation();
  const { notifySuccess, notifyWarning } = useAlerts();

  const handleSubmit = async (e: SubmitEvent) => {
    e.preventDefault();

    const success = await resetPassword?.(username);
    if (success) {
      notifySuccess({
        title: "Success",
        content: "You will receive an e-mail with a password reset link shortly.",
        autoHideDuration: 5000,
      });
      navigate(state?.from || paths.home);
    } else {
      notifyWarning({
        title: "Reset unsuccessful",
        content: "Please check the username you entered.",
        autoHideDuration: 5000,
      });
    }
  };
  const handleChangeUsername = (e: ChangeEvent<HTMLInputElement>) => {
    setUsername(e.target.value);
  };

  if (user) {
    return <Navigate to={paths.home} replace />;
  }

  return (
    <>
      <Helmet>
        <title>Reset password | PEK Dashboard</title>
      </Helmet>
      <Grid container spacing={2} py={2} disableEqualOverflow>
        <Grid xs={12} md={4}>
          <Box
            display="flex"
            flexDirection="column"
            height="calc(100vh - 135px)"
            ml={2}
            sx={{
              background: "#FBFBFB",
              borderRadius: "2rem",
              padding: "1rem",
            }}
          >
            <Box
              sx={{
                marginTop: "0",
              }}
            >
              <SusBox display="flex" flexDirection="row" p={2}>
                <img src={Logo} alt="PEK Dashboard" height="32" style={{ marginRight: "0.5rem" }} />
              </SusBox>
            </Box>
            <Box
              sx={{
                margin: "auto auto auto",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                maxWidth: "400px",
              }}
            >
              <Box
                sx={{
                  marginBottom: "2rem",
                }}
              >
                <SusTypography variant="h4" fontWeight="bold">
                  Reset password
                </SusTypography>
                <SusTypography variant="body2" fontWeight="regular" color="text">
                  You will receive an e-mail in maximum 60 seconds
                </SusTypography>
              </Box>

              <SusBox
                component="form"
                role="form"
                onSubmit={handleSubmit}
                sx={{
                  width: "100%",
                }}
              >
                <SusBox mb={2}>
                  <FormControl fullWidth>
                    <InputLabel>Email</InputLabel>
                    <OutlinedInput type="email" label="Email" onChange={handleChangeUsername} />
                  </FormControl>
                </SusBox>
                <SusBox mt={4} mb={1}>
                  <SusButton color="primary" size="large" fullWidth type="submit">
                    Reset password
                  </SusButton>
                </SusBox>
                <Box mt={3} textAlign="center">
                  <SusTypography variant="button" color="text" fontWeight="regular">
                    back to{" "}
                    <SusTypography
                      component={Link}
                      to="/sign-in/"
                      variant="button"
                      fontWeight="medium"
                    >
                      Sign in
                    </SusTypography>
                  </SusTypography>
                </Box>
              </SusBox>
            </Box>
          </Box>
          <Box
            display="flex"
            flexDirection="column"
            ml={2}
            sx={{
              borderRadius: "2rem",
              padding: "1rem",
            }}
          >
            <Box
              sx={{
                margin: "auto auto auto",
                maxWidth: "400px",
              }}
            >
              <SusTypography variant="button" color="text" fontWeight="regular">
                <SusButton
                  href="https://pektogram.com/imprint/"
                  target="_blank"
                  variant="text"
                  fontWeight="medium"
                  color="text"
                >
                  Legal notice
                </SusButton>
                <SusButton
                  href="https://pektogram.com/privacy-policy/"
                  target="_blank"
                  variant="text"
                  fontWeight="medium"
                  color="text"
                >
                  Privacy policy
                </SusButton>
              </SusTypography>
            </Box>
            <Box
              sx={{
                margin: "auto auto auto",
                maxWidth: "400px",
              }}
            >
              <SusTypography variant="button" color="text" fontWeight="regular">
                <SusButton
                  href="https://pektogram.com/"
                  target="_blank"
                  variant="text"
                  fontWeight="medium"
                  color="text"
                >
                  © powered by pektogram
                </SusButton>
              </SusTypography>
            </Box>
          </Box>
        </Grid>
        <Grid xs={12} md={8}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              justifyContent: "end",
              width: "100%",
              height: "calc(100vh - 32px)",
              minHeight: "150px",
              borderRadius: "2rem 0 0 2rem",
              background: `URL(${SignInHero}) no-repeat center center / cover`,
              position: "relative",
              zIndex: 99,
            }}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default ResetPassword;
