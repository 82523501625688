import { WithCompanies } from "../types";
import { useDashboardState } from "../app.state";

export function trycatch<T>(func: () => T, fail: T) {
  try {
    return func();
  } catch (e) {
    return fail;
  }
}

export function useCurrentCompanyDistributorOrPurchaser(obj?: WithCompanies) {
  const currentCompany = useDashboardState((state) => state.currentCompany);
  const attrName = currentCompany?.companyIsDistributor ? "distributor" : "purchaser";
  return obj?.[attrName];
}

export function composeOneLineAddress(
  street?: string | null,
  zipCode?: string | null,
  city?: string | null,
  country?: string | null
) {
  return [street, [zipCode, city].filter((elem) => !!elem).join(" "), country]
    .filter((elem) => !!elem)
    .join(", ");
}

/**
 * Check if the given element or any of its parents matches the given css selector.
 * @param element
 * @param selector
 */
export const anyParentMatchesSelector = (element: HTMLElement, selector: string) => {
  while (element) {
    if (element.matches(selector)) {
      return true;
    }
    element = element.parentNode as HTMLElement;
  }
  return false;
};

/**
 * Taken from react-router (https://github.com/remix-run/react-router/blob/edf1dfc3327f721045ed672870b4f99825aa8fb8/packages/router/utils.ts#L298)
 *
 * Examples:
 * "/a/b/*" -> "*"
 * ":a" -> "a"
 * "/a/:b" -> "b"
 * "/a/blahblahblah:b" -> "b"
 * "/:a/:b" -> "a" | "b"
 * "/:a/b/:c/*" -> "a" | "c" | "*"
 */
type PathParam<Path extends string> =
  // check if path is just a wildcard
  Path extends "*" | "/*"
    ? "*"
    : // look for wildcard at the end of the path
      Path extends `${infer Rest}/*`
      ? "*" | _PathParam<Rest>
      : // look for params in the absence of wildcards
        _PathParam<Path>;

/**
 * Recursive helper for finding path parameters in the absence of wildcards
 * Taken from react-router (https://github.com/remix-run/react-router/blob/edf1dfc3327f721045ed672870b4f99825aa8fb8/packages/router/utils.ts#L277)
 */
type _PathParam<Path extends string> =
  // split path into individual path segments
  Path extends `${infer L}/${infer R}`
    ? _PathParam<L> | _PathParam<R>
    : // find params after `:`
      Path extends `:${infer Param}`
      ? Param extends `${infer Optional}?`
        ? Optional
        : Param
      : // otherwise, there aren't any params present
        never;

/**
 * Returns a path with params interpolated.
 * Taken from react-router (https://github.com/remix-run/react-router/blob/edf1dfc3327f721045ed672870b4f99825aa8fb8/packages/router/utils.ts#L713)
 * and modified to work with django.
 * @param originalPath
 * @param params
 */
export function generateApiPath<Path extends string>(
  originalPath: Path,
  params: {
    [key in PathParam<Path>]?: string | null;
  } = {} as any
): string {
  let path: string = originalPath;
  if (path.endsWith("*") && path !== "*" && !path.endsWith("/*")) {
    const msg =
      `Route path '${path}' will be treated as if it were ` +
      `'${path.replace(/\*$/, "/*")}' because the \`*\` character must ` +
      `always follow a \`/\` in the pattern. To get rid of this warning, ` +
      `please change the route path to '${path.replace(/\*$/, "/*")}'.`;
    if (typeof console !== "undefined") {
      console.warn(msg);
    } else {
      throw new Error(msg);
    }
    path = path.replace(/\*$/, "/*") as Path;
  }

  // ensure `/` is added at the beginning if the path is absolute
  const prefix = path.startsWith("/") ? "/" : "";

  const segments = path
    .split(/\/+/)
    .map((segment, index, array) => {
      const isLastSegment = index === array.length - 1;

      // only apply the splat if it's the last segment
      if (isLastSegment && segment === "*") {
        const star = "*" as PathParam<Path>;
        const starParam = params[star];

        // Apply the splat
        return starParam;
      }

      const keyMatch = segment.match(/^:(\w+)(\??)$/);
      if (keyMatch) {
        const [, key, optional] = keyMatch;
        const param = params[key as PathParam<Path>];

        if (optional === "?") {
          return param == null ? "" : param;
        }

        if (param == null) {
          throw new Error(`Missing ":${key}" param`);
        }

        return param;
      }

      // Remove any optional markers from optional static segments
      return segment.replace(/\?$/g, "");
    })
    // Remove empty segments
    .filter(
      (segment, index, array) => !!segment || (index === array.length - 1 && path.endsWith("/"))
    );

  return prefix + segments.join("/");
}
