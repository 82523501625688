import { makeStyles } from "@mui/styles";
import Theme from "@theme/index";

const { palette } = Theme;
const { grey } = palette;

const useStyles = makeStyles({
  document: {
    transition: "bottom .2s ease-in-out",
    background: grey[500],

    "& .react-pdf__Document": {
      background: "transparent",
    },
    "& .react-pdf__Page": {
      border: "1px solid" && grey[600],
      margin: "20px 0",
      width: "100%",
      justifyContent: "center",
      "&:first-of-type": {
        margin: "0 0 20px",
      },

      "& .react-pdf__Page__canvas": {
        // height: "auto !important",
        // width: "100% !important",
        // padding: "1rem",
        margin: "0 auto",
      },
    },
  },
});
export default useStyles;
