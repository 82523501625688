import viewConfigEU from "./eu";
import viewConfigUS from "./us";
import viewConfigNone from "./none";
import { ViewSettings } from "@src/types";

export const availableViewConfigKeys = ["EU", "US", "None"] as const;
export type ViewConfigKey = (typeof availableViewConfigKeys)[number];

export type AvailableViewSettings = Record<ViewConfigKey, ViewSettings>;

const availableViewSettings: AvailableViewSettings = {
  EU: viewConfigEU,
  US: viewConfigUS,
  None: viewConfigNone,
};

export default availableViewSettings;
