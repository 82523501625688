import React, { useState } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Chip,
  Dialog,
  FormControlLabel,
  Switch,
  Tab,
  Tabs,
  Theme,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { styled } from "@mui/material/styles";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight } from "@fortawesome/pro-regular-svg-icons";

import Cookies from "js-cookie";

import { ServiceTypeDescription } from "./types";
import { SusDialogStyles } from "@components/SusDialog/styles";
import { useDashboardState } from "@src/app.state";
import useAuth from "@hooks/useAuth";
import { ANALYTICS_SERVICE_KEY, SERVICE_CONSENT_COOKIE_NAME } from "@src/constants";
import { loadTagManager, unloadTagManager } from "@src/features/tracking";
import SusBox from "@components/SusBox";
import SusTypography from "@components/SusTypography";
import SusButton from "@components/SusButton";

const StyledCookieBanner = styled("div")(SusDialogStyles);

const serviceTypes: ServiceTypeDescription[] = [
  {
    title: "Essential",
    key: "essential",
    description: "These tags are required for the basic functions of the website.",
    isOptional: false,
    services: [
      {
        title: "Dashboard Functions",
        description:
          "The functional cookies are used, for example, to ensure that you remain logged in when you call up the dashboard again.",
        cookies: [
          {
            name: "csrftoken",
          },
          { name: "sessionid" },
        ],
      },
      {
        title: "Privacy Settings Agreement",
        description: "The functional cookies are used to save your privacy settings.",
        cookies: [
          {
            name: "serviceConsent",
          },
        ],
      },
    ],
  },
  {
    title: "Functional",
    key: ANALYTICS_SERVICE_KEY,
    description:
      "These tags enable us to analyze website usage so that we can measure and improve its performance.",
    isOptional: true,
    services: [
      {
        title: "Web Analytics - by pektogram",
        description:
          "This is an open source web analytics service. Matomo is providing the technology. However, Matomo is not processing any data as the data is not being transferred to Matomo (Self hosting).",
        cookies: [
          { name: "_pk_ses.**" },
          { name: "_pk_id.**" },
          { name: "_pk_hsr.**" },
          { name: "_pk_cvar.**" },
          { name: "_pk_ref.**" },
          { name: "MATOMO_SESSID" },
        ],
      },
    ],
  },
];

const ServiceConsentDialog = () => {
  const { setCurrentDialog, currentDialog } = useDashboardState();
  const { user } = useAuth();
  const [selectedTab, setSelectedTab] = useState(0);

  function setAllOptionalServices(accepted: boolean) {
    const initialServiceConfig: Record<string, boolean> = {};
    for (const serviceType of serviceTypes) {
      initialServiceConfig[serviceType.key] = serviceType.isOptional ? accepted : true;
    }
    return initialServiceConfig;
  }

  const hashId = crypto.randomUUID();
  const [serviceConfig, setServiceConfig] = useState<Record<string, boolean>>(() => {
    const services = Cookies.get(SERVICE_CONSENT_COOKIE_NAME);
    if (services) {
      const [, servicesString] = services.split("|");
      return JSON.parse(servicesString);
    } else {
      return setAllOptionalServices(true);
    }
  });

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) =>
    setSelectedTab(newValue);

  const handleServiceConfigChange = (event: React.ChangeEvent<HTMLInputElement>) =>
    setServiceConfig({ ...serviceConfig, [event.target.name]: event.target.checked });

  const handleAcceptServices = () => saveServiceConfigCookie(setAllOptionalServices(true));
  const handleDenyServices = () => saveServiceConfigCookie(setAllOptionalServices(false));
  const handleAcceptSelectionServices = () => saveServiceConfigCookie(serviceConfig);

  const saveServiceConfigCookie = (serviceConfig: Record<string, boolean>) => {
    const servicesString = JSON.stringify(serviceConfig);
    Cookies.set(SERVICE_CONSENT_COOKIE_NAME, `${hashId}|${servicesString}`, { expires: 365 });

    if (serviceConfig[ANALYTICS_SERVICE_KEY]) {
      loadTagManager();
    } else {
      unloadTagManager();
    }

    // If this is the first login of the current user, switch to the setup dialog
    setCurrentDialog(user && !user?.firstLoginAt ? "SetupDialog" : null);
  };

  const handleChangeServices = () => {
    setSelectedTab(1);
  };

  function ServicesAccordion() {
    return (
      <SusBox
        sx={{
          ".MuiAccordion-root": {
            boxShadow: "none",
          },
          ".MuiAccordionSummary-expandIconWrapper": {
            order: "-1",
            "&.Mui-expanded": {
              transform: "rotate(90deg)",
            },
          },

          ".MuiAccordionSummary-content": {
            display: "flex",
            justifyContent: "space-between",
            paddingLeft: "15px",
          },
        }}
      >
        {serviceTypes.map((serviceType) => (
          <Accordion>
            <AccordionSummary
              expandIcon={<FontAwesomeIcon color={"dark"} icon={faAngleRight} size="xs" />}
            >
              <SusBox>
                <SusTypography variant="h5" color="text">
                  {serviceType.title}
                </SusTypography>

                <SusTypography variant="body2" fontWeight="regular" color="text">
                  {serviceType.description}
                </SusTypography>
              </SusBox>
              <FormControlLabel
                labelPlacement="start"
                label={""}
                control={
                  <Switch
                    checked={serviceType.isOptional ? serviceConfig[serviceType.key] : true}
                    onChange={serviceType.isOptional ? handleServiceConfigChange : () => null}
                    name={serviceType.key}
                    disabled={!serviceType.isOptional}
                  />
                }
              />
            </AccordionSummary>
            <AccordionDetails
              sx={{
                ".service": {
                  border: "1px solid rgba(34, 34, 34, 0.2)",
                  "&.Mui-expanded": {
                    margin: "0",
                  },
                  "&:not(:last-child)": {
                    borderBottom: "none",
                  },
                },
              }}
            >
              {serviceType.services.map((service) => (
                <Accordion className="service">
                  <AccordionSummary
                    expandIcon={<FontAwesomeIcon color={"dark"} icon={faAngleRight} size="xs" />}
                  >
                    <SusTypography>{service.title}</SusTypography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <SusTypography variant="body2" fontWeight="regular" color="text">
                      {service.description}
                    </SusTypography>
                    <SusBox mt={2}>
                      <SusTypography variant="body2" fontWeight="regular" color="text">
                        List of cookies:
                      </SusTypography>
                      <SusBox mt={2} sx={{ display: "flex", flexWrap: "wrap", gap: "5px" }}>
                        {service.cookies.map((cookie) => (
                          <Chip label={cookie.name} variant="outlined" />
                        ))}
                      </SusBox>
                    </SusBox>
                  </AccordionDetails>
                </Accordion>
              ))}
            </AccordionDetails>
          </Accordion>
        ))}
      </SusBox>
    );
  }

  const theme = useTheme();
  const fullScreenQuery = useMediaQuery(theme.breakpoints.down("lg"));
  return (
    <Dialog
      fullScreen={fullScreenQuery}
      open={currentDialog.type === "ServiceConsentDialog"}
      scroll="body"
      disableEscapeKeyDown
      sx={
        SusDialogStyles && {
          "& .MuiDialog-container": {
            alignItems: "center",
          },
          "& .MuiDialog-paper": {
            maxWidth: "100%",
            width: "782px",
          },
        }
      }
    >
      <StyledCookieBanner>
        <SusBox
          sx={{
            display: "block",
            overflowY: "auto",
            maxHeight: "65vh",
          }}
        >
          <SusBox sx={{ margin: "1rem", textAlign: "center" }}>
            <SusTypography variant="h5" color="text" my={2}>
              Privacy settings
            </SusTypography>
          </SusBox>

          <Tabs
            value={selectedTab}
            onChange={handleTabChange}
            variant="fullWidth"
            textColor="primary"
            indicatorColor="primary"
            sx={{ borderBottom: 1, borderColor: "divider", margin: "1rem" }}
          >
            <Tab label="Consent" />
            <Tab label="Details" />
            <Tab label="About services" />
          </Tabs>
          <SusBox
            sx={{
              padding: "1rem",
            }}
          >
            {selectedTab === 0 && (
              <>
                <SusTypography variant="body2" fontWeight="regular" color="text">
                  We use services and cookies to personalise content and analyse traffic on our
                  website.
                  <br />
                  You can find more information about this under details.
                </SusTypography>
              </>
            )}
            {selectedTab === 1 && <ServicesAccordion />}
            {selectedTab === 2 && (
              <>
                <SusTypography variant="body2" fontWeight="regular" color="text" mb={0.5}>
                  We use services and cookies to ensure and further optimize the functionality of
                  the Dashboard.
                </SusTypography>
                <SusTypography variant="body2" fontWeight="regular" color="text" mb={0.5}>
                  This also involves the use of so-called cookies, which are small text files used
                  by websites to improve the user experience.
                </SusTypography>
                <SusTypography variant="body2" fontWeight="regular" color="text" mb={0.5}>
                  The law allows us to store cookies on your device if they are strictly necessary
                  for the operation of this website. For all other types of cookies, we need your
                  consent. This means that cookies that are classified as necessary are permitted on
                  the basis of Art. 6 (1) lit. f DSGVO. All other cookies that are not necessary,
                  i.e. cookies categorized as preferences or functional, are used on the basis of
                  Art. 6 (1) a DSGVO.
                </SusTypography>
                <SusTypography variant="body2" fontWeight="regular" color="text" mb={0.5}>
                  This website uses different types of cookies. Some cookies are set by third
                  parties that appear on our pages.
                </SusTypography>
                <SusTypography variant="body2" fontWeight="regular" color="text" mb={0.5}>
                  In our privacy policy you can learn more about who we are, how you can contact us
                  and how we process personal data. Please provide your consent ID and date when you
                  contact us regarding your consent.
                </SusTypography>
              </>
            )}
          </SusBox>
        </SusBox>
        <SusBox
          sx={({ breakpoints }: Theme) => ({
            padding: "1rem",
            borderTop: 1,
            borderColor: "divider",
            display: "flex",
            justifyContent: "space-between",
            overflowY: "auto",
            maxHeight: "35vh",
            [breakpoints.down("sm")]: {
              flexDirection: "column",
              gap: "10px",
            },
          })}
        >
          <SusButton variant="outlined" color="primary" size="large" onClick={handleDenyServices}>
            Deny
          </SusButton>
          {selectedTab === 1 ? (
            <SusButton
              variant="outlined"
              color="primary"
              size="large"
              onClick={handleAcceptSelectionServices}
            >
              Allow selection
            </SusButton>
          ) : (
            <SusButton
              variant="outlined"
              color="primary"
              size="large"
              onClick={handleChangeServices}
            >
              Change settings
            </SusButton>
          )}
          <SusButton variant="outlined" color="primary" size="large" onClick={handleAcceptServices}>
            Accept all
          </SusButton>
        </SusBox>
      </StyledCookieBanner>
    </Dialog>
  );
};

export default ServiceConsentDialog;
