import colors from "@theme/base/colors";
import boxShadows from "@theme/base/boxShadows";
import borders from "@theme/base/borders";

const { md } = boxShadows;
const { borderRadius } = borders;

// types
type Types = any;

const tableContainer: Types = {
  styleOverrides: {
    root: {
      backgroundColor: colors.white.main,
      boxShadow: md,
      borderRadius: borderRadius.xl,
    },
  },
};

export default tableContainer;
