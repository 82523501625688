import React from "react";
import { Table, TableContainer } from "@mui/material";

import { SusListOfContentStyle } from "./styles";

interface SusSettingListRoot {
  children: any;
}

const SusSettingListRoot: React.FC<React.PropsWithChildren<SusSettingListRoot>> = ({
  children,
}) => {
  return (
    <TableContainer sx={SusListOfContentStyle}>
      <Table>{children}</Table>
    </TableContainer>
  );
};

export default SusSettingListRoot;
