import React, { ChangeEvent, useEffect, useState } from "react";

// react-router-dom components
import { Link, Navigate, useLocation, useNavigate } from "react-router-dom";

import Grid from "@mui/material/Unstable_Grid2";

import {
  Box,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
} from "@mui/material";
import useAuth from "@hooks/useAuth";
import useAlerts from "@hooks/useAlerts";
import { paths } from "@src/paths";
import SignInHero from "@src/assets/images/steel_Hero.jpeg";
import SusBox from "@components/SusBox";
import SusButton from "@components/SusButton";
import SusTypography from "@components/SusTypography";
import { Helmet } from "react-helmet-async";
import Logo from "@src/assets/images/cremer_erzkontor_logo.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/pro-solid-svg-icons";

type ResetPasswordEnterNewProps = {
  image?: string;
  title?: string;
  description?: string;
};

const ResetPasswordEnterNew: React.FC<ResetPasswordEnterNewProps> = () => {
  const { user, setNewPassword } = useAuth();
  const [password1, setPassword1] = useState("");
  const [password2, setPassword2] = useState("");
  const [uid, setUid] = useState<string | undefined>(undefined);
  const [token, setToken] = useState<string | undefined>(undefined);
  const navigate = useNavigate();
  const location = useLocation();
  const { notifySuccess, notifyWarning } = useAlerts();

  useEffect(() => {
    const queryParams = new URLSearchParams(location?.search);
    const uid = queryParams.get("u");
    const token = queryParams.get("t");
    if (!uid || !token) {
      navigate("/");
    } else {
      setUid(uid);
      setToken(token);
    }
  });

  const handleSubmit = async (e: SubmitEvent) => {
    e.preventDefault();
    if (uid && token) {
      const success = await setNewPassword?.(password1, password2, uid, token);
      if (success) {
        notifySuccess({
          title: "Success",
          content: "You can now log in with your new password.",
          autoHideDuration: 5000,
        });
        navigate(location?.state?.from || paths.home);
      } else {
        notifyWarning({
          title: "Log in unsuccessful",
          content: "Please check your input.",
          autoHideDuration: 5000,
        });
      }
    }
  };
  const handleChangePassword1 = (e: ChangeEvent<HTMLInputElement>) => {
    setPassword1(e.target.value);
  };
  const handleChangePassword2 = (e: ChangeEvent<HTMLInputElement>) => {
    setPassword2(e.target.value);
  };

  if (user) {
    return <Navigate to={paths.home} replace />;
  }

  const [showPassword, setShowPassword] = useState(false);

  const handleTogglePasswordVisibility = () => {
    setShowPassword((prev) => !prev);
  };

  return (
    <React.Fragment>
      <Helmet>
        <title>Reset password | PEK Dashboard</title>
      </Helmet>
      <Grid container spacing={2} py={2} disableEqualOverflow>
        <Grid xs={12} md={4}>
          <Box
            display="flex"
            flexDirection="column"
            height="calc(100vh - 135px)"
            ml={2}
            sx={{
              background: "#FBFBFB",
              borderRadius: "2rem",
              padding: "1rem",
            }}
          >
            <Box
              sx={{
                marginTop: "0",
              }}
            >
              <SusBox display="flex" flexDirection="row" p={2}>
                <img src={Logo} alt="PEK Dashboard" height="32" style={{ marginRight: "0.5rem" }} />
              </SusBox>
            </Box>
            <Box
              sx={{
                margin: "auto auto auto",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                maxWidth: "400px",
              }}
            >
              <Box
                sx={{
                  marginBottom: "2rem",
                }}
              >
                <SusTypography variant="h4" fontWeight="bold">
                  Reset Password
                </SusTypography>

                <SusTypography variant="body2" fontWeight="regular" color="text">
                  Please choose a new password and enter it twice.
                </SusTypography>
              </Box>

              <SusBox
                component="form"
                role="form"
                onSubmit={handleSubmit}
                sx={{
                  width: "100%",
                }}
              >
                <SusBox mb={2}>
                  <FormControl fullWidth>
                    <InputLabel>New Password</InputLabel>
                    <OutlinedInput
                      type={showPassword ? "text" : "password"}
                      label="New Password"
                      onChange={handleChangePassword1}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleTogglePasswordVisibility}
                            edge="end"
                          >
                            {showPassword ? (
                              <FontAwesomeIcon icon={faEye} fixedWidth size={"xs"} />
                            ) : (
                              <FontAwesomeIcon icon={faEyeSlash} fixedWidth size={"xs"} />
                            )}
                          </IconButton>
                        </InputAdornment>
                      }
                    />
                  </FormControl>
                </SusBox>
                <SusBox mb={2}>
                  <FormControl fullWidth>
                    <InputLabel>Repeat New Password</InputLabel>
                    <OutlinedInput
                      type="password"
                      label="Repeat New Password"
                      onChange={handleChangePassword2}
                    />
                  </FormControl>
                </SusBox>
                <SusBox mt={4} mb={1}>
                  <SusButton color="primary" size="large" fullWidth type="submit">
                    Set New Password
                  </SusButton>
                </SusBox>
                <SusBox mt={3} textAlign="center">
                  <SusTypography variant="button" color="text" fontWeight="regular">
                    back to{" "}
                    <SusTypography
                      component={Link}
                      to="/sign-in/"
                      variant="button"
                      fontWeight="medium"
                    >
                      Sign in
                    </SusTypography>
                  </SusTypography>
                </SusBox>
              </SusBox>
            </Box>
          </Box>
          <Box
            display="flex"
            flexDirection="column"
            ml={2}
            sx={{
              borderRadius: "2rem",
              padding: "1rem",
            }}
          >
            <Box
              sx={{
                margin: "auto auto auto",
                maxWidth: "400px",
              }}
            >
              <SusTypography variant="button" color="text" fontWeight="regular">
                <SusButton
                  href="https://pektogram.com/imprint/"
                  target="_blank"
                  variant="text"
                  fontWeight="medium"
                  color="text"
                >
                  Legal notice
                </SusButton>
                <SusButton
                  href="https://pektogram.com/privacy-policy/"
                  target="_blank"
                  variant="text"
                  fontWeight="medium"
                  color="text"
                >
                  Privacy policy
                </SusButton>
              </SusTypography>
            </Box>
            <Box
              sx={{
                margin: "auto auto auto",
                maxWidth: "400px",
              }}
            >
              <SusTypography variant="button" color="text" fontWeight="regular">
                <SusButton
                  href="https://pektogram.com/"
                  target="_blank"
                  variant="text"
                  fontWeight="medium"
                  color="text"
                >
                  © powered by pektogram
                </SusButton>
              </SusTypography>
            </Box>
          </Box>
        </Grid>
        <Grid xs={12} md={8}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              justifyContent: "end",
              width: "100%",
              height: "calc(100vh - 32px)",
              minHeight: "150px",
              borderRadius: "2rem 0 0 2rem",
              background: `URL(${SignInHero}) no-repeat center center / cover`,
              position: "relative",
              zIndex: 99,
            }}
          />
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default ResetPasswordEnterNew;
