import React, { useEffect, useRef } from "react";
import "simplebar/dist/simplebar.min.css";
import SimpleBar from "simplebar-react";
import SimpleBarCore from "simplebar-core";

import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import SusBox from "@components/SusBox";

interface AgbContentProps {
  src?: string;
  onScrollToBottom: () => void;
}

const AgbContent: React.FC<AgbContentProps> = ({ onScrollToBottom }) => {
  const scrollableNodeRef = useRef<SimpleBarCore | null>(null);

  useEffect(() => {
    const scrollableNode = scrollableNodeRef.current?.getScrollElement();
    scrollableNode?.addEventListener("scroll", handleScroll);
    return () => {
      scrollableNode?.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleScroll = () => {
    const scrollableNode = scrollableNodeRef.current?.getScrollElement();
    const scrollableNodeHeight = scrollableNode?.scrollHeight || 0;
    const scrollableNodeTop = scrollableNode?.scrollTop || 0;
    const scrollableNodeVisibleHeight = scrollableNode?.offsetHeight || 0;
    const isScrollAtBottom =
      scrollableNodeHeight - Math.ceil(scrollableNodeTop + scrollableNodeVisibleHeight) <= 1;
    if (isScrollAtBottom) {
      onScrollToBottom();
    }
  };

  const agb = `
Cremer Erzkontor GmbH & Co KG, Beckergrube 38, 23552 Lübeck, Germany (hereinafter referred to
as the " **Provider"** ) provides on the website [pekdashboard.com] (hereinafter **"Website"** ) for its
customers (hereinafter **"Customer"** ) a dashboard which can be used to display and manage the
contractual relationships, stock levels and order processing (in particular shipment tracking) in the
existing, ongoing supplier relationships (hereinafter " **Dashboard"** ). The Dashboard is made available
in accordance with these Terms of Use (hereinafter **"Terms of Use"** ) exclusively to businesses within
the meaning of Section 14 of the German Civil Code ( _BGB_ ) ( _Unternehmer_ ), legal entities under public
law ( _juristische Personen des öffentlichen Rechts_ ) or special funds under public law ( _öffentlich-
rechtliche Sondervermögen_ ) in their capacity as purchasers of raw materials of the refractories industry.

**The Website and with it the Dashboard are expressly not directed at consumers. According to
Section 13 of the German Civil Code (** **_BGB_** **), a consumer (** **_Verbraucher_** **) is any natural person who
enters into a legal transaction for purposes that are predominantly neither connected to their
commercial nor to their independent professional activities**.

**PLEASE READ THESE TERMS OF USE CAREFULLY BEFORE USING THE SERVICES OF THE PROVIDER
AS DEFINED BELOW. BY USING THE SERVICES, YOU DECLARE YOUR CONSENT WITH THESE TERMS
OF USE IN THE CURRENTLY VALID VERSION.**

## § 1 Description of Services; Scope of the Terms of Use

(1) The Provider shall make the Dashboard defined above available to commercial Customers as a
software-as-a-service for all contractual relationships existing between them. The Provider is free
to continuously adapt and update the functional scope of the Dashboard.

(2) Each Customer receives an initial user account for a primary user as part of the setup. Customers
then have the option to have additional user accounts added for different employees to grant them
access to the Dashboard.

(3) These Terms of Use apply to all user accounts (primary users and other users of the Customer)
and exclusively to the use of the Website and Dashboard. They define the rights and obligations
of users in general. The Customer shall notify all users of the Dashboard of these Terms of Use
and to oblige them accordingly.

(4) Each user shall enter the required personal data (in particular business contact data) in the
respective user account settings and to keep them up to date.

(5) In the relationship between the Provider and the Customer, no general terms and conditions of the
Customer shall be recognized in any case. These Terms of Use shall therefore also apply if the
Provider's Service is used in the knowledge of conflicting general terms and conditions or terms
and conditions that deviate from these Terms of Use.


## § 2 Availability

(1) There is no obligation on the Provider to ensure the availability and continuity of the Website.
The Provider has the right to close the Website in whole or in part at any time. The same applies
to parts of the Website, in particular the Dashboard.

(2) To the extent possible, the Provider shall ensure the complete usability of the Dashboard by
providing access to the data stock of the ERP system.

## § 3 Basic obligations of all users

(1) The use of the Dashboard is permitted only after having created a user account in the Dashboard
by the Customer.

(2) All users shall keep the passwords they have chosen secret and shall not disclose them to third
parties. Users shall indemnify the Provider upon first request against all costs, damages and
expenses incurred by the Provider as a result of a breach of the obligation set forth in sentence 1.
This obligation to indemnify shall also include the usual legal costs incurred.

(3) The Dashboard includes mandatory additional account security through so-called multifactor
authentication ( **"MFA"** ). Paragraph 2 shall apply _mutatis mutandis_ to the additional account
protection by MFA.

## § 4 Duties of the users and exemption

(1) The users shall respect the copyrights and industrial property rights relating to the Dashboard, in
particular trademarks, patents, utility models and design rights.

(2) The Provider shall be indemnified by the Users upon first request against all costs, damages and
expenses incurred by the Provider as a result of third party asserting claims against the Provider
due to infringement of statutory obligations, industrial or other property rights on the part of the
Provider. This obligation to indemnify shall also include the usual legal costs incurred.

## § 5 Limitation of Liability

(1) In the event of intent or gross negligence, also by its representatives and vicarious agents
( _Erfüllungsgehilfen_ ), either Party shall be liable according to the statutory provisions. The same
shall apply in the event of culpably caused damages resulting from an injury to life, body or
health, in the event of damages resulting from a violation of a guarantee as to quality
( _Beschaffenheitsgarantie_ ), as well as in the event of defaults concealed fraudulently (arglistig
verschwiegene Mängel).

(2) In the event of damages to property and financial damages ( _Sach- und Vermögensschäden_ ) caused
by slight negligence of either Party, its representatives or vicarious agents, such Party shall be
liable only in the event of a violation of a contractual core duty ( _wesentliche Vertragspflicht_ ),
however limited to the amount of the damage which was foreseeable at the time of conclusion of
the contract and typical taking into account the nature of the contract ( _vorhersehbarer und
vertragstypischer Schaden_ ). Contractual core duties are such duties whose accomplishment
enables proper fulfilment of an agreement and whose observance the contracting parties may
regularly rely on.

(3) Liability based on the German Product Liability Act ( _Produkthaftungsgesetz_ ) shall remain
unaffected.

(4) Any further liability of either Party other than set out above shall be excluded.

## § 7 Changes to these Terms of Use

The Provider has the right to change these Terms of Use with effect also for all existing user accounts.
The Provider shall inform the Customer of all changes at least in text form. All changes to these Terms
of Use shall only become effective if they are not objected to within 30 days of notification or if the
Dashboard is used by the primary user after notification of the changes (whichever occurs first). In case
of objection, the Provider is entitled to terminate the business relationship and deactivate the Customer's
user accounts.

## § 8 Data protection; Confidentiality; Final provisions

(1) Reference is made to the Privacy Policy of the Provider, available at [URL]. The Dashboard is a
service licensed to the Provider from pektogram GmbH, Glockengießerwall 3, 20095 Hamburg,
Germany, which acts as a data processor within the meaning of Article 28 (1) of the GDPR.

(2) Users are obligated to maintain confidentiality regarding all contractual conditions existing with
the Provider.

(3) Should single provisions of these Terms of Use be invalid or unenforceable, this shall not affect
the validity and enforceability of the remaining provisions. In place of the invalid or
unenforceable provision, the parties shall agree on the provision customary in the industry for this
case, or in the absence of a permissible provision customary in the industry, the corresponding
statutory provision that comes closest to the invalid or unenforceable provision.

(4) Any agreements between the Provider and the Users that deviate from these Terms of Use must
at least be in text form.


(5) The exclusive place of jurisdiction for disputes arising from contractual relationships between the
Provider and a User ( _ausschließlicher Gerichtsstand_ ) is the place of the Provider's registered
office.

(6) All legal relationships between the Provider and its Users shall be governed exclusively by the
laws of the Federal Republic of Germany, to the exclusion of the UN Convention on Contracts
for the International Sale of Goods ( _CISG_ ) and other international private law. As far as legally
permissible, conflict of laws rules from international private law are waived if these stipulate the
applicability of a law other than the ones of the Federal Republic of Germany.
`;

  return (
    <SimpleBar
      ref={scrollableNodeRef}
      style={{
        maxHeight: "285px",
        width: "100%",
        background: "#F3F4F6",
        padding: "7px",
        borderRadius: "5px",
      }}
    >
      <SusBox
        sx={{
          textAlign: "left",
          "& p": {
            marginBottom: "5px",
          },
          "& h2": {
            marginBottom: "10px",
            marginTop: "5px",
            fontSize: "1.2rem",
          },
        }}
      >
        <ReactMarkdown
          components={{
            h1: "h2",
          }}
          children={agb}
          remarkPlugins={[remarkGfm]}
        />
      </SusBox>
    </SimpleBar>
  );
};

export default AgbContent;
