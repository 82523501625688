import React, { ChangeEventHandler, useEffect, useMemo, useState } from "react";
import { IconButton, TextareaAutosize, Tooltip, Zoom } from "@mui/material";
import Card from "@mui/material/Card";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/pro-regular-svg-icons";

import { clone, debounce, isArray, isString } from "lodash";
import useAuth from "../../../hooks/useAuth";
import "simplebar/dist/simplebar.min.css";
import useStyles from "./styles";
import SusBox from "@components/SusBox";
import SusTypography from "@components/SusTypography";

interface IWidgetNotesProps {
  title: string;
  uuid: string;
  onRemove?: (event?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

const WidgetNotes: React.FC<React.PropsWithChildren<IWidgetNotesProps>> = ({
  title,
  uuid,
  onRemove,
}) => {
  const { user, updateUser } = useAuth();
  const [content, setContent] = useState("");

  // Load content from user config
  useEffect(() => {
    const userNotesContents = user?.config?.notes;
    if (!userNotesContents) {
      return;
    }
    if (isString(userNotesContents)) {
      setContent(userNotesContents);
    } else if (isArray(userNotesContents)) {
      setContent(userNotesContents.find((value) => value.uuid === uuid)?.content || "");
    }
  }, [user?.id]);

  // Reduce number of requests by not sending update on every keystroke
  const debouncedSaveChanges = useMemo(
    () =>
      debounce((newContent: string) => {
        const updatedNotes = [
          ...(user?.config?.notes && isArray(user.config.notes) ? removeContents(false) || [] : []),
          { uuid: uuid, content: newContent },
        ];
        updateUser?.({ config: { ...user?.config, notes: updatedNotes } });
      }, 300),
    [updateUser, user?.id, user?.config]
  );

  /**
   * Remove this note's content from the user notes list and return the resulting list.
   * @param save If true, save the new list to the user config.
   */
  const removeContents = (save?: boolean) => {
    if (!user?.config?.notes) {
      return;
    }
    let updatedNotes = undefined;
    if (isArray(user?.config?.notes)) {
      updatedNotes = clone(user.config.notes);
      const index = user.config.notes.findIndex((value) => value.uuid === uuid);
      if (index >= 0) {
        updatedNotes.splice(index, 1);
      }
    }
    if (save) {
      updateUser?.({ config: { ...user?.config, notes: updatedNotes } });
    }
    return updatedNotes;
  };

  const handleChange: ChangeEventHandler<HTMLTextAreaElement> = (event) => {
    const value = event.target.value;
    const lines = value.split("\n");
    if (lines.length <= 9) {
      setContent(value);
      debouncedSaveChanges(value);
    }
  };
  const handleRemove = () => {
    if (!onRemove) {
      return;
    }
    removeContents(true);
    onRemove();
  };
  const classes = useStyles();

  return (
    <Card sx={{ height: "100%" }}>
      <SusBox display="flex" justifyContent="space-between" alignItems="center" py={2} px={2}>
        <SusTypography variant="h6" fontWeight="medium" textTransform="uppercase">
          {title}Notes
        </SusTypography>
        <SusBox display="flex" justifyContent="flex-end" alignItems="center">
          {onRemove && (
            <Tooltip title="Remove Widget" placement="bottom" TransitionComponent={Zoom}>
              <IconButton
                onClick={handleRemove}
                sx={{
                  "&:hover": {
                    "& svg": {
                      color: "#EF6461",
                    },
                  },
                }}
              >
                <FontAwesomeIcon icon={faXmark} size="xs" />
              </IconButton>
            </Tooltip>
          )}
        </SusBox>
      </SusBox>
      <SusBox p={0} sx={{ height: "100%", overflow: "hidden" }}>
        <>
          <TextareaAutosize
            className={classes.notes}
            aria-label="minimum height"
            maxRows={9}
            minRows={1}
            placeholder="Write a note.."
            style={{ width: "100%", resize: "none", height: "calc(320px - 58px)" }}
            wrap="hard"
            onChange={handleChange}
            value={content}
          />
        </>
      </SusBox>
    </Card>
  );
};

export default WidgetNotes;
