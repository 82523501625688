import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
  layout: {
    position: "relative",
    transition: "height 200ms ease",

    "& .react-grid-item": {
      transition: "all 200ms ease",
      transitionProperty: "left, top",
      "&.react-draggable": {
        cursor: "grab",
      },
    },
    "& .react-grid-item-content": {
      userSelect: "none",
    },
    "& .react-grid-item-css-transforms": {
      transitionProperty: "transform",
    },
    "& .react-grid-item-resizing": {
      zIndex: 1,
      willChange: "width, height",
    },
    "& .react-grid-item-dragging": {
      transition: "none",
      zIndex: 3,
      willChange: "transform",
      cursor: "grabbing",
    },
    "& .react-grid-item-dropping": {
      visibility: "hidden",
    },
    "& .react-grid-item-placeholder, & .react-resizable-handle": {
      background: "transparent",
      opacity: 0.8,
      transitionDuration: "100ms",
      borderRadius: "15px",
      zIndex: 2,
    },
    "& .react-grid-item-placeholder": {
      border: "0.25rem dashed #d3d3d3 !important",
    },
    "& .react-resizable-handle": {
      position: "absolute",
      width: "100%",
      height: "4px",
      backgroundImage: "none !important",
    },
    "& .react-draggable .react-resizable-handle::after": {
      position: "absolute",
      right: 0,
      left: 0,
      margin: "0 auto",
      bottom: 0,
      width: "90%",
      borderRadius: "4px",
      height: "3px",
      borderBottom: "3px solid #a3a3a3",
    },
    "& .react-resizable-hide-handle": {
      display: "none",
    },
    "& .react-resizable-handle-sw, & .react-resizable-handle-nw": {
      bottom: 0,
      left: 0,
      cursor: "sw-resize",
    },
    "& .react-resizable-handle-se, & .react-Resizable-handle-we, & .react-resizable-handle-n": {
      width: "140px",
      left: 0,
      right: 0,
      margin: "0 auto",
    },
    "& .react-resizable-handle-se::after": {
      bottom: 0,
      top: "9px",
      cursor: "se-resize",
    },
    "& .react-Resizable-handle-ne": {
      top: 0,
      right: 0,
      marginTop: "-10px",
      cursor: "ne-resize",
    },
    "& .react-Resizable-handle-we, & .react-resizable-handle-s": {
      marginTop: "-10px",
    },
    "& .react-resizable-handle-w": {
      left: "-20px",
      transform: "rotate(90deg)",
    },
    "& .react-resizable-handle-e": {
      right: "-80px",
      top: "50%",
      width: "140px",
      transform: "rotate(90deg)",
      cursor: "e-resize",
      "&::after": {
        left: "10px",
        width: "calc(90% - 20px) !important",
      },
    },
    "& .react-resizable-handle-ns": {
      marginLeft: 0,
      cursor: "ns-resize",
    },
    "& .react-resizable-handle-n": {
      top: "-20px",
    },
    "& .react-resizable-handle-s": {
      bottom: "-9px",
      width: "140px",
      left: 0,
      right: 0,
      margin: "0 auto",
      cursor: "s-resize",
    },
  },
});

export default useStyles;
