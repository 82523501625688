const accordionSummary = {
  styleOverrides: {
    expandIconWrapper: {
      transform: "none",
      opacity: 0.38,
      "&.Mui-expanded": {
        transform: "none",
      },
    },
  },
};

export default accordionSummary;
