import React, { ReactNode } from "react";
import { Button, Icon, Theme, Tooltip, Typography, Zoom } from "@mui/material";

import SusBox from "../../components/SusBox";
import { collapseIcon } from "./styles/sidenavCollapse";
import { Link } from "react-router-dom";
import colors from "@theme/base/colors";
import borders from "@theme/base/borders";

interface SidenavCollapseProps {
  color?: "primary" | "secondary" | "info" | "success" | "warning" | "error" | "dark";
  icon: ReactNode;
  name: string;
  children?: ReactNode;
  active?: boolean;
  noCollapse?: boolean;
  betaFeature?: boolean;
  open?: boolean;
  loading?: boolean;
  [key: string]: any;
  to?: string;
}

const SidenavCollapse: React.FC<SidenavCollapseProps> = ({
  icon,
  name,
  active = false,

  betaFeature = false,

  loading = false,
  to: route = "",
  key: key,
  ...rest
}) => {
  const listItemName = name.replace(/\s/g, "-").toLowerCase();
  return (
    <>
      <Button
        component={Link}
        variant="text"
        to={route}
        key={key}
        data-component-name={`${listItemName}`}
        fullWidth
        sx={{
          border: 0,
          borderRadius: borders.borderRadius.md,
          justifyContent: "flex-start",
          margin: "4px 0",
          display: "flex",
          padding: "4px 16px",
          fontWeight: 500,
          background: active ? colors.grey[300] : "transparent",
        }}
        startIcon={
          typeof icon === "string" ? (
            <Icon sx={(theme: Theme) => collapseIcon(theme, { active })}>{icon}</Icon>
          ) : (
            icon
          )
        }
      >
        <SusBox
          {...rest}
          className={`sidenavItem-${listItemName}`}
          sx={{
            color: active ? colors.primary.focus : colors.black.main,
            padding: "8px 16px",
          }}
        >
          {name}
          {!loading && betaFeature && (
            <Tooltip
              title="Function and data are still in testing phase."
              placement="top"
              TransitionComponent={Zoom}
            >
              <SusBox
                sx={{
                  background: "#9EA3B0",
                  borderRadius: "8px",
                  color: "#FFFFFF",
                  padding: "4px",
                  textAlign: "center",
                  position: "absolute",
                  right: "20px",
                  top: "9px",
                }}
              >
                <Typography
                  sx={{
                    fontSize: "9px !important",
                    letterSpacing: "0.25px",
                    textTransform: "uppercase",
                  }}
                >
                  Beta
                </Typography>
              </SusBox>
            </Tooltip>
          )}
        </SusBox>
      </Button>
    </>
  );
};

export default SidenavCollapse;
