import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
  notes: {
    fontFamily: "inherit",
    backgroundImage: "linear-gradient(#ede2b4 0px, #fffae7 1px)",
    WebkitAppearance: "none",
    color: "#344767",
    fontWeight: 500,
    fontSize: "1rem",
    resize: "none",
    wordWrap: "break-word",
    width: "100%",
    border: "none",
    lineHeight: "1.8rem",
    padding: "3px 20px 12px",
    boxSizing: "border-box",
    height: "calc(320px - 44px)",
    outline: "none",
    boxShadow: "none",
    borderRadius: "0",
    backgroundSize: "100% 1.8rem",
    overflow: "hidden",
  },
});
export default useStyles;
