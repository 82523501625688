import React, { useEffect, useState } from "react";

import {
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Theme,
} from "@mui/material";
import { useForm } from "react-hook-form";
import { SubmitHandler } from "react-hook-form/dist/types/form";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFloppyDisk, faTimes } from "@fortawesome/pro-regular-svg-icons";
import LoadingButton from "@mui/lab/LoadingButton";

import { Helmet } from "react-helmet-async";
import useAlerts from "@src/hooks/useAlerts";
import { ApiResponse } from "@src/api";
import TabsFormSection from "@components/FormularSection/TabsFormSection";
import SusButton from "@components/SusButton";
import SusBox from "@components/SusBox";
import useCompanySettings from "@hooks/useCompanySettings";
import colors from "@theme/base/colors";

interface ICompanyDetailsProps {
  companyId: number;
  hasUpdateCompanyPermission: boolean;
  isSuperuser?: boolean;
}

interface ICompanyFormData {
  name: string;
  street: string;
  zipCode: string;
  city: string;
  country: string;
  vatId: string;
  salesRepEmail: string;
  salesRepName: string;
}

const CompanyDetails: React.FC<ICompanyDetailsProps> = ({
  companyId,
  hasUpdateCompanyPermission,
  isSuperuser,
}) => {
  const { company, loading, updateCompany, sending } = useCompanySettings(companyId);
  const {
    register,
    handleSubmit,
    reset,
    formState: { isDirty },
  } = useForm<ICompanyFormData>();
  const { notifySuccess, notifyError } = useAlerts();

  const [errors, setErrors] = useState<ApiResponse["errors"] | null | undefined>();

  useEffect(() => {
    reset({
      ...company,
    });
  }, [company]);

  const onSubmit: SubmitHandler<ICompanyFormData> = async (data, event) => {
    event?.preventDefault();
    const response = await updateCompany({ company: data });
    if (!response?.success) {
      setErrors(response?.errors);
      notifyError({
        title: "Error",
        content: "Something went wrong. Please check your input.",
        autoHideDuration: 5000,
      });
    } else {
      setErrors(null);
      notifySuccess({
        title: "Success",
        content: "Company updated.",
        autoHideDuration: 5000,
      });
    }
  };

  return loading ? null : (
    <>
      <Helmet>
        <title>Company - Preferences | PEK Dashboard </title>
      </Helmet>
      <TabsFormSection
        title="Company information"
        description="These are general information about your Company. "
      >
        <>
          <Grid container>
            <FormControl
              sx={({ breakpoints }: Theme) => ({
                width: "280px",
                maxWidth: "100%",
                marginBottom: "0.5rem",
                [breakpoints.down("md")]: {
                  width: "100%",
                },
              })}
              variant="outlined"
            >
              <InputLabel htmlFor="outlined-adornment-company">Company</InputLabel>
              <OutlinedInput
                label="Company"
                defaultValue=" "
                {...register("name", { required: true })}
                disabled={!hasUpdateCompanyPermission && true}
              />
              {errors && (
                <FormHelperText id="standard-weight-helper-text">
                  {errors?.get("name")}
                </FormHelperText>
              )}
            </FormControl>
          </Grid>
          <Grid
            container
            sx={{
              flexDirection: "row",
            }}
          >
            <FormControl
              sx={({ breakpoints }: Theme) => ({
                width: "280px",
                maxWidth: "100%",
                marginBottom: "0.5rem",
                marginRight: "0.5rem",
                [breakpoints.down("md")]: {
                  width: "100%",
                  marginRight: "0",
                },
              })}
            >
              <InputLabel htmlFor="standard-adornment-street">Street</InputLabel>
              <OutlinedInput
                label="Street"
                defaultValue=" "
                {...register("street")}
                error={errors?.has("street")}
                disabled={!hasUpdateCompanyPermission && true}
              />
              {errors && (
                <FormHelperText id="standard-weight-helper-text">
                  {errors?.get("street")}
                </FormHelperText>
              )}
            </FormControl>
            <FormControl
              sx={({ breakpoints }: Theme) => ({
                width: "280px",
                maxWidth: "100%",
                marginBottom: "0.5rem",
                [breakpoints.down("md")]: {
                  width: "100%",
                },
              })}
            >
              <InputLabel htmlFor="standard-adornment-zip">ZIP</InputLabel>
              <OutlinedInput
                label="ZIP"
                defaultValue=" "
                {...register("zipCode")}
                error={errors?.has("zipCode")}
                disabled={!hasUpdateCompanyPermission && true}
              />

              {errors && (
                <FormHelperText id="standard-weight-helper-text">
                  {errors?.get("zipCode")}
                </FormHelperText>
              )}
            </FormControl>
          </Grid>
          <Grid
            container
            sx={{
              flexDirection: "row",
            }}
          >
            <FormControl
              sx={({ breakpoints }: Theme) => ({
                width: "280px",
                maxWidth: "100%",
                marginBottom: "0.5rem",
                marginRight: "0.5rem",
                [breakpoints.down("md")]: {
                  width: "100%",
                  marginRight: "0",
                },
              })}
            >
              <InputLabel htmlFor="standard-adornment-city">City</InputLabel>
              <OutlinedInput
                label="City"
                defaultValue=" "
                {...register("city")}
                error={errors?.has("city")}
                disabled={!hasUpdateCompanyPermission && true}
              />
              {errors && (
                <FormHelperText id="standard-weight-helper-text">
                  {errors?.get("city")}
                </FormHelperText>
              )}
            </FormControl>
            <FormControl
              sx={({ breakpoints }: Theme) => ({
                width: "280px",
                maxWidth: "100%",
                marginBottom: "0.5rem",
                [breakpoints.down("md")]: {
                  width: "100%",
                  marginRight: "0",
                },
              })}
            >
              <InputLabel htmlFor="standard-adornment-country">Country</InputLabel>
              <OutlinedInput
                label="Country"
                defaultValue=" "
                {...register("country")}
                error={errors?.has("country")}
                disabled={!hasUpdateCompanyPermission && true}
              />
              {errors && (
                <FormHelperText id="standard-weight-helper-text">
                  {errors?.get("country")}
                </FormHelperText>
              )}
            </FormControl>
          </Grid>
          <Grid
            container
            sx={{
              flexDirection: "row",
            }}
          >
            <FormControl
              sx={({ breakpoints }: Theme) => ({
                width: "280px",
                maxWidth: "100%",
                marginBottom: "0.5rem",
                marginRight: "0.5rem",
                [breakpoints.down("md")]: {
                  width: "100%",
                  marginRight: "0",
                },
              })}
            >
              <InputLabel htmlFor="standard-adornment-VatID">Vat</InputLabel>
              <OutlinedInput
                label="Vat"
                defaultValue=" "
                {...register("vatId")}
                error={errors?.has("vatId")}
                disabled={!hasUpdateCompanyPermission && true}
              />
              {errors && (
                <FormHelperText id="standard-weight-helper-text">
                  {errors?.get("vatId")}
                </FormHelperText>
              )}
            </FormControl>
            {hasUpdateCompanyPermission ? (
              <FormControl
                sx={({ breakpoints }: Theme) => ({
                  width: "280px",
                  maxWidth: "100%",
                  marginBottom: "0.5rem",
                  [breakpoints.down("md")]: {
                    width: "100%",
                    marginRight: "0",
                  },
                })}
              >
                <InputLabel htmlFor="standard-adornment-password">
                  Account Authorized Member
                </InputLabel>
                <OutlinedInput disabled label="Account Authorized Member" />
              </FormControl>
            ) : null}
          </Grid>
          <Grid
            container
            sx={{
              flexDirection: "row",
            }}
          >
            <FormControl
              sx={({ breakpoints }: Theme) => ({
                width: "280px",
                maxWidth: "100%",
                marginBottom: "0.5rem",
                marginRight: "0.5rem",
                [breakpoints.down("md")]: {
                  width: "100%",
                  marginRight: "0",
                },
              })}
            >
              <InputLabel htmlFor="standard-adornment-SalesRepName">Sales Rep Name</InputLabel>
              <OutlinedInput
                label="Sales Rep Name"
                defaultValue=" "
                {...register("salesRepName")}
                error={errors?.has("salesRepName")}
                disabled={!isSuperuser}
              />
              {errors && (
                <FormHelperText id="standard-weight-helper-text">
                  {errors?.get("salesRepName")}
                </FormHelperText>
              )}
            </FormControl>
            <FormControl
              sx={({ breakpoints }: Theme) => ({
                width: "280px",
                maxWidth: "100%",
                marginBottom: "0.5rem",
                marginRight: "0.5rem",
                [breakpoints.down("md")]: {
                  width: "100%",
                  marginRight: "0",
                },
              })}
            >
              <InputLabel htmlFor="standard-adornment-SalesRepName">Sales Rep E-Mail</InputLabel>
              <OutlinedInput
                label="Sales Rep E-Mail"
                defaultValue=" "
                {...register("salesRepEmail")}
                error={errors?.has("salesRepEmail")}
                disabled={!isSuperuser}
              />
              {errors && (
                <FormHelperText id="standard-weight-helper-text">
                  {errors?.get("salesRepEmail")}
                </FormHelperText>
              )}
            </FormControl>
          </Grid>
        </>
      </TabsFormSection>
      {hasUpdateCompanyPermission && (
        <SusBox
          display="flex"
          justifyContent="flex-end"
          sx={{ borderTop: "1px solid #e0e0e0", padding: "1rem 1rem 0 1rem" }}
        >
          {isDirty && (
            <SusButton
              size="medium"
              color="text"
              variant="outlined"
              onClick={() => reset({ ...company })}
              startIcon={
                <InputAdornment position="start">
                  <IconButton
                    aria-label={"save"}
                    edge="start"
                    sx={{
                      color: colors.white.main,
                    }}
                  >
                    <FontAwesomeIcon icon={faTimes} size="xs" />
                  </IconButton>
                </InputAdornment>
              }
              sx={{
                lineHeight: "1",
                mt: { xs: 1, md: 0 },
                mr: { xs: 1, sm: 1, md: 1 },
                ml: "auto",
                paddingLeft: "1rem",
                paddingRight: "1rem",
                minWidth: "auto",
              }}
            >
              Discard
            </SusButton>
          )}

          <LoadingButton
            onClick={handleSubmit(onSubmit)}
            loading={sending}
            variant="contained"
            color="primary"
            size="medium"
            startIcon={
              <InputAdornment position="start">
                <IconButton
                  aria-label={"save"}
                  edge="start"
                  sx={{
                    color: colors.white.main,
                  }}
                >
                  <FontAwesomeIcon icon={faFloppyDisk} size="xs" />
                </IconButton>
              </InputAdornment>
            }
          >
            Save
          </LoadingButton>
        </SusBox>
      )}
    </>
  );
};

export default CompanyDetails;
