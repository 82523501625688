import { Skeleton, Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import React from "react";

export default () => (
  <Table sx={{ width: "100%" }} size="small">
    <TableHead>
      <TableRow>
        <TableCell>
          <Skeleton width={190} />
        </TableCell>
        <TableCell>
          <Skeleton width={190} />
        </TableCell>
        <TableCell>
          <Skeleton width={190} />
        </TableCell>
        <TableCell>
          <Skeleton width={190} />
        </TableCell>
        <TableCell>
          <Skeleton width={190} />
        </TableCell>
      </TableRow>
    </TableHead>
    <TableBody>
      {[...Array(10)].map((value, index) => (
        <TableRow key={index}>
          <TableCell>
            <Skeleton width={190} />
          </TableCell>
          <TableCell>
            <Skeleton width={190} />
          </TableCell>
          <TableCell>
            <Skeleton width={190} />
          </TableCell>
          <TableCell>
            <Skeleton width={190} />
          </TableCell>
          <TableCell>
            <Skeleton width={190} />
          </TableCell>
        </TableRow>
      ))}
    </TableBody>
  </Table>
);
