import dashboardAxios, { ApiResponse } from "../api";
import { Company, Invitation } from "../types";
import { apiPaths } from "../paths";
import useApiData from "./useApiData";
import { useState } from "react";
import { generateApiPath } from "../utils/helpers";

interface UpdateCompanyProps {
  company: Partial<Company>;
}

interface SendInvitationProps {
  invitation: Pick<Invitation, "email" | "role">;
}

interface ResendInvitationProps {
  id: number;
}

interface CancelInvitationProps {
  id: number;
}

interface CompanySettingsResult {
  company?: Company;
  loading: boolean;
  refetching: boolean;
  refetch: () => void;
  updateCompany: (props: UpdateCompanyProps) => Promise<ApiResponse>;
  sendInvitation: (props: SendInvitationProps) => Promise<ApiResponse>;
  resendInvitation: (props: ResendInvitationProps) => Promise<ApiResponse>;
  cancelInvitation: (props: CancelInvitationProps) => Promise<ApiResponse>;
  sending: boolean;
}

/***
 * Provides access to company data and functions to update them.
 * @param companyId ID of the company to work with. If not given, use the company of the current user.
 */
const useCompanySettings: (companyId: number) => CompanySettingsResult = (companyId) => {
  const [sending, setSending] = useState(false);

  const { data, loading, fetch, refetching } = useApiData(
    generateApiPath(apiPaths.company, { id: companyId?.toString() })
  );

  const updateCompany: CompanySettingsResult["updateCompany"] = async ({ company }) => {
    setSending(true);
    return dashboardAxios
      .patch(generateApiPath(apiPaths.company, { id: companyId?.toString() }), company)
      .then(() => {
        return { success: true };
      })
      .catch(() => {
        return { success: false };
      })
      .finally(() => {
        setSending(false);
      });
  };

  const sendInvitation: CompanySettingsResult["sendInvitation"] = async ({ invitation }) => {
    setSending(true);
    return dashboardAxios
      .post(generateApiPath(apiPaths.invitations, { companyId: companyId?.toString() }), {
        ...invitation,
        company: companyId,
      })
      .then((response) => {
        return { success: true, data: response.data };
      })
      .catch((error) => {
        return {
          success: false,
          errors: new Map<string, string[]>(Object.entries(error.response.data)),
        };
      })
      .finally(() => {
        setSending(false);
      });
  };

  const resendInvitation: CompanySettingsResult["resendInvitation"] = async ({ id }) => {
    setSending(true);
    return dashboardAxios
      .post(
        generateApiPath(apiPaths.resendInvitation, {
          companyId: companyId.toString(),
          id: id.toString(),
        })
      )
      .then(() => {
        return { success: true };
      })
      .catch((error) => {
        return {
          success: false,
          errors: new Map<string, string[]>(Object.entries(error.response.data)),
        };
      })
      .finally(() => {
        setSending(false);
      });
  };

  const cancelInvitation: CompanySettingsResult["cancelInvitation"] = async ({ id }) => {
    setSending(true);
    return dashboardAxios
      .delete(
        generateApiPath(apiPaths.invitation, {
          companyId: companyId?.toString(),
          id: id.toString(),
        })
      )
      .then(() => {
        return { success: true };
      })
      .catch((error) => {
        return {
          success: false,
          errors: new Map<string, string[]>(Object.entries(error.response.data)),
        };
      })
      .finally(() => {
        setSending(false);
      });
  };

  return {
    loading,
    company: data,
    updateCompany,
    sendInvitation,
    resendInvitation,
    cancelInvitation,
    sending: sending,
    refetch: () => fetch(true),
    refetching,
  };
};

export default useCompanySettings;
