import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Grid, Skeleton, Tooltip, Zoom } from "@mui/material";
import Cookies from "js-cookie";
import { delay } from "lodash";

import UploadedFile from "@components/UploadedFile/UploadedFile";
import SusDropzone from "@components/SusDropzone/SusDropzone";
import SusWidget from "@src/templates/SusWidget";
import SusBox from "@components/SusBox";
import SusTypography from "@components/SusTypography";
import useAlerts from "@hooks/useAlerts";
import dashboardAxios from "@src/api";
import { MAX_UPLOAD_FILESIZE } from "@src/constants";
import { File, FileAttachments } from "@src/types";

interface IDropzoneAreaProps {
  url: string;
  fileAttachments: FileAttachments;
  loading?: boolean;
  comingSoon?: boolean;
}

const DropzoneArea: React.FC<IDropzoneAreaProps> = ({
  fileAttachments,
  url,
  loading,
  comingSoon,
}) => {
  const csrfToken = Cookies.get("csrftoken");
  const [displayFileAttachments, setDisplayFileAttachments] =
    useState<FileAttachments>(fileAttachments);
  const alerts = useAlerts();

  useEffect(() => {
    setDisplayFileAttachments(fileAttachments);
  }, [fileAttachments]);

  const filesUploadedHandler = (files: File[], fileName: string) => {
    delay(() => {
      const prevEntries = displayFileAttachments?.[fileName] || [];
      setDisplayFileAttachments({
        ...displayFileAttachments,
        [fileName]: [...prevEntries, ...files],
      });
    }, 1000);
  };

  const onRemoveFile = useCallback(
    (removedFile: File) => {
      if (!removedFile?.url) {
        return;
      }
      dashboardAxios
        .delete(removedFile.url)
        .then(() => {
          alerts.notifySuccess({
            title: "Success",
            content: "File deleted successfully.",
            autoHideDuration: 5000,
          });
          setDisplayFileAttachments({
            ...displayFileAttachments,
            [removedFile.fileName]: displayFileAttachments[removedFile.fileName].filter(
              (file) => file.file !== removedFile.file
            ),
          });
        })
        .catch((error) => {
          console.log(error);
          let message = "Sorry, something went wrong. Please try again later.";
          if (error?.response?.status === 403) {
            message = "Sorry, you do not have permission to delete this file.";
          }
          alerts.notifyError({
            title: "Error",
            content: message,
            autoHideDuration: 5000,
          });
        });
    },
    [displayFileAttachments]
  );

  const fileList = useMemo(
    () =>
      Object.entries(displayFileAttachments).map(([fileType, files]) => (
        <Grid
          key={fileType}
          item
          xs={6}
          sm={4}
          sx={{
            maxWidth: "220px !important",
          }}
        >
          {loading ? (
            <Skeleton
              variant="rectangular"
              width={"100%"}
              height={107}
              sx={{ borderRadius: "13px" }}
            />
          ) : (
            <>
              {files.length > 0 ? (
                files.map((file) => (
                  <>
                    {loading ? (
                      <Skeleton
                        variant="rectangular"
                        width={"100%"}
                        height={107}
                        sx={{ borderRadius: "13px" }}
                      />
                    ) : (
                      <UploadedFile key={file.id} file={file} onRemoveFile={onRemoveFile} />
                    )}
                  </>
                ))
              ) : (
                <>
                  {loading ? (
                    <Skeleton
                      variant="rectangular"
                      width={"100%"}
                      height={107}
                      sx={{ borderRadius: "13px" }}
                    />
                  ) : (
                    <SusDropzone
                      onFilesUploaded={(files) => filesUploadedHandler(files, fileType)}
                      loading={loading}
                      options={{
                        paramName: "files",
                        params: () => {
                          return {
                            file_name: fileType,
                          };
                        },
                        acceptedFiles: "application/pdf",
                        uploadMultiple: true,
                        maxFilesize: MAX_UPLOAD_FILESIZE,
                        createImageThumbnails: false,
                        url,
                        headers: {
                          "X-CSRFToken": csrfToken || "",
                        },
                        withCredentials: true,
                        dictDefaultMessage: fileType,
                        maxFiles: comingSoon ? 0 : 1,
                      }}
                    />
                  )}
                </>
              )}
            </>
          )}
        </Grid>
      )),
    [displayFileAttachments, loading]
  );

  return (
    <SusWidget title={"Documents"} loading={loading} className={"product-documents-card"}>
      <Grid
        direction="row"
        spacing={0.5}
        container
        alignItems="left"
        justifyContent="left"
        sx={{
          position: "relativ",
        }}
      >
        {comingSoon && (
          <Tooltip title="This feature will be available soon." TransitionComponent={Zoom}>
            <SusBox
              sx={{
                position: "absolute",
                width: "100%",
                height: "calc(100% - 90px)",
                background: "rgba(255,255,255,0.50)",
                textAlign: "center",
                zIndex: "1",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <SusTypography
                variant="headline"
                fontWeight="bold"
                textTransform="capitalize"
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "top",
                  transform: "rotate(350deg)",
                  marginTop: "30px",
                }}
              >
                Coming Soon
              </SusTypography>
            </SusBox>
          </Tooltip>
        )}
        {fileList}
      </Grid>
    </SusWidget>
  );
};
export default DropzoneArea;
