import React, { useEffect } from "react";
import Joyride, { ACTIONS, CallBackProps, EVENTS, STATUS } from "react-joyride";
import { useDashboardState } from "../../app.state";
import { useSetState } from "react-use";
import { anyParentMatchesSelector } from "../../utils/helpers";

export interface TourState {
  lazyTarget: boolean;
  stepIndex: number;
  steps: any;
}

export const Tour: React.FC = () => {
  const { viewSettings, viewConfigKey, tourRunning, startTour, stopTour, setTourRunning } =
    useDashboardState();

  const [{ lazyTarget, stepIndex, steps }, setState] = useSetState<TourState>({
    lazyTarget: false,
    stepIndex: 0,
    steps: viewSettings.tourSteps,
  });

  useEffect(() => {
    setState({
      lazyTarget: false,
      stepIndex: 0,
      steps: viewSettings.tourSteps,
    });
  }, [viewConfigKey]);

  const handleJoyrideCallback = (data: CallBackProps) => {
    const { action, index, status, type } = data;

    if (([STATUS.FINISHED, STATUS.SKIPPED] as string[]).includes(status)) {
      // Need to set our running state to false, so we can restart if we click start again.
      stopTour();
      setState({ stepIndex: 0 });
    } else if (([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND] as string[]).includes(type)) {
      const currentStep = viewSettings?.tourSteps?.[stepIndex];
      const nextStepIndex = index + (action === ACTIONS.PREV ? -1 : 1);
      setTourRunning(!(currentStep?.lazyTarget || false));
      setState({
        stepIndex: nextStepIndex,
        lazyTarget: currentStep?.lazyTarget || false,
      });
    }
    if (action === ACTIONS.CLOSE || action === ACTIONS.SKIP) {
      stopTour();
      setState({ stepIndex: 0 });
      return true;
    }
  };

  // If the next step has a lazy target, it will probably be not mounted immediately.
  // In this case, the joyride "run" is paused and "lazyTarget" will be set,
  // which will trigger this useEffect hook to check for the presence of that lazy target
  // in a 200ms interval. When found, the interval will be cleared.
  useEffect(() => {
    if (tourRunning || !lazyTarget) {
      setState({ lazyTarget: false });
      return;
    }

    const currentStep = viewSettings?.tourSteps?.[stepIndex];
    if (typeof currentStep?.target !== "string") {
      console.error(
        "Tour step is configured to have a lazy target. In this case, step.target needs to be a css selector string!"
      );
      return;
    }
    const interval = setInterval(() => {
      const elems = document.querySelectorAll(currentStep?.target as string);
      if (elems.length > 0) {
        startTour();
        setState({ lazyTarget: false });
        clearInterval(interval);
      }
    }, 200);
    return () => clearInterval(interval);
  }, [lazyTarget]);

  // Register global click-event handler in order to be able to use click events in the joyride tour.
  useEffect(() => {
    if (!tourRunning || !viewSettings) {
      return;
    }

    const handleClick = (event: MouseEvent) => {
      if (!tourRunning) {
        return;
      }
      const currentStep = viewSettings?.tourSteps?.[stepIndex];
      if (
        currentStep?.nextStepOnClick &&
        typeof currentStep?.target === "string" &&
        event?.target
      ) {
        if (anyParentMatchesSelector(event.target as HTMLElement, currentStep.target)) {
          setTourRunning(!currentStep.lazyTarget);
          setState({
            stepIndex: stepIndex + 1,
            lazyTarget: currentStep.lazyTarget,
          });
        }
      }
    };

    window.addEventListener("click", handleClick);
    return () => window.removeEventListener("click", handleClick);
  }, [stepIndex, tourRunning, viewConfigKey]);

  return viewSettings.tourSteps ? (
    <Joyride
      continuous={true}
      debug={false}
      steps={steps}
      showProgress
      run={tourRunning}
      scrollToFirstStep
      callback={handleJoyrideCallback}
      stepIndex={stepIndex}
      scrollOffset={90}
      disableScrolling={true}
      styles={{
        options: {
          zIndex: 10000,
          arrowColor: "#fff",
          backgroundColor: "#fff",
          overlayColor: "rgba(51,51,51,0.44)",
          primaryColor: "#fe654f",
          textColor: "#344767",
          spotlightShadow: "0 0 15px rgba(0,0,0,0.5)",
        },
        tooltip: {
          width: "450px",
          borderRadius: 8,
        },
        buttonBack: {
          color: "#344767",
          borderRadius: "0,5rem",
          padding: "0.575rem 0.8rem",
        },
        buttonNext: {
          backgroundColor: "rgb(97, 157, 142)",
          borderRadius: 8,
          padding: "0.575rem 0.8rem",
        },
        spotlightLegacy: {
          boxShadow: "none",
        },
      }}
    />
  ) : null;
};
