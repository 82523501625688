import { ReactNode } from "react";
import { ToggleButtonGroup } from "@mui/material";

interface SidenavListProps {
  children: ReactNode;
}

function SidenavList({ children }: SidenavListProps) {
  return (
    <ToggleButtonGroup
      sx={{
        pl: 2,
        ml: 3,
      }}
    >
      {children}
    </ToggleButtonGroup>
  );
}

export default SidenavList;
