import breakpoints from "@theme/base/breakpoints";

import pxToRem from "@theme/functions/pxToRem";

// types
type Types = any;

const {
  values: { xs, sm, md, lg, xl, xxl },
} = breakpoints;

const XS = `@media (min-width: ${xs}px)`;
const SM = `@media (min-width: ${sm}px)`;
const MD = `@media (min-width: ${md}px)`;
const LG = `@media (min-width: ${lg}px)`;
const XL = `@media (min-width: ${xl}px)`;
const XXL = `@media (min-width: ${xxl}px)`;

const sharedClasses = {
  paddingRight: `${pxToRem(24)} !important`,
  paddingLeft: `${pxToRem(24)} !important`,
  marginRight: "auto !important",
  marginLeft: "auto !important",
  width: "100% !important",
  position: "relative",
  "&.containerHeader": {
    minHeight: "600px",
    paddingBottom: "80px",
    paddingTop: "80px",
  },
};
const container: Types = {
  [XS]: {
    ".MuiContainer-root": {
      "&.containerHeader": {
        paddingBottom: "80px",
        paddingTop: "90px",
      },
    },
  },
  [SM]: {
    ".MuiContainer-root": {
      ...sharedClasses,
      maxWidth: "540px !important",
      "&.containerHeader": {
        paddingBottom: "80px",
        paddingTop: "90px",
      },
    },
  },
  [MD]: {
    ".MuiContainer-root": {
      ...sharedClasses,
      maxWidth: "720px !important",
      "&.containerHeader": {
        paddingBottom: "80px",
        paddingTop: "100px",
      },
    },
  },
  [LG]: {
    ".MuiContainer-root": {
      ...sharedClasses,
      maxWidth: "960px !important",
    },
  },
  [XL]: {
    ".MuiContainer-root": {
      ...sharedClasses,
      maxWidth: "1140px !important",
    },
  },
  [XXL]: {
    ".MuiContainer-root": {
      ...sharedClasses,
      maxWidth: "1320px !important",
    },
  },
};

export default container;
