import React, { ReactElement, useState } from "react";

import MenuItem from "@mui/material/MenuItem";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle, faClock } from "@fortawesome/pro-solid-svg-icons";

import { faCircle } from "@fortawesome/pro-regular-svg-icons";
import SusBox from "@components/SusBox";
import SusTypography from "@components/SusTypography";
import { menuItem } from "@components/NotificationSettingsPanel/styles";
import { IconButton } from "@mui/material";

const makeReadableTimeDifference = (date: Date) => {
  const diff = new Date().getTime() - date.getTime();
  const minutesdiff = Math.floor(diff / 1000 / 60);
  const hoursdiff = Math.floor(minutesdiff / 60);
  const daysdiff = Math.floor(hoursdiff / 24);

  if (minutesdiff < 1) {
    return "< 1 minute";
  }
  if (hoursdiff < 1) {
    return `${minutesdiff} minutes`;
  }
  if (daysdiff < 1) {
    return `${hoursdiff} hours`;
  }
  return `${daysdiff} days`;
};

interface NotificationItemProps {
  color?: "primary" | "secondary" | "info" | "success" | "warning" | "error" | "light" | "dark";
  image: ReactElement;
  title: string;
  messageActor: string;
  message: string;
  unread: boolean;
  date: Date;
  handleMessageClick: () => void;
  handleIconClick: () => void;

  [x: string]: any;
}

const NotificationItem: React.FC<React.PropsWithChildren<NotificationItemProps>> = ({
  color = "dark",
  title,
  messageActor,
  message,
  unread,
  date,
  handleMessageClick,
  handleIconClick,
  ...rest
}) => {
  const [iconHover, setIconHover] = useState(false);

  const toggleIconHover = () => setIconHover(!iconHover);

  return (
    <MenuItem {...rest} sx={(theme) => menuItem(theme)}>
      <SusBox mr={1} color={color} onMouseEnter={toggleIconHover} onMouseLeave={toggleIconHover}>
        <IconButton
          onClick={handleIconClick}
          sx={{
            color: color,
          }}
        >
          {!iconHover && unread && <FontAwesomeIcon icon={faCircle} size="xs" />}
          {iconHover && unread && <FontAwesomeIcon icon={faCheckCircle} size="xs" />}
          {!iconHover && !unread && <FontAwesomeIcon icon={faCheckCircle} size="xs" />}
          {iconHover && !unread && <FontAwesomeIcon icon={faCircle} size="xs" />}
        </IconButton>
      </SusBox>
      <SusBox sx={{ maxWidth: "400px" }} onClick={handleMessageClick}>
        <SusTypography variant="subtitle2" fontWeight="regular" mb={1}>
          {title}
        </SusTypography>
        <SusBox
          display="flex"
          justifyContent="center"
          alignItems="left"
          variant="contained"
          borderRadius="md"
          flexDirection="column"
          p={1}
          sx={{ Width: "100%", backgroundColor: "#EDEEF1" }}
        >
          {/* if Comment */}
          <SusTypography variant="small" fontWeight="regular" color="secondary">
            {messageActor} writes:
          </SusTypography>
          {/* End Comment */}
          <SusTypography display="block" variant="body2" fontWeight="regular" noWrap>
            {message}
          </SusTypography>
        </SusBox>
        <SusBox
          flexDirection="row"
          display="flex"
          justifyContent="start"
          alignItems="center"
          mt={1}
          color="secondary"
        >
          <FontAwesomeIcon icon={faClock} size="xs" />
          <SusTypography variant="caption" color="secondary" ml={0.5}>
            {makeReadableTimeDifference(date)} {" ago"}
          </SusTypography>
        </SusBox>
      </SusBox>
    </MenuItem>
  );
};
export default NotificationItem;
