import { API_URI } from "./constants";
import { CallOff, Contract, ContractPosition } from "./types";
import { generatePath } from "react-router-dom";

// Frontend
const baseUrl = "/";

// API
export const apiBaseUrl = `${API_URI}v1/`;

export const paths = {
  home: `${baseUrl}contracts`,
  notFound: `${baseUrl}contracts`,
  /**
   * Auth
   */
  settings: `${baseUrl}preferences`,
  signIn: `${baseUrl}sign-in/`,
  confirmEmail: `${baseUrl}confirm-email/:key`,
  resetPassword: `${baseUrl}reset-password/`,
  resetPasswordEnterNew: `${baseUrl}reset-password/enter-new/`,
  /**
   * Modules
   */
  controlRoom: `${baseUrl}control-room`,
  contractOverview: `${baseUrl}contracts`,
  contractDetail: `${baseUrl}contracts/:id`,
  contractPositionDetail: `${baseUrl}contracts/:contractId/:id`,
  productOverview: `${baseUrl}products`,
  warehousingOverview: `${baseUrl}warehousing`,
  warehousingDetail: `${baseUrl}warehousing/:id`,
  callOffOverview: `${baseUrl}call-offs`,
  callOffDetail: `${baseUrl}call-offs/:id`,
  materialInTransitOverview: `${baseUrl}material-in-transit`,
  materialInTransitDetail: `${baseUrl}material-in-transit/:id`,
  upcomingVesselsOverview: `${baseUrl}upcoming-vessels`,
  upcomingVesselDetail: `${baseUrl}upcoming-vessels/:id`,
};

export const apiPaths = {
  /**
   * Auth
   */
  login: `auth/login/`,
  mfa: `auth/mfa/`,
  logout: `auth/logout/`,
  resetPassword: `auth/password/reset/`,
  setNewPassword: `auth/password/reset/confirm/`,
  changePassword: `auth/password/change/`,
  confirmEmail: `auth/confirm-email/:key/`,
  /**
   * Support
   */
  supportRequest: `support/contact/`,
  reorderRequest: `support/reorder/`,
  /**
   * Data
   */
  comment: `comments/:id/`,
  comments: `comments/`,
  notifications: `notifications/`,
  readNotifications: `notifications/?unread=False`,
  unreadNotifications: `notifications/?unread=True`,
  notification: `notifications/:id/`,
  notificationSettings: `notification-settings/`,
  user: "users/:id/",
  currentUser: "current-user/",
  companyMemberships: "current-user/companies/",
  companyMembership: "current-user/companies/:companyId/",
  superUsers: "superusers/",
  contracts: "contracts/",
  contractDetail: "contracts/:id/",
  contractDetailPositions: "contracts/:id/positions/",
  contractFileUpload: `contracts/:id/file/`,
  contractPositions: "contract-positions/",
  contractPositionDetail: "contract-positions/:id/",
  contractPositionFileUpload: "contract-positions/:id/file/",
  callOffs: "call-offs/",
  callOffDetail: "call-offs/:id/",
  callOffDetailPositions: "call-offs/:id/positions/",
  callOffFileUpload: "call-offs/:id/file/",
  callOffPositions: "call-off-positions/",
  callOffPositionDetail: "call-off-positions/:id/",
  callOffPositionFileUpload: "call-off-positions/:id/file/",
  companies: "companies/",
  company: "companies/:id/",
  companyMembers: "companies/:id/members/",
  invitation: "companies/:companyId/invitations/:id/",
  resendInvitation: "companies/:companyId/invitations/:id/resend/",
  invitations: "companies/:companyId/invitations/",
  aggregate: "aggregate/:objectClass/:filterField/:filterValue/:sumField/:groupByField?/",
};

export const getContractPositionHref = (pos: ContractPosition) => {
  if (pos && pos?.id && pos?.contract) {
    return generatePath(paths.contractPositionDetail, {
      contractId: pos.contract.toString(),
      id: pos.id.toString(),
    });
  } else {
    return "#";
  }
};

export const getContractHref = (contract: Contract) => {
  if (contract && contract?.id) {
    return generatePath(paths.contractDetail, {
      id: contract.id.toString(),
    });
  } else {
    return "#";
  }
};

export const getCallOffHref = (co: CallOff) => {
  if (co && co?.id) {
    return generatePath(paths.callOffDetail, {
      id: co.id.toString(),
    });
  } else {
    return "#";
  }
};
