// React
import React, { useEffect } from "react";

// Material UI
import Icon from "@mui/material/Icon";

// Components
import { DateTime } from "luxon";
import { ceil } from "lodash";
import { setOpenConfigurator, useSoftUIController } from "@src/context";
import useAlerts from "@hooks/useAlerts";
import useAuth from "@hooks/useAuth";
import { useDashboardState } from "@src/app.state";
import SusBox from "@components/SusBox";
import Sidenav from "@src/templates/Sidenav";
import generateRoutes from "@src/routes";
import DashboardLayout from "@src/templates/LayoutContainers/DashboardLayout";
import DashboardNavbar from "@components/DashboardNavbar/DashboardNavbar";

const ContentFrame: (props: React.PropsWithChildren<any>) => React.ReactElement = ({
  children,
}: React.PropsWithChildren<any>) => {
  const [controller, dispatch] = useSoftUIController();
  const { openConfigurator, sidenavColor } = controller;
  const { notifySuccess, notifyWarning } = useAlerts();
  const { user, logout, sessionExpiry } = useAuth();
  const { setCurrentDialog, loading, viewSettings } = useDashboardState();

  const handleSignOut = () => {
    logout?.();
    notifySuccess({
      title: "Success",
      content: "You successfully signed out.",
      autoHideDuration: 5000,
    });
  };

  const checkSessionExpiration = () => {
    if (!user) {
      return;
    }
    const sessionTimeLeft = sessionExpiry && sessionExpiry.diff(DateTime.now()).as("minute");
    if (sessionTimeLeft && sessionTimeLeft <= 0) {
      logout?.();
      notifySuccess({
        title: "Logout",
        content: "You have been automatically logged out because of inactivity.",
      });
    } else if (sessionTimeLeft && sessionTimeLeft < 5) {
      notifyWarning({
        title: "Still there?",
        content: `If you do not take any action, you will be automatically logged out in ${ceil(
          sessionTimeLeft,
          0
        )} minutes.`,
      });
    }
  };

  useEffect(() => {
    const interval = setInterval(checkSessionExpiration, 15 * 1000);
    return () => clearInterval(interval);
  }, [user?.id, sessionExpiry]);

  useEffect(() => {
    const onVisibilityChange = () => {
      if (!document.hidden) {
        checkSessionExpiration();
      }
    };
    document.addEventListener("visibilitychange", onVisibilityChange);
    return () => document.removeEventListener("visibilitychange", onVisibilityChange);
  });

  // Change the openConfigurator state
  const handleConfiguratorOpen = () => setOpenConfigurator(dispatch, !openConfigurator);

  const configsButton = (
    <SusBox
      display="flex"
      justifyContent="center"
      alignItems="center"
      width="3.5rem"
      height="3.5rem"
      bgColor="white"
      shadow="sm"
      borderRadius="50%"
      position="fixed"
      right="2rem"
      bottom="2rem"
      zIndex={99}
      color="dark"
      sx={{ cursor: "pointer" }}
      onClick={handleConfiguratorOpen}
    >
      <Icon color="inherit">settings</Icon>
    </SusBox>
  );

  const experimental = false;

  const content = loading ? (
    <>
      {/*      <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justifyContent="center"
        style={{ minHeight: "100vh" }}
      >
        <Grid item xs={3}>
          <SyncLoader color="#8392ab" />
        </Grid>
      </Grid>*/}
      {children}
    </>
  ) : (
    children
  );

  return (
    <>
      {experimental && configsButton}
      {user ? (
        <>
          <Sidenav
            color={sidenavColor}
            brandName="PEK Dashboard"
            routes={generateRoutes(setCurrentDialog, viewSettings).filter((route) => route.visible)}
          />
          <DashboardLayout>
            <DashboardNavbar handleSignOut={handleSignOut} />
            {content}
          </DashboardLayout>
        </>
      ) : (
        children
      )}
    </>
  );
};

export default ContentFrame;
