import React, { useState } from "react";
import { CircularProgress } from "@mui/material";
import { Document, Page } from "react-pdf";

import useStyles from "./styles";
import SusBox from "@components/SusBox";

interface SusDocumentViewProps {
  url: string;
}

const SusDocumentView: React.FC<React.PropsWithChildren<SusDocumentViewProps>> = ({ url }) => {
  const [numPages, setNumPages] = useState<number | null>(null);

  interface onDocumentLoadSuccessProps {
    numPages: number;
  }

  function onDocumentLoadSuccess({ numPages }: onDocumentLoadSuccessProps) {
    setNumPages(numPages);
  }

  const classes = useStyles();

  const loadingProgress = (
    <SusBox
      p={2}
      textAlign="center"
      display="flex"
      justifyContent="center"
      alignItems="center"
      sx={{ height: "70vh", width: "100%" }}
    >
      <CircularProgress />
    </SusBox>
  );

  return (
    <SusBox className={classes.document}>
      <Document
        file={{ url }}
        options={{ withCredentials: true }}
        onLoadSuccess={onDocumentLoadSuccess}
        loading={loadingProgress}
      >
        {Array.from(new Array(numPages), (el, index) => (
          <Page key={`page_${index + 1}`} pageNumber={index + 1} />
        ))}
      </Document>
    </SusBox>
  );
};

export default SusDocumentView;
