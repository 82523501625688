/**
 * Generates colors for each label based on the first letter of the label.
 * @param labels
 * @param colorMap
 * @param palette
 */
export function generateColorsByLetter(
  labels: (string | null)[],
  colorMap: { [letter: string]: string },
  palette: string[]
): string[] {
  const colors: string[] = [];

  if (!labels?.length || !labels[0]) {
    return [];
  }

  // Convert labels to array of letters
  const labelLetters = labels.map((label) => label?.toLowerCase().replace(/[^a-z]/g, "") || "?");

  // Generate colors for each letter
  for (let i = 0; i < labelLetters.length; i++) {
    const letter = labelLetters[i][0];
    const color = colorMap[letter] || palette[i % palette.length]; // Use palette if no color for letter is found
    colors.push(color);
  }

  return colors;
}
