import SusDialog from "@components/SusDialog/SusDialog";
import SusTypography from "@components/SusTypography";
import React from "react";
import SusButton from "@components/SusButton";
import { useDashboardState } from "@src/app.state";
import SusBox from "@components/SusBox";

const AboutDialog: React.FC = () => {
  const { currentDialog, setCurrentDialog } = useDashboardState();

  const handleClose = () => {
    setCurrentDialog(null);
  };

  return (
    <SusDialog
      title={`About PEK Dashboard`}
      open={currentDialog.type === "AboutDialog"}
      onClose={handleClose}
      sx={{ "& .MuiPaper-root": { width: "420px" } }}
    >
      <SusBox p={2} sx={{ textAlign: "center" }}>
        <SusTypography variant="h6" fontWeight="medium" textTransform="uppercase" mb={2}>
          The PEK Dashboard is designed to support the interaction between Erzkontor and its
          customers.
        </SusTypography>

        <SusTypography variant="body2">
          PEK Dashboard is a product of
          <br /> pektogram GmbH
        </SusTypography>
        <SusTypography variant="small">
          Glockengießerwall 3
          <br />
          20095 Hamburg
        </SusTypography>
        <SusBox mt={4} mb={1}>
          <SusButton
            variant="text"
            target="_blank"
            href="https://pektogram.com/privacy-policy/"
            sx={{
              color: "#344767",
              "&:hover": {
                color: "#6c757d",
              },
            }}
          >
            Privacy policy
          </SusButton>
        </SusBox>
        <SusBox mt={1} mb={1}>
          <SusButton
            variant="text"
            target="_blank"
            href="https://pektogram.com/imprint/"
            sx={{
              color: "#344767",
              "&:hover": {
                color: "#6c757d",
              },
            }}
          >
            Imprint
          </SusButton>
        </SusBox>
      </SusBox>
    </SusDialog>
  );
};

export default AboutDialog;
