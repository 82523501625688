import React from "react";
import VesselFinder from "@components/VesselFinder/VesselFinder";

interface IWidgetVesselFinderProps {
  title: string;
  imo?: number;
  onRemove?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

const WidgetVesselFinder: React.FC<React.PropsWithChildren<IWidgetVesselFinderProps>> = ({
  title,
  imo,
  onRemove,
}) => {
  return (
    <>
      <VesselFinder title={title} imo={imo} height={"100%"} onRemove={onRemove} />
    </>
  );
};

export default WidgetVesselFinder;
