export const teamSelectCheckbox = {
  border: "none",

  "&.Mui-checked": {
    backgroundImage: "none",
  },
  '&.MuiAutocomplete-option[aria-selected="true"]': {
    backgroundColor: "transparent !important",
  },
};

export const teamSelectDialog = {
  justifyContent: "space-between",

  "& .MuiDialog-container": {
    alignItems: "start",
  },
  "& .MuiDialogActions-root": {
    justifyContent: "space-between",
  },
};
