import root from "@theme/components/accordion/root";

type Types = any;

const accordion: Types = {
  styleOverrides: {
    root: { ...root },
  },
};

export default accordion;
