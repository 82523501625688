import React from "react";

import { Grid } from "@mui/material";

import { Helmet } from "react-helmet-async";
import useAuth from "@hooks/useAuth";
import { useDashboardState } from "@src/app.state";
import CompanyDetails from "./CompanyDetails";
import TabsFormSection from "@components/FormularSection/TabsFormSection";
import SusBox from "@components/SusBox";
import SusConnectedCompaniesWidget from "@components/SusConnectedCompaniesWidget/SusConnectedCompaniesWidget";
import SusCompanyUserInvite from "@components/SusSettingsList/SusCompanyUserInvite";
import SusCompanyUserList from "@components/SusSettingsList/SusCompanyUserList";

const CompanyTabPage: React.FC = () => {
  const { user } = useAuth();
  const { currentCompany } = useDashboardState();
  const companyId = currentCompany?.companyId;

  const isAdmin = currentCompany?.role === "admin" || user?.isSuperuser || false;

  return !companyId ? null : (
    <>
      <Helmet>
        <title>Company - Preferences | PEK Dashboard </title>
      </Helmet>

      <CompanyDetails
        companyId={companyId}
        hasUpdateCompanyPermission={isAdmin}
        isSuperuser={user?.isSuperuser}
      />
      <TabsFormSection
        title="Company User"
        description="Here you can find all users of your company."
        direction="column"
      >
        <Grid container>
          <Grid item xs={12} sx={{ overflow: "auto" }}>
            <SusCompanyUserList companyId={companyId} hasEditUserPermission={isAdmin} />
          </Grid>
        </Grid>
      </TabsFormSection>
      {isAdmin && (
        <TabsFormSection
          title="Invitations"
          description="Here you can find all invited users of your company and invite new users."
          direction="column"
        >
          <Grid container>
            <Grid item xs={12} sx={{ overflow: "auto" }}>
              <SusCompanyUserInvite companyId={companyId} hasInvitePermission={isAdmin} />
            </Grid>
          </Grid>
        </TabsFormSection>
      )}

      <TabsFormSection
        title={
          currentCompany?.companyIsDistributor ? "Connected Customers" : "Connected Distributors"
        }
        description={
          currentCompany?.companyIsDistributor
            ? "Here you can find all Customers which are connected to your company profile."
            : "Here you can find all Distributors which are connected to your company profile."
        }
        direction="column"
      >
        <SusBox sx={{ margin: "-16px" }}>
          <SusConnectedCompaniesWidget companyId={companyId} />
        </SusBox>
      </TabsFormSection>
    </>
  );
};

export default CompanyTabPage;
