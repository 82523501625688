import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
  authCode: {
    '& input[type="number"]::-webkit-outer-spin-button, & input[type="number"]::-webkit-inner-spin-button':
      {
        appearance: "none",
        margin: 0,
      },
    display: "grid !important",
    gridGap: "10px",
    gridRow: "1",
    gridTemplateColumns: "repeat(6, 1fr)",

    "& input": {
      "&:focus-visible": {
        outline: "1px solid black !important",
      },
    },

    "& input[type='number']": {
      MozAppearance: "textfield",
    },
  },
});

export default useStyles;
