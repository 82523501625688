import React, { useEffect, useMemo } from "react";

// react-router components
import { Navigate, Route, Routes, useLocation, useParams } from "react-router-dom";

// @mui material components
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";

import { HelmetProvider } from "react-helmet-async";

import { library } from "@fortawesome/fontawesome-svg-core";
import { faCheckSquare, faCoffee } from "@fortawesome/free-solid-svg-icons";

import { LocalizationProvider } from "@mui/x-date-pickers-pro";
import { AdapterLuxon } from "@mui/x-date-pickers-pro/AdapterLuxon";

import { configure as configureAxiosHooks } from "axios-hooks";
import { Backdrop } from "@mui/material";

import RequireAuth from "@components/RequireAuth";
import dashboardAxios from "@src/api";

import { Tour } from "./features/tour";
import DashboardStateManager from "@components/DashboardStateManager/DashboardStateManager";
import Alerts from "@components/Alerts/Alerts";
import { SyncLoader } from "react-spinners";
import ContentFrame from "@src/templates/ContentFrame";
import Dialogs from "@components/Dialogs/Dialogs";
import { AlertsProvider } from "@src/context/AlertsProvider";
import { ServiceWorkerProvider } from "@src/context/ServiceWorkerProvider";
import { AuthProvider } from "@src/context/AuthProvider";
import { NotificationsProvider } from "@src/context/NotificationsProvider";
import { useDashboardState } from "@src/app.state";
import { paths } from "@src/paths";
import { SERVICE_WORKER_TIMEOUT } from "@src/constants";
import { useSoftUIController } from "@src/context";
import theme from "@theme/index";
import generateRoutes from "@src/routes";

library.add(faCheckSquare, faCoffee);

export default function App() {
  const [controller] = useSoftUIController();
  const helmetContext = {};
  const { direction } = controller;
  const { pathname, search } = useLocation();
  const params = useParams();
  const {
    setCurrentDialog,
    locale,
    currentCompany,
    viewSettings,
    viewConfigKey,
    loading,
    currentDialog,
  } = useDashboardState();

  // Setting the dir attribute for the body element
  useEffect(() => {
    document.body.setAttribute("dir", direction);
  }, [direction]);

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    if (document.scrollingElement?.scrollTop) {
      document.scrollingElement.scrollTop = 0;
    }
  }, [pathname]);

  const routes = useMemo(
    () =>
      generateRoutes(setCurrentDialog, viewSettings).map((route) => {
        // if (route.collapse) {
        //   return getRoutes(route.collapse);
        // }

        const hasPermission = !route.permission || route.permission(currentCompany?.role);
        if (route.component && route.public && route.visible && hasPermission) {
          return <Route path={route.route} element={<route.component />} key={route.key} />;
        }
        if (route.route && route.component) {
          return (
            <Route
              path={route.route}
              key={route.key}
              element={
                <RequireAuth redirect={paths.signIn}>
                  {hasPermission && route.visible ? (
                    <route.component {...params} />
                  ) : viewConfigKey === "None" ? null : (
                    <Navigate to={`${paths.home}${search}`} replace />
                  )}
                </RequireAuth>
              }
            />
          );
        }

        return null;
      }),
    [viewSettings]
  );

  configureAxiosHooks({ axios: dashboardAxios });

  return (
    <ThemeProvider theme={theme}>
      <LocalizationProvider dateAdapter={AdapterLuxon} adapterLocale={locale}>
        <HelmetProvider context={helmetContext}>
          <AlertsProvider>
            <ServiceWorkerProvider timeout={SERVICE_WORKER_TIMEOUT} />
            <AuthProvider>
              <Tour />
              <DashboardStateManager>
                <NotificationsProvider>
                  <CssBaseline />
                  <Alerts />
                  <Backdrop
                    sx={{
                      color: "#fff",
                      backgroundColor: "rgba(255, 255, 255, 0.5)",
                      zIndex: (theme) => theme.zIndex.drawer + 1,
                    }}
                    open={loading}
                  >
                    <SyncLoader color="#8392ab" />
                  </Backdrop>
                  <ContentFrame>
                    {/* Show contents only when the setup dialog is not open */}
                    {currentDialog.type === "SetupDialog" ? null : (
                      <Routes>
                        {routes}
                        <Route path="*" element={<Navigate to={paths.notFound} replace />} />
                      </Routes>
                    )}
                    <Dialogs />
                  </ContentFrame>
                </NotificationsProvider>
              </DashboardStateManager>
            </AuthProvider>
          </AlertsProvider>
        </HelmetProvider>
      </LocalizationProvider>
    </ThemeProvider>
  );
}
