import { Chip, Tooltip, Typography, Zoom } from "@mui/material";
import React from "react";

import { useLocation, useNavigate } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen } from "@fortawesome/pro-solid-svg-icons";
import useAuth from "@hooks/useAuth";
import { useDashboardState } from "@src/app.state";
import { CompanyMembership, possibleViewKeyDependentLocalStorageKeys } from "@src/types";
import { composeOneLineAddress } from "@src/utils/helpers";
import SusAvatar from "@components/SusAvatar";
import pxToRem from "@src/assets/theme/functions/pxToRem";
import SusBox from "@components/SusBox";
import { MAX_FAVORITE_COMPANIES } from "@src/constants";
import { TeamSwitchDialog } from "@src/features/team-switch/TeamSwitchDialog";
import SusButton from "@components/SusButton";

interface TeamSwitchProps {
  color?: "primary" | "secondary" | "info" | "success" | "warning" | "error" | "light" | "dark";
}

export const TeamSwitch: React.FC<React.PropsWithChildren<TeamSwitchProps>> = () => {
  const { user, updateUser } = useAuth();
  const [open, setOpen] = React.useState(false);
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const parentPath = pathname.split("/")[1];
  const { currentCompany, setCurrentCompany, favoriteCompanies, setFavoriteCompanies } =
    useDashboardState();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSave = (newFavoriteCompanies: CompanyMembership[]) => {
    setFavoriteCompanies?.(newFavoriteCompanies);
    updateUser?.({
      config: {
        ...user?.config,
        favoriteCompanyIds: newFavoriteCompanies.map((compMembership) => compMembership.companyId),
      },
    });
    handleClose();
  };

  const handleCompanySwitch = (company: CompanyMembership) => {
    const currentViewConfigKey = currentCompany?.viewConfigKey;
    const newViewConfigKey = company.viewConfigKey;
    if (currentViewConfigKey && newViewConfigKey && currentViewConfigKey !== newViewConfigKey) {
      possibleViewKeyDependentLocalStorageKeys.forEach((key) => localStorage.removeItem(key));
    }
    setCurrentCompany?.(company);
    updateUser?.({
      config: { ...user?.config, currentCompanyId: company.companyId },
    });
    if (parentPath) {
      navigate(parentPath);
    }
  };
  const TeamChip: React.FC<React.PropsWithChildren<{ company: CompanyMembership }>> = ({
    company,
  }) => {
    const companyAddress = composeOneLineAddress(
      company.companyStreet,
      company.companyZipCode,
      company.companyCity,
      company.companyCountry
    );

    return (
      <Tooltip
        title={
          <>
            {company.companyName}
            {companyAddress && (
              <>
                <br />
                {companyAddress}
              </>
            )}
          </>
        }
        placement="bottom"
        TransitionComponent={Zoom}
        enterDelay={500}
      >
        <Chip
          avatar={
            <SusAvatar
              name={
                company.companyName.length > 12
                  ? `${company.companyName.substring(0, 14)}`
                  : company.companyName
              }
            />
          }
          key={company.companyId}
          label={
            <Typography
              variant="button"
              textOverflow="ellipsis"
              sx={{ overflow: "hidden", textTransform: "initial" }}
            >
              {company.companyName}
            </Typography>
          }
          variant={company.companyId === currentCompany?.companyId ? "filled" : "outlined"}
          onClick={() => handleCompanySwitch(company)}
          sx={{
            maxWidth: pxToRem(150),
            margin: pxToRem(4),
            "& .MuiChip-avatar": {
              fontSize: "9px",
            },
            "&.MuiChip-filled": {
              border: "1px solid transparent",
            },
          }}
        />
      </Tooltip>
    );
  };

  return (
    <SusBox className={"teamSwitch"}>
      {currentCompany &&
        favoriteCompanies &&
        !favoriteCompanies.find((company) => company.companyId === currentCompany.companyId) && (
          <TeamChip company={currentCompany} />
        )}
      {favoriteCompanies?.map((company) => <TeamChip key={company.companyId} company={company} />)}

      {(user?.isSuperuser || (user?.nCompanyMemberships || 0) > MAX_FAVORITE_COMPANIES) && (
        <SusBox>
          <SusButton
            size="small"
            color="secondary"
            variant="text"
            onClick={handleClickOpen}
            sx={{ minWidth: "" }}
          >
            <FontAwesomeIcon icon={faPen} />
          </SusButton>
          <TeamSwitchDialog
            open={open}
            onClose={handleClose}
            onSave={handleSave}
            favoriteCompanies={favoriteCompanies}
          />
        </SusBox>
      )}
    </SusBox>
  );
};
