import Cookies from "js-cookie";

import { User } from "../types";
import { TRACKING_ID, TRACKING_SCRIPT_ID } from "../constants";

function getTrackingScriptElement() {
  return document.getElementById(TRACKING_SCRIPT_ID);
}

export function loadTagManager() {
  const script = getTrackingScriptElement();
  if (script) {
    return;
  }
  const d = document,
    g = d.createElement("script"),
    s = d.getElementsByTagName("script")[0];
  g.async = true;
  g.id = TRACKING_SCRIPT_ID;
  g.src = `https://tracking.rocki.rocks/js/container_${TRACKING_ID}.js`;
  s?.parentNode?.insertBefore(g, s);
}

export function unloadTagManager() {
  const script = getTrackingScriptElement();
  if (!script) {
    return;
  }

  const allCookies = Cookies.get();
  Object.keys(allCookies)
    .filter(
      (key) =>
        key.startsWith("_pk_ses") ||
        key.startsWith("_pk_id") ||
        key.startsWith("_pk_id") ||
        key.startsWith("_pk_hsr") ||
        key.startsWith("_pk_cvar") ||
        key.startsWith("_pk_ref") ||
        key.startsWith("MATOMO_SESSID")
    )
    .map((key) => Cookies.remove(key));

  script?.parentNode?.removeChild(script);
  location.reload();
}

export function trackUserChanged(newUser: User) {
  window._mtm.push({ userId: newUser?.id });
  if (newUser?.companies?.length === 1) {
    window._mtm.push({ teamId: newUser.companies[0].companyId });
    window._mtm.push({ teamName: newUser.companies[0].companyName });
  }
}

export const sendMatomoEvent = (category: string, action: string, label: string): void => {
  if (typeof window !== "undefined" && window._mtm) {
    window._mtm.push({
      event: "customEvent",
      eventData: {
        category, // Kurzschreibweise für category: category
        action, // Kurzschreibweise für action: action
        label, // Kurzschreibweise für label: label
      },
    });
  }
};
