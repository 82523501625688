import {
  faAbacus,
  faCalendarStar,
  faChartPieSimple,
  faShip,
} from "@fortawesome/pro-regular-svg-icons";

import { faNotes } from "@fortawesome/pro-light-svg-icons";
import {
  WidgetConfigurationGetter,
  WidgetLayoutsGetter,
  WidgetSpecs,
} from "@src/features/control-room";
import {
  ISimpleAggregationTileProps,
  SimpleAggregationTile,
} from "@components/widgets/SimpleAggregationTile/SimpleAggregationTile";
import { Status, statusDetails } from "@src/types";
import { ISimpleAggregationChartProps } from "@components/widgets/Charts/SimpleAggregationChart/SimpleAggregationChart";
import SimpleAggregationChart from "@components/widgets/Charts/SimpleAggregationChart";
import { formatData } from "@src/utils/typing";
import VesselFinder from "@components/widgets/VesselFinder/VesselFinder";
import Notes from "@components/widgets/Notes";
import { faWarehouse } from "@fortawesome/pro-solid-svg-icons";

const aggWidget: (
  locale: string
) => WidgetSpecs<ISimpleAggregationTileProps<"ContractPosition">> = (locale) => ({
  widgetName: "Contract aggregation",
  icon: faAbacus,
  component: SimpleAggregationTile,
  w: 3,
  h: 1,
  fixedProps: (modifyableProps) => ({
    title: `${statusDetails[modifyableProps.filter as Status]?.label} ${
      modifyableProps.aggregationField === "amount" ? "total volume (MT)" : "volume left (MT)"
    }`,
    objectClass: "ContractPosition",
    icon:
      modifyableProps.filter === "UPCOMING"
        ? faCalendarStar
        : modifyableProps.filter === "IN_TRANSIT"
          ? faShip
          : modifyableProps.filter === "WAREHOUSING"
            ? faWarehouse
            : undefined,
    formatter: (val: any) => formatData(val, "decimal", locale),
  }),
  modifyableProps: [
    {
      name: "filter",
      label: "Status Filter",
      valueMap: (value: string) => ({
        field: "status",
        value,
      }),
      options: [
        { label: "Upcoming", value: "UPCOMING" },
        { label: "In Transit", value: "IN_TRANSIT" },
        { label: "Warehousing", value: "WAREHOUSING" },
      ],
    },
    {
      name: "aggregationField",
      label: "Sum up value",
      options: [
        { label: "Total amount", value: "amount" },
        { label: "Amount left", value: "amountLeft" },
      ],
    },
  ],
});

const chartWidget: (
  locale: string
) => WidgetSpecs<ISimpleAggregationChartProps<"ContractPosition">> = (locale) => ({
  widgetName: "Contract chart",
  icon: faChartPieSimple,
  component: SimpleAggregationChart,
  w: 3,
  h: 2,
  fixedProps: (modifyableProps) => ({
    title: `${statusDetails[modifyableProps.filter as Status]?.label} ${
      modifyableProps.aggregationField === "amount" ? "total volume (MT)" : "volume left (MT)"
    } by ${modifyableProps.groupByField === "productSimple" ? "product" : "vessel"}`,
    objectClass: "ContractPosition",
    formatter: (val: any) => formatData(val, "decimal", locale),
  }),
  modifyableProps: [
    {
      name: "filter",
      label: "Status Filter",
      valueMap: (value: string) => ({
        field: "status",
        value,
      }),
      options: [
        { label: "Upcoming", value: "UPCOMING" },
        { label: "In Transit", value: "IN_TRANSIT" },
        { label: "Warehousing", value: "WAREHOUSING" },
      ],
    },
    {
      name: "groupByField",
      label: "Group by",
      options: [
        { label: "Product", value: "productSimple" },
        { label: "Vessel", value: "vesselName" },
      ],
    },
    {
      name: "aggregationField",
      label: "Display value",
      options: [
        { label: "Total amount", value: "amount" },
        { label: "Amount left", value: "amountLeft" },
      ],
    },
  ],
});

/**
 * Generate a widget registry for the control room using the current locale.
 * @param locale
 */
const getWidgetRegistry = (locale: string) => {
  return [
    aggWidget(locale),
    {
      widgetName: "Single Vessel Tracker",
      icon: faShip,
      component: VesselFinder,
      w: 4,
      h: 2,
      fixedProps: () => ({
        showTrack: true,
        title: "Single Vessel",
      }),
      modifyableProps: [
        {
          name: "imo",
          label: "Vessel IMO",
        },
      ],
    },
    chartWidget(locale),
    {
      widgetName: "Notes",
      title: "Notes",
      icon: faNotes,
      component: Notes,
      fixedSize: true,
      w: 3,
      h: 2,
    },
  ];
};

const getDefaultWidgets: WidgetConfigurationGetter = (locale) => {
  return [
    {
      widget: aggWidget(locale),
      modifyablePropsValues: {
        filter: "WAREHOUSING",
        groupByField: "productSimple",
        aggregationField: "amountLeft",
      },
      x: 2,
      y: 7,
    },
    {
      widget: aggWidget(locale),
      modifyablePropsValues: {
        filter: "IN_TRANSIT",
        groupByField: "productSimple",
        aggregationField: "amount",
      },
      x: 2,
      y: 6,
    },
    {
      widget: aggWidget(locale),
      modifyablePropsValues: {
        filter: "UPCOMING",
        groupByField: "productSimple",
        aggregationField: "amount",
      },
      x: 2,
      y: 8,
    },
    {
      widget: chartWidget(locale),
      modifyablePropsValues: {
        filter: "WAREHOUSING",
        groupByField: "productSimple",
        aggregationField: "amountLeft",
      },
      x: 1,
      y: 4,
    },
    {
      widget: chartWidget(locale),
      modifyablePropsValues: {
        filter: "IN_TRANSIT",
        groupByField: "productSimple",
        aggregationField: "amount",
      },
      x: 3,
      y: 2,
    },
    {
      widget: chartWidget(locale),
      modifyablePropsValues: {
        filter: "UPCOMING",
        groupByField: "productSimple",
        aggregationField: "amount",
      },
      x: 2,
      y: 8,
    },
  ];
};

const getDefaultLayouts: WidgetLayoutsGetter = () => {
  return {
    lg: [
      {
        h: 1,
        i: "0",
        w: 3,
        x: 6,
        y: 2,
        min_h: 1,
        min_w: 2,
        moved: false,
        static: false,
      },
      {
        h: 1,
        i: "1",
        w: 3,
        x: 0,
        y: 2,
        min_h: 1,
        min_w: 2,
        moved: false,
        static: false,
      },
      {
        h: 1,
        i: "2",
        w: 3,
        x: 3,
        y: 2,
        min_h: 1,
        min_w: 2,
        moved: false,
        static: false,
      },
      {
        h: 2,
        i: "3",
        w: 3,
        x: 6,
        y: 0,
        min_h: 2,
        min_w: 2,
        moved: false,
        static: false,
      },
      {
        h: 2,
        i: "4",
        w: 3,
        x: 0,
        y: 0,
        min_h: 2,
        min_w: 2,
        moved: false,
        static: false,
      },
      {
        h: 2,
        i: "5",
        w: 3,
        x: 3,
        y: 0,
        min_h: 2,
        min_w: 2,
        moved: false,
        static: false,
      },
    ],
    md: [
      {
        h: 1,
        i: "0",
        w: 3,
        x: 6,
        y: 2,
        min_h: 1,
        min_w: 2,
        moved: false,
        static: false,
      },
      {
        h: 1,
        i: "1",
        w: 3,
        x: 0,
        y: 2,
        min_h: 1,
        min_w: 2,
        moved: false,
        static: false,
      },
      {
        h: 1,
        i: "2",
        w: 3,
        x: 3,
        y: 2,
        min_h: 1,
        min_w: 2,
        moved: false,
        static: false,
      },
      {
        h: 2,
        i: "3",
        w: 3,
        x: 6,
        y: 0,
        min_h: 2,
        min_w: 2,
        moved: false,
        static: false,
      },
      {
        h: 2,
        i: "4",
        w: 3,
        x: 0,
        y: 0,
        min_h: 2,
        min_w: 2,
        moved: false,
        static: false,
      },
      {
        h: 2,
        i: "5",
        w: 3,
        x: 3,
        y: 0,
        min_h: 2,
        min_w: 2,
        moved: false,
        static: false,
      },
    ],
    sm: [
      {
        h: 1,
        i: "0",
        w: 2,
        x: 4,
        y: 2,
        min_h: 1,
        min_w: 2,
        moved: false,
        static: false,
      },
      {
        h: 1,
        i: "1",
        w: 2,
        x: 0,
        y: 2,
        min_h: 1,
        min_w: 2,
        moved: false,
        static: false,
      },
      {
        h: 1,
        i: "2",
        w: 2,
        x: 2,
        y: 2,
        min_h: 1,
        min_w: 2,
        moved: false,
        static: false,
      },
      {
        h: 2,
        i: "3",
        w: 2,
        x: 4,
        y: 0,
        min_h: 2,
        min_w: 2,
        moved: false,
        static: false,
      },
      {
        h: 2,
        i: "4",
        w: 2,
        x: 0,
        y: 0,
        min_h: 2,
        min_w: 2,
        moved: false,
        static: false,
      },
      {
        h: 2,
        i: "5",
        w: 2,
        x: 2,
        y: 0,
        min_h: 2,
        min_w: 2,
        moved: false,
        static: false,
      },
    ],
    xs: [
      {
        h: 1,
        i: "0",
        w: 2,
        x: 2,
        y: 3,
        min_h: 1,
        min_w: 2,
        moved: false,
        static: false,
      },
      {
        h: 1,
        i: "1",
        w: 2,
        x: 2,
        y: 2,
        min_h: 1,
        min_w: 2,
        moved: false,
        static: false,
      },
      {
        h: 1,
        i: "2",
        w: 2,
        x: 2,
        y: 4,
        min_h: 1,
        min_w: 2,
        moved: false,
        static: false,
      },
      {
        h: 2,
        i: "3",
        w: 2,
        x: 0,
        y: 2,
        min_h: 2,
        min_w: 2,
        moved: false,
        static: false,
      },
      {
        h: 2,
        i: "4",
        w: 2,
        x: 0,
        y: 0,
        min_h: 2,
        min_w: 2,
        moved: false,
        static: false,
      },
      {
        h: 2,
        i: "5",
        w: 2,
        x: 2,
        y: 0,
        min_h: 2,
        min_w: 2,
        moved: false,
        static: false,
      },
    ],
    xxs: [
      {
        h: 1,
        i: "0",
        w: 2,
        x: 0,
        y: 8,
        min_h: 1,
        min_w: 2,
        moved: false,
        static: false,
      },
      {
        h: 1,
        i: "1",
        w: 2,
        x: 0,
        y: 6,
        min_h: 1,
        min_w: 2,
        moved: false,
        static: false,
      },
      {
        h: 1,
        i: "2",
        w: 2,
        x: 0,
        y: 7,
        min_h: 1,
        min_w: 2,
        moved: false,
        static: false,
      },
      {
        h: 2,
        i: "3",
        w: 2,
        x: 0,
        y: 4,
        min_h: 2,
        min_w: 2,
        moved: false,
        static: false,
      },
      {
        h: 2,
        i: "4",
        w: 2,
        x: 0,
        y: 0,
        min_h: 2,
        min_w: 2,
        moved: false,
        static: false,
      },
      {
        h: 2,
        i: "5",
        w: 2,
        x: 0,
        y: 2,
        min_h: 2,
        min_w: 2,
        moved: false,
        static: false,
      },
    ],
  };
};

export default {
  getWidgetRegistry,
  getDefaultWidgets,
  getDefaultLayouts,
};
