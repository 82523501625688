import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRotateRight } from "@fortawesome/pro-solid-svg-icons";

import SusButton from "@components/SusButton";
import { useDashboardState } from "@src/app.state";
import { useServiceWorker } from "@hooks/useServiceWorker";
import useAlerts from "@hooks/useAlerts";

export interface IServiceWorkerContext {
  updateAvailable: boolean;
}

export interface IProps {
  children?: React.ReactNode;
  timeout?: number;
}

export const ServiceWorkerProvider: React.FC<React.PropsWithChildren<IProps>> = ({ timeout }) => {
  const setLoading = useDashboardState((state) => state.setLoading);
  const { update, updateAvailable } = useServiceWorker({ timeout });
  const [reminderTimer, setReminderTimer] = useState<number | null>(null);
  const { notifyWarning, close: closeAlert } = useAlerts();

  const stopReminderTimer = () => {
    if (reminderTimer) {
      clearInterval(reminderTimer);
      setReminderTimer(null);
    }
  };

  const updateInfo = () => {
    const dismissReminderAndUpdate = () => {
      closeAlert();
      setLoading(true);
      stopReminderTimer();
      update();
    };
    notifyWarning({
      content: "To update the application to the latest version, please refresh the page!",
      title: "New version is available!",
      notDismissable: true,
      action: () => (
        <>
          <SusButton
            variant="outlined"
            color="warning"
            size="small"
            onClick={dismissReminderAndUpdate}
            startIcon={<FontAwesomeIcon icon={faArrowRotateRight} size="xs" />}
          >
            Refresh
          </SusButton>
        </>
      ),
    });
  };

  React.useEffect(() => {
    if (updateAvailable && !reminderTimer) {
      setReminderTimer(window.setInterval(updateInfo, 5000));
      updateInfo();
    }
    return stopReminderTimer;
  }, [updateAvailable]);

  return null;
};
