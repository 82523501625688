import React, { useEffect, useState } from "react";

// react-router components
import { Link, useNavigate } from "react-router-dom";

// @material-ui core components
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Menu from "@mui/material/Menu";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Turn as Hamburger } from "hamburger-react";

import MenuItem from "@mui/material/MenuItem";
import Divider from "@mui/material/Divider";
import { faCog, faPowerOff, faUserCircle } from "@fortawesome/pro-solid-svg-icons";
import { setMiniSidenav, setTransparentNavbar, useSoftUIController } from "@src/context";
import useAuth from "@hooks/useAuth";
import SusButton from "@components/SusButton";
import { paths } from "@src/paths";
import {
  navbar,
  navbarIconButton,
  navbarMobileMenu,
  navbarRow,
} from "@components/DashboardNavbar/styles";
import SusBox from "@components/SusBox";
import { Skeleton, Theme } from "@mui/material";
import SusTypography from "@components/SusTypography";
import Breadcrumbs from "@components/Breadcrumbs/Breadcrumbs";
import { TeamSwitch } from "@src/features/team-switch";
import Notifications from "@components/Notifications/Notifications";

interface IDashboardNavbarProps {
  absolute?: boolean;
  light?: boolean;
  isMini?: boolean;
  handleSignOut?: () => void;
  loading?: boolean;
}

const DashboardNavbar: React.FC<React.PropsWithChildren<IDashboardNavbarProps>> = ({
  absolute = false,
  light = false,
  isMini = false,
  handleSignOut = () => {},
  loading,
}) => {
  const [navbarType, setNavbarType] = useState<
    "fixed" | "absolute" | "sticky" | "static" | "relative"
  >();
  const [controller, dispatch] = useSoftUIController();
  // const { miniSidenav, transparentNavbar, fixedNavbar, openConfigurator } = controller;
  const { miniSidenav, transparentNavbar, fixedNavbar } = controller;
  const { user } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    // Setting the navbar type
    if (fixedNavbar) {
      setNavbarType("sticky");
    } else {
      setNavbarType("static");
    }

    // A function that sets the transparent state of the navbar.
    function handleTransparentNavbar() {
      setTransparentNavbar(dispatch, (fixedNavbar && window.scrollY === 0) || !fixedNavbar);
    }

    /**
     The event listener that's calling the handleTransparentNavbar function when
     scrolling the window.
     */
    window.addEventListener("scroll", handleTransparentNavbar);

    // Call the handleTransparentNavbar function to set the state with the initial value.
    handleTransparentNavbar();

    // Remove event listener on cleanup
    return () => window.removeEventListener("scroll", handleTransparentNavbar);
  }, [dispatch, fixedNavbar]);

  const handleMiniSidenav = () => setMiniSidenav(dispatch, !miniSidenav);
  // const handleConfiguratorOpen = () => setOpenConfigurator(dispatch, !openConfigurator);

  // Render the Account menu
  const [openAccountMenu, setOpenAccountMenu] = useState<Element | undefined>();
  const handleOpenAccountMenu = (event: any) => setOpenAccountMenu(event.currentTarget);
  const handleCloseAccountMenu = () => setOpenAccountMenu(undefined);

  const renderAccountMenu = () => (
    <Menu
      anchorEl={openAccountMenu}
      anchorReference={undefined}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      open={!!openAccountMenu}
      onClose={handleCloseAccountMenu}
      sx={{ mt: 2 }}
    >
      <MenuItem onClick={handleCloseAccountMenu}>
        <SusButton
          variant="text"
          sx={navbarIconButton}
          size="small"
          color="dark"
          startIcon={<FontAwesomeIcon icon={faCog} />}
          onClick={() => navigate(paths.settings)}
          className="Preferences-Navigation"
          fullWidth
        >
          Preferences
        </SusButton>
      </MenuItem>
      <Divider sx={{ marginY: "0.2rem !important" }} />
      <MenuItem
        onClick={() => {
          handleCloseAccountMenu();
          handleSignOut();
        }}
      >
        <SusButton
          variant="text"
          sx={navbarIconButton}
          size="small"
          color="error"
          startIcon={<FontAwesomeIcon icon={faPowerOff} />}
          fullWidth
          className="Logout-Navigation"
        >
          Log out
        </SusButton>
      </MenuItem>
    </Menu>
  );

  return (
    <AppBar
      position={absolute ? "absolute" : navbarType}
      color="inherit"
      sx={(theme) => navbar(theme, { transparentNavbar, absolute, light })}
    >
      <Toolbar
        sx={({ breakpoints }: Theme) => ({
          flexDirection: "row",
          alignItems: "flex-start",
          justifyContent: "start",
          pt: 0.5,
          pb: 0.5,

          [breakpoints.up("md")]: {
            alignItems: "center",
            paddingTop: "0",
            paddingBottom: "0",
          },
        })}
      >
        <SusBox sx={navbarMobileMenu}>
          <Hamburger toggled={!miniSidenav} toggle={handleMiniSidenav} size={20} />
        </SusBox>
        <SusBox
          color="inherit"
          mb={{ xs: 0, md: 0 }}
          sx={({ breakpoints }: Theme) => ({
            display: "none",

            [breakpoints.up("sm")]: {
              display: "flex",
              gridArea: "breadcrumbs",
            },
          })}
        >
          {loading ? (
            <>
              <Skeleton width="250px">
                <SusTypography>.</SusTypography>
              </Skeleton>
            </>
          ) : (
            <Breadcrumbs light={light} />
          )}
        </SusBox>
        <TeamSwitch />
        {isMini ? null : (
          <SusBox
            className={"userMenu"}
            sx={(theme: Theme) => navbarRow(theme, { isMini }) && { marginLeft: "auto" }}
          >
            <SusBox color={light ? "white" : "inherit"} sx={loading && { display: "inline-flex" }}>
              {loading ? (
                <SusBox sx={{ display: "flex", alignItems: "center", marginRight: "8px" }}>
                  <Skeleton
                    variant="circular"
                    width={23}
                    height={23}
                    sx={{ display: "inline-flex", alignItems: "center" }}
                  />
                </SusBox>
              ) : (
                <Notifications light />
              )}
              {user ? (
                <>
                  {loading ? (
                    <SusBox sx={{ display: "flex", alignItems: "center" }}>
                      <Skeleton variant="circular" width={23} height={23} />
                      <Skeleton width="123px" sx={{ marginLeft: "4px" }}>
                        <SusTypography>.</SusTypography>
                      </Skeleton>
                    </SusBox>
                  ) : (
                    <>
                      <SusButton
                        variant="text"
                        sx={navbarIconButton}
                        color="dark"
                        className="userProfile"
                        onClick={handleOpenAccountMenu}
                      >
                        <>
                          <FontAwesomeIcon icon={faUserCircle} />
                          <SusTypography variant="button" fontWeight="medium" data-matomo-mask>
                            {user?.firstName} {user?.lastName}
                          </SusTypography>
                        </>
                      </SusButton>
                      {renderAccountMenu()}
                    </>
                  )}
                </>
              ) : (
                <Link to="/authentication/sign-in/basic">
                  <SusButton sx={navbarIconButton} size="small" color="dark">
                    <FontAwesomeIcon icon={faUserCircle} />{" "}
                    <SusTypography variant="button" fontWeight="medium">
                      Sign in
                    </SusTypography>
                  </SusButton>
                </Link>
              )}
            </SusBox>
          </SusBox>
        )}
      </Toolbar>
    </AppBar>
  );
};

export default DashboardNavbar;
