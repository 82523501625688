import { Theme } from "@mui/material/styles";

interface OwnerState {
  miniSidenav?: boolean;
  active?: boolean;
  transparentSidenav?: boolean;
  sidenavColor?: string;
  color?: string;
  noCollapse?: boolean;
  open?: boolean;
}

const collapseIcon = (theme: Theme, ownerState: OwnerState) => {
  const {
    palette: { white, gradients },
  } = theme;
  const { active } = ownerState;

  return {
    color: active ? white.main : gradients.dark.state,
  };
};

function collapseText(theme: Theme, ownerState: OwnerState) {
  const { typography, transitions, breakpoints, functions } = theme;
  const { miniSidenav, transparentSidenav, active } = ownerState;

  const { size, fontWeightMedium, fontWeightRegular } = typography;
  const { pxToRem } = functions;

  return {
    marginLeft: pxToRem(12.8),

    [breakpoints.up("xl")]: {
      opacity: miniSidenav || (miniSidenav && transparentSidenav) ? 0 : 1,
      maxWidth: miniSidenav || (miniSidenav && transparentSidenav) ? 0 : "100%",
      marginLeft: miniSidenav || (miniSidenav && transparentSidenav) ? 0 : pxToRem(12.8),
      transition: transitions.create(["opacity", "margin"], {
        easing: transitions.easing.easeInOut,
        duration: transitions.duration.standard,
      }),
    },

    "& span": {
      fontWeight: active ? fontWeightMedium : fontWeightRegular,
      fontSize: size.sm,
      lineHeight: 0,
    },
  };
}

function collapseArrow(theme: Theme, ownerState: OwnerState) {
  const { palette, typography, transitions, breakpoints, functions } = theme;
  const { noCollapse, transparentSidenav, miniSidenav, open } = ownerState;

  const { dark, gradients } = palette;
  const { size } = typography;
  const { rgba } = functions;

  return {
    fontSize: `${size.md} !important`,
    color: open ? dark.main : rgba(gradients.dark.state, 0.4),
    transition: transitions.create(["color", "transform", "opacity"], {
      easing: transitions.easing.easeInOut,
      duration: transitions.duration.shorter,
    }),

    [breakpoints.up("xl")]: {
      display:
        noCollapse || (transparentSidenav && miniSidenav) || miniSidenav
          ? "none !important"
          : "block !important",
    },
  };
}

export { collapseIcon, collapseText, collapseArrow };
