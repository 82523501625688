import React, { useEffect, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import PropTypes from "prop-types";
import { Box, Divider, Grid, Link } from "@mui/material";
import Logo from "@src/assets/images/cremer_steel_logo.svg";
import { Turn as Hamburger } from "hamburger-react";
import SimpleBar from "simplebar-react";

import SidenavCollapse from "./SidenavCollapse";
import SidenavList from "./SidenavList";
import SidenavItem from "./SidenavItem";
import SidenavRoot from "./SidenavRoot";
import SidenavCard from "./SidenavCard";
import sidenavLogoLabel from "./styles/sidenav";
import SusTypography from "../../components/SusTypography";
import SusBox from "../../components/SusBox";
import { navbarDesktopMenu } from "@components/DashboardNavbar/styles";
import { useDashboardState } from "@src/app.state";
import { setMiniSidenav, useSoftUIController } from "@src/context";

interface SidenavProps {
  color?: "primary" | "secondary" | "info" | "success" | "warning" | "error" | "dark";
  brand?: string;
  brandName: string;
  routes: Array<any>;
}

const Sidenav: React.FC<SidenavProps> = ({ color = "info", brand = "", routes, ...rest }) => {
  const [openCollapse, setOpenCollapse] = useState<string | boolean>(false);
  const [openNestedCollapse, setOpenNestedCollapse] = useState<string | boolean>(false);
  const { currentCompany } = useDashboardState();
  const [controller, dispatch] = useSoftUIController();
  const { miniSidenav, transparentSidenav } = controller;
  const location = useLocation();
  const { pathname } = location;
  const itemName = pathname.split("/").slice(1)[1];

  const handleMiniSidenav = () => {
    setMiniSidenav(dispatch, !miniSidenav);
  };

  useEffect(() => {
    function handleMiniSidenavResize() {
      setMiniSidenav(dispatch, window.innerWidth < 1200);
    }

    window.addEventListener("resize", handleMiniSidenavResize);
    handleMiniSidenavResize();

    return () => window.removeEventListener("resize", handleMiniSidenavResize);
  }, [dispatch, location]);

  const renderNestedCollapse = (collapse: Array<any>) => {
    return collapse.map(({ name, route, key, href }) =>
      href ? (
        <Link
          key={key}
          href={href}
          target="_blank"
          rel="noreferrer"
          style={{ textDecoration: "none" }}
        >
          <SidenavItem name={name} nested />
        </Link>
      ) : (
        <NavLink to={route} key={key} style={{ textDecoration: "none" }}>
          <SidenavItem name={name} active={route === pathname} nested />
        </NavLink>
      )
    );
  };

  const renderCollapse = (collapses: Array<any>) =>
    collapses.map(({ name, collapse, route, href, key }) => {
      let returnValue;

      if (collapse) {
        returnValue = (
          <SidenavItem
            key={key}
            name={name}
            active={key === itemName}
            open={openNestedCollapse === name}
            onClick={() =>
              openNestedCollapse === name
                ? setOpenNestedCollapse(false)
                : setOpenNestedCollapse(name)
            }
          >
            {renderNestedCollapse(collapse)}
          </SidenavItem>
        );
      } else {
        returnValue = href ? (
          <Link
            href={href}
            key={key}
            target="_blank"
            rel="noreferrer"
            style={{ textDecoration: "none" }}
          >
            <SidenavItem name={name} active={key === itemName} />
          </Link>
        ) : (
          <NavLink to={route} key={key} style={{ textDecoration: "none" }}>
            <SidenavItem name={name} active={key === itemName} />
          </NavLink>
        );
      }
      return <SidenavList key={key}>{returnValue}</SidenavList>;
    });

  const renderRoutes = routes.map(
    ({
      type,
      name,
      icon,
      title,
      permission,
      collapse,
      noCollapse,
      betaFeature,
      key,
      href,
      route,
      onClick,
    }) => {
      let returnValue;

      const currentPathBaseMatches =
        route?.split("/")?.slice(1)[0] === pathname.split("/").slice(1)[0];

      if (permission && !permission(currentCompany?.role)) {
        return;
      }

      if (type === "collapse") {
        returnValue = href ? (
          <Link
            href={href}
            key={key}
            target="_blank"
            rel="noreferrer"
            style={{ textDecoration: "none" }}
          >
            <SidenavCollapse
              color={color}
              name={name}
              icon={icon}
              active={currentPathBaseMatches}
              noCollapse={noCollapse}
            />
          </Link>
        ) : (
          <SidenavCollapse
            color={currentPathBaseMatches ? "info" : color}
            key={key}
            name={name}
            icon={icon}
            active={currentPathBaseMatches}
            open={openCollapse === name}
            onClick={() => (openCollapse === name ? setOpenCollapse(false) : setOpenCollapse(name))}
          >
            {collapse ? renderCollapse(collapse) : null}
          </SidenavCollapse>
        );
      } else if (type === "title") {
        returnValue = (
          <SusTypography
            key={key}
            display="block"
            variant="caption"
            fontWeight="bold"
            textTransform="uppercase"
            opacity={0.6}
            pl={3}
            mt={2}
            mb={1}
            ml={1}
          >
            {title}
          </SusTypography>
        );
      } else if (type === "divider") {
        returnValue = <Divider key={key} />;
      } else if (type === "button") {
        returnValue = (
          <SidenavCollapse
            onClick={onClick}
            color={color}
            name={name}
            icon={icon(currentPathBaseMatches)}
            noCollapse
            key={key}
          />
        );
      } else if (type === "simple") {
        returnValue = (
          <SidenavCollapse
            color={color}
            to={route}
            key={key}
            name={name}
            icon={icon(currentPathBaseMatches)}
            active={currentPathBaseMatches}
            betaFeature={betaFeature}
            noCollapse
          />
        );
      }

      return returnValue;
    }
  );

  return (
    <SidenavRoot {...rest} variant="permanent" ownerState={{ transparentSidenav, miniSidenav }}>
      <SimpleBar
        style={{
          width: "100%",
          height: "100%",
          overflow: "auto",
          margin: "5px 0",
          overflowX: "hidden",
        }}
        autoHide={false}
      >
        <Grid
          container
          direction="column"
          justifyContent="space-between"
          alignItems="stretch"
          sx={{ overflow: "hidden", height: "94vh", flexFlow: "column nowrap" }}
        >
          <Grid item>
            <SusBox pt={3} pb={1} px={4} pl={"22px"} textAlign="center">
              <SusBox display="flex" alignItems="center">
                {brand && <SusBox component="img" src={brand} alt="Logo" width="2rem" />}
                <SusBox
                  pr={1}
                  display="flex"
                  color="inherit"
                  mb={{ xs: 1, md: 0 }}
                  sx={navbarDesktopMenu()}
                >
                  <Hamburger toggled={!miniSidenav} toggle={handleMiniSidenav} size={20} />
                </SusBox>
                <SusBox
                  display="flex"
                  color="inherit"
                  mb={{ xs: 1, md: 0 }}
                  sx={navbarDesktopMenu()}
                >
                  <Hamburger toggled={miniSidenav} toggle={handleMiniSidenav} size={20} />
                </SusBox>
                <SusBox
                  display="flex"
                  width={"100%"}
                  sx={(theme: any) => sidenavLogoLabel(theme, { miniSidenav })}
                >
                  <SusBox display="flex" flexDirection="row">
                    <img src={Logo} alt="PEK Dashboard" height="28" />
                  </SusBox>
                </SusBox>
              </SusBox>
            </SusBox>

            <Divider />
            <Box
              className={"SideNavList-wrapper"}
              sx={{
                display: "flex",
                alignItems: "flex-start",
                flexDirection: "column",
                margin: "1rem",
              }}
            >
              {renderRoutes}
            </Box>
          </Grid>
          <Grid item sx={{ width: "100%" }}>
            <SidenavCard />
          </Grid>
        </Grid>
      </SimpleBar>
    </SidenavRoot>
  );
};

Sidenav.propTypes = {
  color: PropTypes.oneOf(["primary", "secondary", "info", "success", "warning", "error", "dark"]),
  brand: PropTypes.string,
  brandName: PropTypes.string.isRequired,
  routes: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default Sidenav;
