// Custom styles.js

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/pro-solid-svg-icons";
import {
  Alert,
  AlertColor,
  Breakpoint,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { useState } from "react";

import { SusDialogStyles } from "./styles";
import { delay } from "lodash";

type CloseEventHandler = (event: any, reason: string) => void;

interface ISusDialogProps {
  title: string;
  open: boolean;
  onClose: CloseEventHandler;
  confirmClose?: boolean;
  confirmCloseText?: string;
  confirmCloseColor?: AlertColor;
  actions?: (handleCloseConfirm: CloseEventHandler) => React.ReactElement;
  disableEscapeKeyDown?: boolean;
  fullScreen?: boolean;
  maxWidth?: Breakpoint;
  sx?: any;
  className?: string;
}

const SusDialog: React.FC<React.PropsWithChildren<ISusDialogProps>> = ({
  title,
  open,
  onClose,
  confirmClose,
  confirmCloseText,
  confirmCloseColor,
  actions,
  disableEscapeKeyDown,
  children,
  fullScreen,
  maxWidth,
  sx,
  className,
}) => {
  const theme = useTheme();
  const fullScreenQuery = useMediaQuery(theme.breakpoints.down("lg"));
  const [showConfirmClose, setShowConfirmClose] = useState<boolean>(false);

  const handleCloseConfirm: CloseEventHandler = (event, reason) => {
    if (!confirmClose || showConfirmClose) {
      onClose(event, reason);
    } else {
      setShowConfirmClose(true);
      delay(() => setShowConfirmClose(false), 5000);
    }
  };

  return (
    <Dialog
      fullScreen={typeof fullScreen !== "undefined" ? fullScreen : fullScreenQuery}
      open={open}
      onClose={handleCloseConfirm}
      aria-labelledby="responsive-dialog"
      sx={SusDialogStyles && sx}
      maxWidth={maxWidth}
      scroll="paper"
      disableEscapeKeyDown={disableEscapeKeyDown}
      className={className}
    >
      {showConfirmClose && (
        <Alert
          severity={confirmCloseColor || "warning"}
          sx={{
            alignItems: "center",
          }}
        >
          <span className="dz-error-message">
            {confirmCloseText ||
              'Do you want to cancel and discard your changes? If yes, please click "cancel" again.'}
          </span>
        </Alert>
      )}
      <DialogTitle id="responsive-dialog-title" sx={{ m: 0, p: 2 }}>
        {title}
        <IconButton
          aria-label="close"
          onClick={(event) => handleCloseConfirm(event, "iconClick")}
          sx={{
            position: "absolute",
            right: 18,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <FontAwesomeIcon icon={faXmark} size="xs" />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers>{children}</DialogContent>
      {actions && <DialogActions>{actions(handleCloseConfirm)}</DialogActions>}
    </Dialog>
  );
};

SusDialog.defaultProps = {
  maxWidth: "md",
};

export default SusDialog;
